// src/screens/Dashboard.jsx

import React, { useState } from "react";
import { useAuth } from "../components/AuthContext";
import { firestore } from "../firebaseConfig";
import { DataGrid } from "@mui/x-data-grid";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  FormControl,
  FormGroup,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { format, parseISO } from "date-fns";
import CustomLinearProgress, {
  CustomToolbar,
  BankChip,
  CustomMessage,
  SpadaSwitch,
} from "../components/Common";
import { enGB } from "date-fns/locale";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { useData } from "../components/DataProvider";
import HoverImage from "../components/HoverImage";
import EvaluateAppointment from "./EvaluateAppointment";
import { mobileFirestore } from "../mobileFirebaseConfig";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const { currentUser } = useAuth();
  const { appointmentRequests, balance, loading } = useData();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [accept, setAccept] = useState(false);
  const [message, setMessage] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [isEvaluateOpen, setIsEvaluateOpen] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [isChartsVisible, setIsChartsVisible] = useState(false);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 });
  const [freeTimeData, setFreeTimeData] = useState({
    start: null,
    end: null,
  });

  const handleDateTimeChange = (name, datetimeSent) => {
    if (datetimeSent === null || Number.isNaN(datetimeSent.getTime())) {
      console.error("Not a valid date!");
    } else {
      setFreeTimeData({ ...freeTimeData, [name]: datetimeSent });
    }
  };

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const handleShowChart = () => {
    setIsChartsVisible((prev) => !prev);
  };

  const handleShowDetails = () => {
    navigate("/balance");
  };

  const handleClearData = () => {
    setFreeTimeData({
      start: null,
      end: null,
    });
  };

  const closeModal = () => {
    setIsEvaluateOpen(false);
  };

  // Set selected IDs
  const handleSelectionChange = (newSelection) => {
    setSelectedRowIds(newSelection);
  };

  const handleEvaluateAppointment = async () => {
    if (selectedRowIds[0]) {
      // We can get it from db but since datagrid is read-only, no need re-read data from db
      // Find exact document
      // const appointmentRef = doc(
      //   mobileFirestore,
      //   "appointments",
      //   selectedRowIds[0]
      // );
      // Get document
      // const appointmentDoc = await getDoc(appointmentRef);
      // Get whole data in document
      // const appointmentData = appointmentDoc.data();

      const selectedRow = appointmentRequests.find(
        (row) => row.id === selectedRowIds[0]
      );

      // Set ID to edit
      setAppointmentDetails(selectedRow);
      // Open Modal
      setIsEvaluateOpen(true);
    }
  };

  const handleFeedback = (feedback) => {
    setMessage(feedback);
    setOpenMessage(true);
  };

  const handleMessageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  const generateAvailableDates = (startDateTime, endDateTime) => {
    const startDate = new Date(startDateTime);
    const endDate = new Date(endDateTime);

    const availableDates = {};

    // Iterate through each date from start to end
    while (startDate <= endDate) {
      const currentDateKey = startDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD

      // Determine the time range for the current date
      let startHour = 9;
      let endHour = 17;

      if (
        startDate.toISOString().split("T")[0] ===
        new Date(startDateTime).toISOString().split("T")[0]
      ) {
        // For the first date, use the hour from startDateTime
        startHour = startDate.getHours();
      }

      if (
        startDate.toISOString().split("T")[0] ===
        new Date(endDateTime).toISOString().split("T")[0]
      ) {
        // For the last date, use the hour from endDateTime
        endHour = new Date(endDateTime).getHours();
      }

      // Generate times for the current date
      const times = [];
      for (let hour = startHour; hour <= endHour; hour++) {
        const time = new Date(startDate);
        time.setHours(hour, 0, 0, 0);
        times.push(time.toTimeString().split(" ")[0].slice(0, 5)); // Format as HH:mm
      }

      // Add to availableDates map
      availableDates[currentDateKey] = times;

      // Move to the next day
      startDate.setDate(startDate.getDate() + 1);
      startDate.setHours(9, 0, 0, 0);
    }

    return availableDates;
  };

  const adjustAvailableDatesForBusyTimes = (
    availableDates,
    busyStartDateTime,
    busyEndDateTime
  ) => {
    const busyStartDate = new Date(busyStartDateTime);
    const busyEndDate = new Date(busyEndDateTime);

    // Convert busyStartDate and busyEndDate to the respective string date format (YYYY-MM-DD)
    const busyStartDateStr = busyStartDate.toISOString().split("T")[0];
    const busyEndDateStr = busyEndDate.toISOString().split("T")[0];

    // Loop through each date in availableDates
    for (const date in availableDates) {
      const currentDate = new Date(date);
      const currentDateStr = currentDate.toISOString().split("T")[0];

      // If the date is within the busy period
      if (
        currentDateStr >= busyStartDateStr &&
        currentDateStr <= busyEndDateStr
      ) {
        let availableTimes = availableDates[date];

        // If it's a date between the busy start and end date, remove all times
        if (
          currentDateStr >= busyStartDateStr &&
          currentDateStr < busyEndDateStr
        ) {
          availableTimes = [];
        } else {
          if (
            currentDateStr === busyStartDateStr &&
            currentDateStr === busyEndDateStr
          ) {
            const busyStartTime = `${
              busyStartDate.getHours() < 10 ? "0" : ""
            }${busyStartDate.getHours()}:${
              busyStartDate.getMinutes() < 10 ? "0" : ""
            }${busyStartDate.getMinutes()}`;
            console.log("busyStartTime ", busyStartTime);
            const busyEndTime = `${
              busyEndDate.getHours() < 10 ? "0" : ""
            }${busyEndDate.getHours()}:${
              busyEndDate.getMinutes() < 10 ? "0" : ""
            }${busyEndDate.getMinutes()}`;
            console.log("busyEndTime ", busyEndTime);
            console.log(availableTimes);
            availableTimes = availableTimes.filter(
              (time) => time < busyStartTime || time > busyEndTime
            );
            console.log(availableTimes);
          } else {
            // If it's the busy start date, filter out times before the busy start time
            if (currentDateStr === busyStartDateStr) {
              const busyStartTime = `${
                busyStartDate.getHours() < 10 ? "0" : ""
              }${busyStartDate.getHours()}:${
                busyStartDate.getMinutes() < 10 ? "0" : ""
              }${busyStartDate.getMinutes()}`;

              console.log("busyStartTime ", busyStartTime);
              availableTimes = availableTimes.filter(
                (time) => time !== busyStartTime
              );
              console.log("Chopped Start: ", availableTimes);
            }

            // If it's the busy end date, filter out times after the busy end time
            if (currentDateStr === busyEndDateStr) {
              const busyEndTime = `${
                busyEndDate.getHours() < 10 ? "0" : ""
              }${busyEndDate.getHours()}:${
                busyEndDate.getMinutes() < 10 ? "0" : ""
              }${busyEndDate.getMinutes()}`;
              console.log("busyEndTime ", busyEndTime);
              availableTimes = availableTimes.filter(
                (time) => time > busyEndTime
              );
              console.log("Chopped End: ", availableTimes);
            }
          }
        }
        // Update the availableDates for the current date
        availableDates[date] = availableTimes;
      }
    }

    return availableDates;
  };

  // Function to fetch available dates from Firestore and update them based on busy times
  const adjustAvailableDatesForBusyPeriod = async (
    mechanicId,
    busyStartDateTime,
    busyEndDateTime
  ) => {
    const mechanicRef = doc(mobileFirestore, "mechanics", mechanicId);

    try {
      // Fetch available dates from Firestore
      const doc = await getDoc(mechanicRef);
      if (!doc.exists) {
        console.log("No such document!");
        return;
      }
      const availableDates = doc.data().availableDates;

      // Validate busy times
      const busyStartDate = new Date(busyStartDateTime);
      const busyEndDate = new Date(busyEndDateTime);
      const busyStartDateStr = busyStartDate.toISOString().split("T")[0];
      const busyEndDateStr = busyEndDate.toISOString().split("T")[0];

      // Check if the busy date range falls inside the availableDates
      let validBusyPeriod = false;

      // Loop through the available dates to see if they intersect with the busy period
      for (const date in availableDates) {
        const currentDate = new Date(date);
        const currentDateStr = currentDate.toISOString().split("T")[0];

        // If busy date range overlaps with this date's available times
        if (
          currentDateStr >= busyStartDateStr &&
          currentDateStr <= busyEndDateStr
        ) {
          validBusyPeriod = true;
          break; // We found the overlap, no need to continue
        }
      }

      if (!validBusyPeriod) {
        console.warn(
          "The busy time does not overlap with the available dates."
        );
        alert(
          "The busy time does not overlap with the available dates. Please select a valid range."
        );
        return; // Exit if no overlap
      }

      // Now adjust the availableDates based on the busy times
      const updatedAvailableDates = adjustAvailableDatesForBusyTimes(
        availableDates,
        busyStartDateTime,
        busyEndDateTime
      );

      // Update the availableDates in Firestore with the new adjusted data
      await updateDoc(mechanicRef, { availableDates: updatedAvailableDates });

      console.log("Updated available dates:", updatedAvailableDates);
    } catch (error) {
      console.error("Error fetching or updating available dates:", error);
    }
  };

  const saveData = async () => {
    if (freeTimeData.start && freeTimeData.end) {
      try {
        const q = query(
          collection(firestore, "users"),
          where("fullName", "==", currentUser.fullName)
        );

        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          // Get the first document ID
          const firstDoc = querySnapshot.docs[0];
          // // Reference to the source file
          // const profilePictureUrl = firstDoc.data().profilePicture;
          // const sourceFileRef = ref(storage, profilePictureUrl);
          // // Download the profile picture
          // const profilePictureBlob = await fetch(
          //   await getDownloadURL(sourceFileRef)
          // ).then((res) => res.blob());
          // // Prepare the target file path and metadata
          // const targetFilePath = `mechanics/profile_pictures/${firstDoc.id}`;
          // const targetFileRef = ref(mobileStorage, targetFilePath);
          // // Upload the profile picture to the new location
          // const uploadResult = await uploadBytes(
          //   targetFileRef,
          //   profilePictureBlob
          // );
          // // Get the public URL of the uploaded file
          // const newProfilePictureUrl = await getDownloadURL(uploadResult.ref);
          // Create Reference
          const newMechanicRef = doc(mobileFirestore, "mechanics", firstDoc.id);
          let updatedFreeTimeData;

          if (accept) {
            // Update Structure
            adjustAvailableDatesForBusyPeriod(
              firstDoc.id,
              freeTimeData.start,
              freeTimeData.end
            );
          } else {
            // Create Structure
            updatedFreeTimeData = {
              availableDates: generateAvailableDates(
                freeTimeData.start,
                freeTimeData.end
              ),
              createdAt: new Date(),
              id: firstDoc.id,
              name: currentUser.fullName,
              specialty: firstDoc.data().username,
              photo:
                firstDoc.data().fullName === "Buğra Salman"
                  ? "https://firebasestorage.googleapis.com/v0/b/bnb-chameleon-app.appspot.com/o/mechanics%2Fcofounder1.jpg?alt=media&token=7f45dba9-6680-4fe2-a083-8cb7c4811b1c"
                  : "https://firebasestorage.googleapis.com/v0/b/bnb-chameleon-app.appspot.com/o/mechanics%2Fcofounder2.jpg?alt=media&token=f9c2f7c7-bd46-4758-b07f-aaee422fead6", //newProfilePictureUrl,
            };
            // Create new client with specific ID
            await setDoc(newMechanicRef, updatedFreeTimeData);
          }

          // handleClearData();
          setMessage(t("Operation Successfull!"));
          setOpenMessage(true);
        } else {
          handleClearData();
          setMessage(t("Failed to create free time!"));
          setOpenMessage(true);
        }
      } catch (error) {
        console.error("Error adding document: ", error);
        setMessage(t("Failed to create free time!"));
        setOpenMessage(true);
      }
    } else {
      handleClearData();
      console.error("Dates are not valid!");
      setMessage(t("Dates are not valid!"));
      setOpenMessage(true);
    }
  };

  const handleMouseEnter = (e, src) => {
    const rect = e.target.getBoundingClientRect();
    setHoverPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setHoveredImage(src);
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "clientPhoto",
      headerName: t("Photo"),
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 85,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          position="relative"
          onMouseEnter={(e) => handleMouseEnter(e, params.value)}
          onMouseLeave={handleMouseLeave}
        >
          <Avatar
            src={params.value}
            // variant="square"
            style={{ width: 45, height: 45 }}
          />
        </Box>
      ),
    },
    {
      field: "client",
      headerName: t("Client"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "clientPhone",
      headerName: t("Phone"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "motorcycle",
      headerName: t("Motorcycle"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => {
        const brand = params.row.brand;
        const model = params.row.model;
        const year = params.row.year;
        const plate = params.row.plate;
        return brand && model && year ? (
          <div>
            <div>
              <Typography>
                {brand} {model} ({year})
              </Typography>
            </div>
            <div>
              <Typography>{plate}</Typography>
            </div>
          </div>
        ) : (
          "N/A"
        );
      },
    },
    {
      field: "startDatetime",
      headerName: t("Date & Time"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) =>
        format(params.row.startDatetime, "dd/MM/yyyy HH:mm"),
    },
    {
      field: "description",
      headerName: t("Description"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "mechanic",
      headerName: t("Mechanic"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "status",
      headerName: t("Status"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => {
        switch (params.row.status) {
          case "Pending":
            return (
              <BankChip {...params} value={t("Pending")} color="warning" />
            );
          case "Confirmed":
            return (
              <BankChip {...params} value={t("Confirmed")} color="success" />
            );
          case "Rejected":
            return <BankChip {...params} value={t("Rejected")} color="error" />;
          default:
            return null;
        }
      },
    },
  ];

  const datagridMaxHeight = 616;

  if (currentUser.purchasedProducts.includes("appointmentsModule")) {
    return (
      <Box m="20px" sx={{ height: "100vh" }}>
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Grid container spacing={2}>
            {/* Feedback Mechanism */}
            <CustomMessage
              open={openMessage}
              onClose={handleMessageClose}
              message={message}
            />
            <Grid item xs={2}>
              <Box mb="30px">
                <Typography
                  variant="h2"
                  color={colors.grey[200]}
                  fontWeight="bold"
                  sx={{
                    fontSize: {
                      xs: "1.5rem", // font size for extra-small screens
                      sm: "2rem", // font size for small screens
                      md: "3rem", // font size for medium screens
                      lg: "3.5rem", // font size for large screens
                      xl: "3.5rem", // font size for extra-large screens
                    },
                    whiteSpace: "nowrap",
                  }}
                >
                  {t("Welcome Boss!")}
                </Typography>
                <Typography
                  variant="r16"
                  color={colors.greenAccent[400]}
                  sx={{
                    fontSize: {
                      xs: "1rem", // font size for extra-small screens
                      sm: "1.2rem", // font size for small screens
                      md: "1.2rem", // font size for medium screens
                      lg: "1.5rem", // font size for large screens
                      xl: "1.5rem", // font size for extra-large screens
                    },
                    whiteSpace: "nowrap", // Prevent text wrapping
                    overflow: "hidden", // Hide overflow if any
                    textOverflow: "ellipsis", // Add ellipsis if text overflows
                  }}
                >
                  {t("Set your availability & View appointment requests")}
                </Typography>
              </Box>
            </Grid>

            {/* Evaluate Appointment */}
            {isEvaluateOpen && (
              <EvaluateAppointment
                onClose={closeModal}
                appointmentDetails={appointmentDetails}
                onFeedback={handleFeedback}
              />
            )}

            <Box flexGrow={1} />

            {/* Evaluate Button */}
            {(currentUser.type === "Admin" ||
              currentUser.type === "GODLIKE" ||
              currentUser.authorization.appointments % 2) &&
              selectedRowIds[0] && (
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    onClick={() => handleEvaluateAppointment()}
                    fullWidth
                    sx={{
                      mt: {
                        xs: 0,
                        sm: 0,
                        md: 1,
                        lg: 1,
                        xl: 1,
                      },
                      backgroundColor: colors.greenAccent[600],
                      color: colors.grey[100],
                      height: "50px",
                      "&:hover": {
                        backgroundColor: colors.greenAccent[800],
                      },
                    }}
                  >
                    <Typography
                      component="h1"
                      variant="r16"
                      sx={{
                        fontSize: {
                          xs: "0.7rem", // font size for extra-small screens
                          sm: "0.8rem", // font size for small screens
                          md: "0.9rem", // font size for medium screens
                          lg: "1.2rem", // font size for large screens
                          xl: "1.5rem", // font size for extra-large screens
                        },
                      }}
                    >
                      {t("View Appointment")}
                    </Typography>
                  </Button>
                </Grid>
              )}

            {/* Free Time Button */}
            {(currentUser.type === "Admin" ||
              currentUser.type === "GODLIKE" ||
              currentUser.authorization.appointments % 2) &&
              !isChartsVisible && (
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    onClick={() => handleChange()}
                    fullWidth
                    sx={{
                      mt: {
                        xs: 0,
                        sm: 0,
                        md: 1,
                        lg: 1,
                        xl: 1,
                      },
                      backgroundColor: colors.blueAccent[500],
                      color: colors.grey[100],
                      height: "50px",
                      "&:hover": {
                        backgroundColor: colors.blueAccent[800],
                      },
                    }}
                  >
                    <Typography
                      component="h1"
                      variant="r16"
                      sx={{
                        fontSize: {
                          xs: "0.7rem", // font size for extra-small screens
                          sm: "0.8rem", // font size for small screens
                          md: "0.9rem", // font size for medium screens
                          lg: "1.2rem", // font size for large screens
                          xl: "1.5rem", // font size for extra-large screens
                        },
                      }}
                    >
                      {checked ? t("See Requests") : t("Enter Free Time")}
                    </Typography>
                  </Button>
                </Grid>
              )}

            {/* Charts Button */}
            {(currentUser.type === "Admin" ||
              currentUser.type === "GODLIKE" ||
              currentUser.authorization.appointments % 2) && (
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  onClick={() => handleShowChart()}
                  fullWidth
                  sx={{
                    mt: {
                      xs: 0,
                      sm: 0,
                      md: 1,
                      lg: 1,
                      xl: 1,
                    },
                    backgroundColor: colors.orangeAccent[500],
                    color: colors.grey[100],
                    height: "50px",
                    "&:hover": {
                      backgroundColor: colors.orangeAccent[800],
                    },
                  }}
                >
                  <Typography
                    component="h1"
                    variant="r16"
                    sx={{
                      fontSize: {
                        xs: "0.7rem", // font size for extra-small screens
                        sm: "0.8rem", // font size for small screens
                        md: "0.9rem", // font size for medium screens
                        lg: "1.2rem", // font size for large screens
                        xl: "1.5rem", // font size for extra-large screens
                      },
                    }}
                  >
                    {isChartsVisible ? t("Hide Charts") : t("Show Charts")}
                  </Typography>
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>

        <Collapse in={isChartsVisible}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              overflowY: "auto",
              pb: "20px",
            }}
          >
            <Grid container spacing={2}>
              {/* Balance Chart */}
              <Grid
                item
                xs={6}
                alignContent={"center"}
                alignItems={"center"}
                justifyContent={"center"}
                display="flex"
                flex-direction="column"
              >
                <Stack
                  alignContent={"center"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  display="flex"
                >
                  <Typography
                    variant="r16"
                    color={colors.greenAccent[100]}
                    sx={{
                      fontSize: {
                        xs: "1rem", // font size for extra-small screens
                        sm: "1.2rem", // font size for small screens
                        md: "1.2rem", // font size for medium screens
                        lg: "1.5rem", // font size for large screens
                        xl: "1.5rem", // font size for extra-large screens
                      },
                      whiteSpace: "nowrap", // Prevent text wrapping
                      overflow: "hidden", // Hide overflow if any
                      textOverflow: "ellipsis", // Add ellipsis if text overflows
                    }}
                  >
                    {t("Receivable/Payable Status")}
                  </Typography>
                  <PieChart
                    series={[
                      {
                        data: [
                          {
                            id: 0,
                            value: balance.credit,
                            label: t("Receivable"),
                            color: colors.greenAccent[500],
                          },
                          {
                            id: 1,
                            value: balance.debit,
                            label: t("Payable"),
                            color: colors.pinkAccent[600],
                          },
                        ],
                        highlightScope: { fade: "global", highlight: "item" },
                        faded: {
                          innerRadius: 30,
                          additionalRadius: -30,
                          color: "gray",
                        },
                      },
                    ]}
                    // sx={{
                    //   width: {
                    //     xs: "240px",
                    //     sm: "240px",
                    //     md: "480px",
                    //     lg: "480px",
                    //     xl: "480px",
                    //   },
                    //   height: {
                    //     xs: "240px",
                    //     sm: "240px",
                    //     md: "480px",
                    //     lg: "480px",
                    //     xl: "480px",
                    //   },
                    // }}
                    width={480}
                    height={480}
                  />
                </Stack>
              </Grid>

              {/* Sales Chart */}
              <Grid
                item
                xs={6}
                alignContent={"center"}
                alignItems={"center"}
                justifyContent={"center"}
                display="flex"
                flex-direction="column"
              >
                <Stack
                  alignContent={"center"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  display="flex"
                >
                  <Typography
                    variant="r16"
                    color={colors.greenAccent[100]}
                    sx={{
                      fontSize: {
                        xs: "1rem", // font size for extra-small screens
                        sm: "1.2rem", // font size for small screens
                        md: "1.2rem", // font size for medium screens
                        lg: "1.5rem", // font size for large screens
                        xl: "1.5rem", // font size for extra-large screens
                      },
                      whiteSpace: "nowrap", // Prevent text wrapping
                      overflow: "hidden", // Hide overflow if any
                      textOverflow: "ellipsis", // Add ellipsis if text overflows
                    }}
                  >
                    {t("Receivables Rate")}
                  </Typography>
                  <PieChart
                    series={[
                      {
                        data: [
                          {
                            id: 0,
                            value: balance.works,
                            label: t("Works"),
                            color: colors.greenAccent[600],
                          },
                          {
                            id: 1,
                            value: balance.sales,
                            label: t("Sales"),
                            color: colors.blueAccent[400],
                          },
                        ],
                        highlightScope: { fade: "global", highlight: "item" },
                        faded: {
                          innerRadius: 30,
                          additionalRadius: -30,
                          color: "gray",
                        },
                      },
                    ]}
                    width={480}
                    height={480}
                  />
                </Stack>
              </Grid>

              <Grid item xs={4} />

              {/* Show Details Button */}
              <Grid item xs={4}>
                <Box
                  justifyContent={"center"}
                  alignContent={"center"}
                  flexDirection={"row"}
                  flex={true}
                >
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => handleShowDetails()}
                    sx={{
                      mt: 1,
                      mb: 0,
                      backgroundColor: colors.greenAccent[700],
                      color: "#FFFFFF",
                      height: "50px",
                      "&:hover": {
                        backgroundColor: colors.greenAccent[500],
                      },
                    }}
                  >
                    <Typography component="h1" variant="r16" paddingLeft="10px">
                      {t("Show Details")}
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Collapse>

        <Collapse in={checked && !isChartsVisible}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              overflowY: "auto",
              pb: "20px",
            }}
          >
            {/* Complete Switch */}
            <Grid
              item
              xs={12}
              alignContent={"center"}
              alignItems={"center"}
              justifyContent={"center"}
              display="flex"
              flex-direction="column"
            >
              <FormControl component="fieldset" style={{ marginLeft: "0px" }}>
                <FormGroup>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography
                      variant="r12"
                      sx={{
                        fontSize: {
                          xs: "0.5rem",
                          sm: "0.6rem",
                          md: "0.7rem",
                          lg: "0.8rem",
                          xl: "1rem",
                        },
                      }}
                    >
                      {t("Free/Busy Time")}
                    </Typography>
                    <SpadaSwitch
                      foreColor={colors.greenAccent[500]}
                      trackColor={colors.redAccent[500]}
                      iconColor={"#000000"}
                      onChange={() => setAccept(!accept)}
                      checked={accept}
                    />
                  </Box>
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <Typography
                    variant="r16"
                    color={colors.greenAccent[400]}
                    sx={{
                      fontSize: {
                        xs: "1rem", // font size for extra-small screens
                        sm: "1.2rem", // font size for small screens
                        md: "1.2rem", // font size for medium screens
                        lg: "1.5rem", // font size for large screens
                        xl: "1.5rem", // font size for extra-large screens
                      },
                      whiteSpace: "nowrap", // Prevent text wrapping
                      overflow: "hidden", // Hide overflow if any
                      textOverflow: "ellipsis", // Add ellipsis if text overflows
                    }}
                  >
                    {accept
                      ? t("Busy Date & Time Start")
                      : t("Availability Date & Time Start")}
                  </Typography>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={enGB}
                  >
                    <StaticDateTimePicker
                      sx={{
                        borderRadius: theme.shape.borderRadius,
                        // Toolbar
                        "& .MuiPickersToolbar-root": {
                          backgroundColor: colors.blueAccent[800], // "#1976d2" Customize toolbar background color
                          color:
                            theme.palette.mode === "light"
                              ? colors.redAccent[500]
                              : colors.greenAccent[500],
                          borderRadius: theme.shape.borderRadius,
                        },
                        "& .MuiDayCalendar-root": {
                          borderRadius: theme.shape.borderRadius,
                        },
                        // Calendar Background and Fore Colors
                        // "& .MuiDayCalendar-root": {
                        //   backgroundColor: "#e0f7fa",
                        //   padding: "8px",
                        //   borderRadius: "8px",
                        // },
                        // Tab colors
                        // "& .MuiTabs-root": {
                        //   backgroundColor: "#e0f2f1",
                        //   borderBottom: "1px solid #ccc",
                        // },
                        // Icon Colors
                        "& .MuiTab-root": {
                          color:
                            theme.palette.mode === "light"
                              ? "#000000"
                              : "#FFFFFF",
                          "&.Mui-selected": {
                            color:
                              theme.palette.mode === "light"
                                ? colors.redAccent[500]
                                : colors.greenAccent[500],
                          },
                          "&:hover": {
                            color:
                              theme.palette.mode === "light"
                                ? colors.redAccent[700]
                                : colors.greenAccent[700],
                          },
                        },
                        // Calendar Header Colors
                        "& .MuiPickersCalendarHeader-root": {
                          backgroundColor:
                            theme.palette.mode === "light"
                              ? colors.redAccent[700]
                              : colors.greenAccent[700], // Customize calendar header background
                          color: "#fff", // Customize calendar header text
                          borderRadius: theme.shape.borderRadius,
                        },
                        // Dialog Buttons
                        ".MuiDialogActions-root": {
                          display: "none",
                        },
                        // Days Forecolor
                        "& .MuiPickersDay-root": {
                          color:
                            theme.palette.mode === "light"
                              ? colors.redAccent[500]
                              : colors.greenAccent[500],
                          borderRadius: theme.shape.borderRadius,
                        },
                        // Day of Week Header ForeColor
                        "& .MuiDayCalendar-weekDayLabel": {
                          color:
                            theme.palette.mode === "light" ? "#000" : "#fff",
                        },
                        // Selected Day Background and Fore Colors
                        "& .MuiPickersDay-root.Mui-selected": {
                          backgroundColor:
                            theme.palette.mode === "light"
                              ? colors.redAccent[500]
                              : colors.greenAccent[500], // Customize selected day color
                          color: "#fff", // Customize selected day text color
                        },
                        "& .MuiClockPicker-root": {
                          backgroundColor:
                            theme.palette.mode === "light"
                              ? colors.redAccent[500]
                              : colors.greenAccent[500], // Customize clock background color
                        },
                        // Clock Pointer ForeColor
                        "& .MuiClockPointer-root": {
                          backgroundColor:
                            theme.palette.mode === "light"
                              ? colors.redAccent[500]
                              : colors.greenAccent[500], // Customize clock pointer color
                        },
                      }}
                      slotProps={{
                        toolbar: {
                          toolbarTitle: t("Select Date & Time"),
                        },
                      }}
                      name="start"
                      defaultValue={parseISO(
                        format(new Date(), "yyyy-MM-dd HH:mm:ss")
                      )}
                      value={freeTimeData.start}
                      onChange={(newValue) =>
                        handleDateTimeChange("start", newValue)
                      }
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>

              <Box flexGrow={true} />

              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <Typography
                    variant="r16"
                    color={colors.greenAccent[400]}
                    sx={{
                      fontSize: {
                        xs: "1rem", // font size for extra-small screens
                        sm: "1.2rem", // font size for small screens
                        md: "1.2rem", // font size for medium screens
                        lg: "1.5rem", // font size for large screens
                        xl: "1.5rem", // font size for extra-large screens
                      },
                      whiteSpace: "nowrap", // Prevent text wrapping
                      overflow: "hidden", // Hide overflow if any
                      textOverflow: "ellipsis", // Add ellipsis if text overflows
                    }}
                  >
                    {accept
                      ? t("Busy Date & Time End")
                      : t("Availability Date & Time End")}
                  </Typography>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={enGB}
                  >
                    <StaticDateTimePicker
                      sx={{
                        borderRadius: theme.shape.borderRadius,
                        // Toolbar
                        "& .MuiPickersToolbar-root": {
                          backgroundColor: colors.blueAccent[800], // "#1976d2" Customize toolbar background color
                          color:
                            theme.palette.mode === "light"
                              ? colors.redAccent[500]
                              : colors.greenAccent[500],
                          borderRadius: theme.shape.borderRadius,
                        },
                        "& .MuiDayCalendar-root": {
                          borderRadius: theme.shape.borderRadius,
                        },
                        // Calendar Background and Fore Colors
                        // "& .MuiDayCalendar-root": {
                        //   backgroundColor: "#e0f7fa",
                        //   padding: "8px",
                        //   borderRadius: "8px",
                        // },
                        // Tab colors
                        // "& .MuiTabs-root": {
                        //   backgroundColor: "#e0f2f1",
                        //   borderBottom: "1px solid #ccc",
                        // },
                        // Icon Colors
                        "& .MuiTab-root": {
                          color:
                            theme.palette.mode === "light"
                              ? "#000000"
                              : "#FFFFFF",
                          "&.Mui-selected": {
                            color:
                              theme.palette.mode === "light"
                                ? colors.redAccent[500]
                                : colors.greenAccent[500],
                          },
                          "&:hover": {
                            color:
                              theme.palette.mode === "light"
                                ? colors.redAccent[700]
                                : colors.greenAccent[700],
                          },
                        },
                        // Calendar Header Colors
                        "& .MuiPickersCalendarHeader-root": {
                          backgroundColor:
                            theme.palette.mode === "light"
                              ? colors.redAccent[700]
                              : colors.greenAccent[700], // Customize calendar header background
                          color: "#fff", // Customize calendar header text
                          borderRadius: theme.shape.borderRadius,
                        },
                        // Dialog Buttons
                        ".MuiDialogActions-root": {
                          display: "none",
                        },
                        // Days Forecolor
                        "& .MuiPickersDay-root": {
                          color:
                            theme.palette.mode === "light"
                              ? colors.redAccent[500]
                              : colors.greenAccent[500],
                          borderRadius: theme.shape.borderRadius,
                        },
                        // Day of Week Header ForeColor
                        "& .MuiDayCalendar-weekDayLabel": {
                          color:
                            theme.palette.mode === "light" ? "#000" : "#fff",
                        },
                        // Selected Day Background and Fore Colors
                        "& .MuiPickersDay-root.Mui-selected": {
                          backgroundColor:
                            theme.palette.mode === "light"
                              ? colors.redAccent[500]
                              : colors.greenAccent[500], // Customize selected day color
                          color: "#fff", // Customize selected day text color
                        },
                        "& .MuiClockPicker-root": {
                          backgroundColor:
                            theme.palette.mode === "light"
                              ? colors.redAccent[500]
                              : colors.greenAccent[500], // Customize clock background color
                        },
                        // Clock Pointer ForeColor
                        "& .MuiClockPointer-root": {
                          backgroundColor:
                            theme.palette.mode === "light"
                              ? colors.redAccent[500]
                              : colors.greenAccent[500], // Customize clock pointer color
                        },
                      }}
                      slotProps={{
                        toolbar: {
                          toolbarTitle: t("Select Date & Time"),
                        },
                      }}
                      name="end"
                      defaultValue={parseISO(
                        format(new Date(), "yyyy-MM-dd HH:mm:ss")
                      )}
                      value={freeTimeData.end}
                      onChange={(newValue) =>
                        handleDateTimeChange("end", newValue)
                      }
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>

              {/* Spacer */}
              <Grid item xs={4} />

              {/* Submit Button */}
              <Grid item xs={4}>
                <Box
                  justifyContent={"center"}
                  alignContent={"center"}
                  flexDirection={"row"}
                  flex={true}
                >
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => saveData()}
                    sx={{
                      mt: 1,
                      mb: 0,
                      backgroundColor: colors.greenAccent[700],
                      color: "#FFFFFF",
                      height: "50px",
                      "&:hover": {
                        backgroundColor: colors.greenAccent[500],
                      },
                    }}
                  >
                    <Typography component="h1" variant="r16" paddingLeft="10px">
                      {t("Save")}
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Collapse>

        <Collapse in={!checked && !isChartsVisible}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography
                variant="r16"
                color={colors.greenAccent[400]}
                sx={{
                  fontSize: {
                    xs: "1rem", // font size for extra-small screens
                    sm: "1.2rem", // font size for small screens
                    md: "1.2rem", // font size for medium screens
                    lg: "1.5rem", // font size for large screens
                    xl: "1.5rem", // font size for extra-large screens
                  },
                  whiteSpace: "nowrap", // Prevent text wrapping
                  overflow: "hidden", // Hide overflow if any
                  textOverflow: "ellipsis", // Add ellipsis if text overflows
                }}
              >
                {t("Appointment Requests")}
              </Typography>
              <Box
                m="0px 0px 0px 0px"
                display="flex"
                flex-direction="column"
                min-height="400px"
                width="100%"
                overflow={"auto"}
                sx={{
                  height: {
                    xs: `${datagridMaxHeight / 1.25}px`,
                    sm: `${datagridMaxHeight / 1.25}px`,
                    md: `${datagridMaxHeight}px`,
                    lg: `${datagridMaxHeight}px`,
                    xl: `${datagridMaxHeight * 1.5}px`,
                  },
                  width: "100%",
                  "& .MuiDataGrid-root": {
                    fontSize: {
                      xs: "0.7rem", // font size for extra-small screens
                      sm: "0.8rem", // font size for small screens
                      md: "0.9rem", // font size for medium screens
                      lg: "1.2rem", // font size for large screens
                      xl: "1.5rem", // font size for extra-large screens
                    },
                    ...(theme.palette.mode === "dark"
                      ? {
                          border: "1px solid rgba(62, 67, 150, 1)",
                        }
                      : { border: "1px solid rgba(175, 63, 59, 1)" }),
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "none",
                    fontFamily: "Poppins",
                    fontSize: {
                      xs: "0.7rem", // font size for extra-small screens
                      sm: "0.8rem", // font size for small screens
                      md: "0.9rem", // font size for medium screens
                      lg: "1rem", // font size for large screens
                      xl: "1.2rem", // font size for extra-large screens
                    },
                  },
                  "& .name-column--cell": {
                    color: colors.greenAccent[300],
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    borderBottom: "none",
                    fontFamily: "Poppins",
                    fontSize: {
                      xs: "0.5rem", // font size for extra-small screens
                      sm: "0.7rem", // font size for small screens
                      md: "0.8rem", // font size for medium screens
                      lg: "1rem", // font size for large screens
                      xl: "1.2rem", // font size for extra-large screens
                    },
                    ...(theme.palette.mode === "dark"
                      ? {
                          color: colors.greenAccent[500],
                        }
                      : { color: colors.redAccent[500] }),
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[400],
                  },
                  "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    ...(theme.palette.mode === "dark"
                      ? {
                          backgroundColor: colors.blueAccent[700],
                        }
                      : { backgroundColor: colors.redAccent[800] }),
                  },
                  "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[200]} !important`,
                  },
                  "& .MuiDataGrid-cell:hover": {
                    ...(theme.palette.mode === "dark"
                      ? {
                          color: colors.blueAccent[500],
                        }
                      : { color: colors.redAccent[500] }),
                  },
                }}
              >
                <DataGrid
                  columnVisibilityModel={{
                    id: false,
                  }}
                  checkboxSelection={true}
                  disableMultipleRowSelection
                  rows={appointmentRequests}
                  columns={columns}
                  slots={{
                    toolbar: CustomToolbar,
                    loadingOverlay: CustomLinearProgress,
                  }}
                  loading={loading}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10, 20]}
                  disableSelectionOnClick
                  disableExtendRowFullWidth={true}
                  onRowSelectionModelChange={(newSelection) =>
                    handleSelectionChange(newSelection)
                  }
                  style={{ height: "100%", width: "100%" }}
                />
                <HoverImage
                  src={hoveredImage}
                  isVisible={!!hoveredImage}
                  position={hoverPosition}
                />
              </Box>
            </Grid>
          </Grid>
        </Collapse>
      </Box>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else {
    return (
      <Box m="20px">
        {/* HEADER */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingRight="25px"
        >
          {/* <Header
              title={t("403")}
              subtitle={t("You don't have permission to see this page")}
            /> */}
        </Box>
      </Box>
    );
  }
};

export default Dashboard;
