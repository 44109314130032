/* eslint-disable react-hooks/exhaustive-deps */
// src/screens/EvaluateAppointment.jsx

import React, { useState } from "react";
// import { useAuth } from "../components/AuthContext";
// import { firestore } from "../firebaseConfig";
import {
  // collection,
  doc,
  getDoc,
  updateDoc,
  // addDoc,
  // setDoc,
} from "firebase/firestore";
// import { v4 as uuidv4 } from "uuid";
import {
  Avatar,
  Box,
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  useTheme,
  Backdrop,
  Modal,
  Fade,
  IconButton,
  FormControl,
  FormGroup,
  Stack,
  InputAdornment,
} from "@mui/material";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import { SpadaSwitch } from "../components/Common";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { format, parseISO } from "date-fns";
import { enGB } from "date-fns/locale";
// Icons
import CloseIcon from "@mui/icons-material/Close";
import LoadingWindow from "../components/LoadingWindow";
import HoverImage from "../components/HoverImage";
import "../App.css";
import { StaticDateTimePicker } from "@mui/x-date-pickers";
import { mobileFirestore } from "../mobileFirebaseConfig";

const EvaluateAppointment = ({ onClose, appointmentDetails, onFeedback }) => {
  // const { currentUser } = useAuth();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [accept, setAccept] = useState(false);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 });
  const [appointmentData, setAppointmentData] = useState({
    client: appointmentDetails.userId,
    clientName: appointmentDetails.client,
    clientPhone: appointmentDetails.phone,
    clientPhoto: appointmentDetails.photo,
    startDatetime: appointmentDetails.startDatetime,
    endDatetime: appointmentDetails.endDatetime,
    id: appointmentDetails.id,
    mechanic: appointmentDetails.mechanicId,
    brand: appointmentDetails.motorcycle.brand,
    model: appointmentDetails.motorcycle.model,
    year: appointmentDetails.motorcycle.year,
    odometer: appointmentDetails.motorcycle.odometer,
    licensePlate: appointmentDetails.motorcycle.license_plate,
    description: appointmentDetails.description,
    rejectReason: "",
    status: appointmentDetails.status,
  });
  const [freeTimeData, setFreeTimeData] = useState({
    start: appointmentDetails.startDatetime,
    end: new Date(appointmentDetails.startDatetime).setHours(
      appointmentDetails.startDatetime.getHours() + 1
    ),
  });

  const handleDateTimeChange = (name, datetimeSent) => {
    if (datetimeSent === null || Number.isNaN(datetimeSent.getTime())) {
      console.error("Not a valid date!");
    } else {
      setFreeTimeData({ ...freeTimeData, [name]: datetimeSent });
    }
  };

  const handleMouseEnter = (e, src) => {
    const rect = e.target.getBoundingClientRect();
    setHoverPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setHoveredImage(src);
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
  };

  // Toggle modal open/close
  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  const closeModal = () => {
    setLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAppointmentData({ ...appointmentData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const preValidateFields = () => {
    const newErrors = {};
    if (accept) {
      if (!freeTimeData.start)
        newErrors.start = t("Start Date & Time is required");
      if (!freeTimeData.end) newErrors.end = t("End Date & Time is required");
    } else {
      if (!appointmentData.rejectReason)
        newErrors.rejectReason = t("Rejection Reason is required");
    }
    setErrors(newErrors);
    return newErrors;
  };

  const handleClearData = () => {
    setAppointmentData({
      client: "",
      clientName: "",
      clientPhone: "",
      clientPhoto: "",
      startDatetime: null,
      endDatetime: null,
      id: "",
      mechanic: "",
      brand: "",
      model: "",
      year: "",
      odometer: "",
      licensePlate: "",
      description: "",
      rejectReason: "",
      status: "",
    });
    setFreeTimeData({
      start: null,
      end: null,
    });
    setErrors({});
  };

  // function arrayToObject(arr) {
  //   const obj = {};
  //   for (let i = 0; i < arr.length; ++i) {
  //     obj[i] = arr[i];
  //   }
  //   return obj;
  // }

  // const processDocumentData = (data) => {
  //   const knownStringFields = ["startYear", "endYear"];
  //   const arrays = {};

  //   for (const key in data) {
  //     if (!knownStringFields.includes(key)) {
  //       arrays[key] = data[key];
  //     }
  //   }

  //   return arrays;
  // };

  const handleCancel = async () => {
    handleClearData();
    handleClose();
  };

  function getTimesBetween(startTime, endTime) {
    const times = [];
    let current = startTime;

    while (current < endTime) {
      times.push(current);

      // Increment time by 1 hour
      const [hours] = current.split(":").map(Number);
      const nextHour = hours + 1;

      // Format to HH:mm
      current = nextHour < 10 ? `0${nextHour}:00` : `${nextHour}:00`;
    }

    return times;
  }

  const handleSave = async () => {
    const fieldErrors = preValidateFields();

    if (Object.keys(fieldErrors).length === 0) {
      setLoading(true);
      try {
        // // Create new uid to create new motorcycle
        // const motoID = uuidv4();

        // // Create document reference
        // const motoRef = doc(
        //   mobileFirestore,
        //   "appointments",
        //   appointmentDetails.motoId
        // );
        // // Find the exact document
        // const motoDoc = await getDoc(motoRef);
        // // Get Data
        // const motoData = motoDoc.data();
        // let dataToSave;

        // try {
        //   // console.log(motoData.brand, motoData.model);
        //   // Get the reference to the specific document
        //   const refDocRef = doc(firestore, "refDocs", motoData.brand);
        //   const docSnap = await getDoc(refDocRef);
        //   // Check if document exists
        //   if (docSnap.exists()) {
        //     const models = docSnap.data();
        //     // console.log(models);
        //     const smodel = models[motoData.model];
        //     // console.log(smodel);
        //     let categories;

        //     // eslint-disable-next-line no-unused-vars
        //     for (const [key, value] of Object.entries(smodel)) {
        //       const { startYear, endYear } = value;
        //       // console.log(motoData.year, startYear, endYear);
        //       if (motoData.year >= startYear && motoData.year <= endYear) {
        //         // console.log("Found!", value, key);
        //         categories = value;
        //       }
        //     }
        //     // console.log(categories);
        //     dataToSave = processDocumentData(categories);
        //     // console.log(dataToSave);
        //     // console.log("All relevant documents have been processed.");
        //   }
        // } catch (error) {
        //   console.error("Error updating documents: ", error);
        //   return null;
        // }

        // const newMoto = {
        //   id: motoID,
        //   ...motoData,
        //   referenceDocuments: dataToSave,
        //   documents: [],
        //   createdAt: new Date(),
        // };

        // const newMotoRef = await addDoc(
        //   collection(firestore, "motorcycles"),
        //   newMoto
        // );

        // // Create new uid to create new client
        // const userID = uuidv4();

        // // Create document reference
        // const userRef = doc(
        //   mobileFirestore,
        //   "users",
        //   appointmentDetails.userId
        // );
        // // Find the exact document
        // const userDoc = await getDoc(userRef);
        // // Get Data
        // const userData = userDoc.data();

        // const newClient = {
        //   id: userID,
        //   ...userData,
        //   documents: [],
        //   motorcycles: [],
        // };

        // console.log(newClient);
        // // Create Reference
        // const newUserRef = doc(firestore, "clients", appointmentDetails.userId);
        // // Create new client with specific ID
        // await setDoc(newUserRef, newClient);

        // // Create new uid to create new client
        // const appointmentID = uuidv4();
        // const newAppointment = {
        //   client: appointmentDetails.userId,
        //   createdAt: new Date(),
        //   datetime: appointmentDetails.datetime,
        //   id: appointmentID,
        //   motorcycles: [],
        //   notes: appointmentDetails.description,
        //   status: "0",
        // };

        // await addDoc(collection(firestore, "appointments"), newAppointment);

        const dateVal = new Date(freeTimeData.end);
        const timeVal = `${
          dateVal.getHours() < 10 ? "0" : ""
        }${dateVal.getHours()}:${
          dateVal.getMinutes() < 10 ? "0" : ""
        }${dateVal.getMinutes()}`;

        let mobileData;
        if (accept) {
          mobileData = {
            status: "Confirmed",
            endDate: dateVal.toISOString().split("T")[0],
            endTime: timeVal,
          };
        } else {
          mobileData = {
            status: "Rejected",
            rejectReason: appointmentData.rejectReason,
          };
        }

        const appRef = doc(
          mobileFirestore,
          "appointments",
          appointmentDetails.id
        );

        await updateDoc(appRef, mobileData);

        const subcollectionRef = doc(
          mobileFirestore,
          "clients",
          appointmentDetails.userId,
          "appointments",
          appointmentDetails.id
        );

        await updateDoc(subcollectionRef, mobileData);

        const mechanicsRef = doc(
          mobileFirestore,
          "mechanics",
          appointmentDetails.mechanicId
        );
        const mechanicDoc = await getDoc(mechanicsRef);
        const mechanicData = mechanicDoc.data();
        const availableDates = mechanicData.availableDates || {};

        if (accept) {
          if (availableDates[appointmentDetails.startDate]) {
            // Filter out times within the specified range
            const updatedTimes = availableDates[
              appointmentDetails.startDate
            ].filter((time) => {
              // Combine the date and time into a full timestamp
              const fullTimestamp = new Date(
                `${appointmentDetails.startDate}T${time}:00`
              ).getTime();

              console.log(
                new Date(`${appointmentDetails.startDate}T${time}:00`)
              );
              console.log(freeTimeData.start);
              console.log(freeTimeData.end);

              // Compare with startValue and endValue
              return (
                fullTimestamp < freeTimeData.start ||
                fullTimestamp >= freeTimeData.end
              );
            });

            console.log(updatedTimes);

            // Update the availableDates object
            const updatedAvailableDates = {
              ...availableDates,
              [appointmentDetails.startDate]: updatedTimes,
            };

            console.log(updatedAvailableDates);

            await updateDoc(mechanicsRef, {
              availableDates: updatedAvailableDates,
            });
          } else {
            console.error("No date found");
          }
        } else {
          const timesForDate =
            availableDates[appointmentDetails.startDate] || [];

          if (
            appointmentDetails.endDate != null &&
            appointmentDetails.endTime != null
          ) {
            // Generate all times between startTime and endTime
            const timesToAdd = getTimesBetween(
              appointmentDetails.startTime,
              appointmentDetails.endTime
            );

            // Add new times without duplication
            // biome-ignore lint/complexity/noForEach: <explanation>
            timesToAdd.forEach((time) => {
              if (!timesForDate.includes(time)) {
                timesForDate.push(time);
              }
            });
          } else {
            // Avoid duplicating the time
            if (!timesForDate.includes(appointmentDetails.startTime)) {
              timesForDate.push(appointmentDetails.startTime);
            }
          }
          // Sort the times to keep the array organized
          timesForDate.sort();

          // Update the mechanic's document
          availableDates[appointmentDetails.startDate] = timesForDate;

          // const updatedTimes = availableDates[appointmentDetails.startDate]
          //   ? [
          //       ...availableDates[appointmentDetails.startDate],
          //       appointmentDetails.startTime,
          //     ]
          //   : [appointmentDetails.startTime];

          // // Update the availableDates object
          // const updatedAvailableDates = {
          //   ...availableDates,
          //   [appointmentDetails.startDate]: updatedTimes,
          // };

          // console.log(updatedAvailableDates);

          await updateDoc(mechanicsRef, {
            availableDates, //: updatedAvailableDates,
          });
        }

        handleClearData(); // Clear form fields after submission
        onFeedback(t("Operation Successfull!"));
        handleClose();
      } catch (error) {
        console.error("Error adding document: ", error);
        onFeedback(t("Failed to Create New Appointment"));
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open} timeout={250}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                maxWidth: 950,
                width: "100%",
                margin: "0 auto",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                  maxHeight: "95vh",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                {/* Modal */}
                {loading && <LoadingWindow onClose={closeModal} />}

                <IconButton
                  aria-label="delete"
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>

                {/* Container */}
                {/* Header Section */}
                <Grid item xs={12} sm={12} container justifyContent="center">
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography component="h1" variant="r16" pb={4}>
                      {t("View Appointment Request")}
                    </Typography>
                  </Box>
                </Grid>

                <Grid container spacing={2}>
                  {/* Client Field */}
                  <Grid item xs={12} md={4}>
                    <TextField
                      label={t("Client")}
                      name="client"
                      color="secondary"
                      value={appointmentDetails.client}
                      readOnly
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Avatar
                              alt="Client"
                              src={appointmentDetails.clientPhoto}
                              onMouseEnter={(e) =>
                                handleMouseEnter(
                                  e,
                                  appointmentDetails.clientPhoto
                                )
                              }
                              onMouseLeave={handleMouseLeave}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  {/* Client Phone Field */}
                  <Grid item xs={12} md={4}>
                    <TextField
                      label={t("Phone")}
                      name="phone"
                      color="secondary"
                      value={appointmentDetails.clientPhone}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Brand & Model Field */}
                  <Grid item xs={12} md={4}>
                    <TextField
                      label={t("Brand & Model")}
                      name="brandModel"
                      color="secondary"
                      value={`${appointmentDetails.motorcycle.brand} ${appointmentDetails.motorcycle.model} (${appointmentDetails.motorcycle.year}) [${appointmentDetails.motorcycle.license_plate}]`}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Brand & Model Field */}
                  <Grid item xs={12}>
                    <TextField
                      label={t("Description")}
                      name="description"
                      color="secondary"
                      value={appointmentDetails.description}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Complete Switch */}
                  <Grid
                    item
                    xs={12}
                    alignContent={"center"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    display="flex"
                    flex-direction="column"
                  >
                    <FormControl
                      component="fieldset"
                      style={{ marginLeft: "0px" }}
                    >
                      <FormGroup>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Typography
                            variant="r12"
                            sx={{
                              fontSize: {
                                xs: "0.5rem",
                                sm: "0.6rem",
                                md: "0.7rem",
                                lg: "0.8rem",
                                xl: "1rem",
                              },
                            }}
                          >
                            {t("Reject/Accept the Appointment")}
                          </Typography>
                          <SpadaSwitch
                            foreColor={colors.greenAccent[500]}
                            trackColor={colors.redAccent[500]}
                            iconColor={"#000000"}
                            onChange={() => setAccept(!accept)}
                            checked={accept}
                          />
                        </Box>
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* Start Datetime Picker */}
                {accept && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={1}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Typography
                            variant="r16"
                            color={colors.greenAccent[400]}
                            sx={{
                              fontSize: {
                                xs: "1rem", // font size for extra-small screens
                                sm: "1.2rem", // font size for small screens
                                md: "1.2rem", // font size for medium screens
                                lg: "1.5rem", // font size for large screens
                                xl: "1.5rem", // font size for extra-large screens
                              },
                              whiteSpace: "nowrap", // Prevent text wrapping
                              overflow: "hidden", // Hide overflow if any
                              textOverflow: "ellipsis", // Add ellipsis if text overflows
                            }}
                          >
                            {t("Appointment Start Date & Time")}
                          </Typography>
                        </Box>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          adapterLocale={enGB}
                        >
                          <StaticDateTimePicker
                            sx={{
                              borderRadius: theme.shape.borderRadius,
                              // Toolbar
                              "& .MuiPickersToolbar-root": {
                                backgroundColor: colors.blueAccent[800], // "#1976d2" Customize toolbar background color
                                color:
                                  theme.palette.mode === "light"
                                    ? colors.redAccent[500]
                                    : colors.greenAccent[500],
                                borderRadius: theme.shape.borderRadius,
                              },
                              "& .MuiDayCalendar-root": {
                                borderRadius: theme.shape.borderRadius,
                              },
                              // Icon Colors
                              "& .MuiTab-root": {
                                color:
                                  theme.palette.mode === "light"
                                    ? "#000000"
                                    : "#FFFFFF",
                                "&.Mui-selected": {
                                  color:
                                    theme.palette.mode === "light"
                                      ? colors.redAccent[500]
                                      : colors.greenAccent[500],
                                },
                                "&:hover": {
                                  color:
                                    theme.palette.mode === "light"
                                      ? colors.redAccent[700]
                                      : colors.greenAccent[700],
                                },
                              },
                              // Calendar Header Colors
                              "& .MuiPickersCalendarHeader-root": {
                                backgroundColor:
                                  theme.palette.mode === "light"
                                    ? colors.redAccent[700]
                                    : colors.greenAccent[700], // Customize calendar header background
                                color: "#fff", // Customize calendar header text
                                borderRadius: theme.shape.borderRadius,
                              },
                              // Dialog Buttons
                              ".MuiDialogActions-root": {
                                display: "none",
                              },
                              // Days Forecolor
                              "& .MuiPickersDay-root": {
                                color:
                                  theme.palette.mode === "light"
                                    ? colors.redAccent[500]
                                    : colors.greenAccent[500],
                                borderRadius: theme.shape.borderRadius,
                              },
                              // Day of Week Header ForeColor
                              "& .MuiDayCalendar-weekDayLabel": {
                                color:
                                  theme.palette.mode === "light"
                                    ? "#000"
                                    : "#fff",
                              },
                              // Selected Day Background and Fore Colors
                              "& .MuiPickersDay-root.Mui-selected": {
                                backgroundColor:
                                  theme.palette.mode === "light"
                                    ? colors.redAccent[500]
                                    : colors.greenAccent[500], // Customize selected day color
                                color: "#fff", // Customize selected day text color
                              },
                              "& .MuiClockPicker-root": {
                                backgroundColor:
                                  theme.palette.mode === "light"
                                    ? colors.redAccent[500]
                                    : colors.greenAccent[500], // Customize clock background color
                              },
                              // Clock Pointer ForeColor
                              "& .MuiClockPointer-root": {
                                backgroundColor:
                                  theme.palette.mode === "light"
                                    ? colors.redAccent[500]
                                    : colors.greenAccent[500], // Customize clock pointer color
                              },
                            }}
                            slotProps={{
                              toolbar: {
                                toolbarTitle: t("Select Date & Time"),
                              },
                            }}
                            name="start"
                            defaultValue={parseISO(
                              format(new Date(), "yyyy-MM-dd HH:mm:ss")
                            )}
                            value={freeTimeData.start}
                            onChange={(newValue) =>
                              handleDateTimeChange("start", newValue)
                            }
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>

                    <Box flexGrow={true} />

                    <Grid item xs={12} md={6}>
                      <Stack spacing={1}>
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Typography
                            variant="r16"
                            color={colors.greenAccent[400]}
                            sx={{
                              fontSize: {
                                xs: "1rem", // font size for extra-small screens
                                sm: "1.2rem", // font size for small screens
                                md: "1.2rem", // font size for medium screens
                                lg: "1.5rem", // font size for large screens
                                xl: "1.5rem", // font size for extra-large screens
                              },
                              whiteSpace: "nowrap", // Prevent text wrapping
                              overflow: "hidden", // Hide overflow if any
                              textOverflow: "ellipsis", // Add ellipsis if text overflows
                            }}
                          >
                            {t("Appointment End Date & Time")}
                          </Typography>
                        </Box>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          adapterLocale={enGB}
                        >
                          <StaticDateTimePicker
                            sx={{
                              borderRadius: theme.shape.borderRadius,
                              // Toolbar
                              "& .MuiPickersToolbar-root": {
                                backgroundColor: colors.blueAccent[800], // "#1976d2" Customize toolbar background color
                                color:
                                  theme.palette.mode === "light"
                                    ? colors.redAccent[500]
                                    : colors.greenAccent[500],
                                borderRadius: theme.shape.borderRadius,
                              },
                              "& .MuiDayCalendar-root": {
                                borderRadius: theme.shape.borderRadius,
                              },
                              // Icon Colors
                              "& .MuiTab-root": {
                                color:
                                  theme.palette.mode === "light"
                                    ? "#000000"
                                    : "#FFFFFF",
                                "&.Mui-selected": {
                                  color:
                                    theme.palette.mode === "light"
                                      ? colors.redAccent[500]
                                      : colors.greenAccent[500],
                                },
                                "&:hover": {
                                  color:
                                    theme.palette.mode === "light"
                                      ? colors.redAccent[700]
                                      : colors.greenAccent[700],
                                },
                              },
                              // Calendar Header Colors
                              "& .MuiPickersCalendarHeader-root": {
                                backgroundColor:
                                  theme.palette.mode === "light"
                                    ? colors.redAccent[700]
                                    : colors.greenAccent[700], // Customize calendar header background
                                color: "#fff", // Customize calendar header text
                                borderRadius: theme.shape.borderRadius,
                              },
                              // Dialog Buttons
                              ".MuiDialogActions-root": {
                                display: "none",
                              },
                              // Days Forecolor
                              "& .MuiPickersDay-root": {
                                color:
                                  theme.palette.mode === "light"
                                    ? colors.redAccent[500]
                                    : colors.greenAccent[500],
                                borderRadius: theme.shape.borderRadius,
                              },
                              // Day of Week Header ForeColor
                              "& .MuiDayCalendar-weekDayLabel": {
                                color:
                                  theme.palette.mode === "light"
                                    ? "#000"
                                    : "#fff",
                              },
                              // Selected Day Background and Fore Colors
                              "& .MuiPickersDay-root.Mui-selected": {
                                backgroundColor:
                                  theme.palette.mode === "light"
                                    ? colors.redAccent[500]
                                    : colors.greenAccent[500], // Customize selected day color
                                color: "#fff", // Customize selected day text color
                              },
                              "& .MuiClockPicker-root": {
                                backgroundColor:
                                  theme.palette.mode === "light"
                                    ? colors.redAccent[500]
                                    : colors.greenAccent[500], // Customize clock background color
                              },
                              // Clock Pointer ForeColor
                              "& .MuiClockPointer-root": {
                                backgroundColor:
                                  theme.palette.mode === "light"
                                    ? colors.redAccent[500]
                                    : colors.greenAccent[500], // Customize clock pointer color
                              },
                            }}
                            slotProps={{
                              toolbar: {
                                toolbarTitle: t("Select Date & Time"),
                              },
                            }}
                            name="end"
                            defaultValue={parseISO(
                              format(new Date(), "yyyy-MM-dd HH:mm:ss")
                            )}
                            value={freeTimeData.end}
                            onChange={(newValue) =>
                              handleDateTimeChange("end", newValue)
                            }
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                  </Grid>
                )}

                {/* Rejection Reason Field */}
                {!accept && (
                  <Grid container spacing={2} mt={1}>
                    <Grid item xs={12}>
                      <TextField
                        label={t("Rejection Reason")}
                        name="rejectReason"
                        id="textfield-rejectReason"
                        color="secondary"
                        required
                        value={appointmentData.rejectReason}
                        onChange={handleInputChange}
                        multiline
                        fullWidth
                        error={!!errors.rejectReason}
                        helperText={errors.rejectReason}
                      />
                    </Grid>
                  </Grid>
                )}

                {/* Submit Button */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      justifyContent={"center"}
                      alignContent={"center"}
                      flexDirection={"row"}
                      flex={true}
                    >
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() => handleSave()}
                        sx={{
                          mt: 4,
                          mb: 1,
                          backgroundColor: colors.greenAccent[700],
                          color: "#FFFFFF",
                          height: "50px",
                          "&:hover": {
                            backgroundColor: colors.greenAccent[500],
                          },
                        }}
                      >
                        <Typography component="h1" variant="r16">
                          {t("Save")}
                        </Typography>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>

                <HoverImage
                  src={hoveredImage}
                  isVisible={!!hoveredImage}
                  position={hoverPosition}
                />
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default EvaluateAppointment;
