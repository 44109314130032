// src/components/DatabaseOps.jsx

import { firestore } from "../firebaseConfig";
import { arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";

// Template for database-related functions as a React component
// const DatabaseOps = () => {
// Add a running balance entry to a client's document
export async function addRunningBalanceEntry(clientId, docRef, payment, type) {
  try {
    const clientDocRef = doc(firestore, "clients", clientId);

    const balanceDocRef = doc(firestore, "balance", "ca2R5LtmarqOwnunaOu8");
    const balanceSnap = await getDoc(balanceDocRef); // Await the document snapshot
    const balance = balanceSnap.data(); // Access the data
    const credit = Number(balance.credit) + Number(payment);
    const newBalance = Number(credit) - Number(balance.debit);

    let updateClientPromise;
    let updateBalancePromise;

    if (type === "sale") {
      // Update User Balance
      updateClientPromise = updateDoc(clientDocRef, {
        runningBalance: arrayUnion({
          saleReference: docRef,
          total: Number(payment),
          datetime: new Date(),
        }),
      });

      // Update Total Balance
      const saleBalance = balance.sales + Number(payment);

      updateBalancePromise = updateDoc(balanceDocRef, {
        current: Number(newBalance),
        credit: credit,
        sales: Number(saleBalance),
      });
    } else {
      // Update User Balance
      updateClientPromise = updateDoc(clientDocRef, {
        runningBalance: arrayUnion({
          workReference: docRef,
          total: Number(payment),
          datetime: new Date(),
        }),
      });

      // Update Total Balance
      const worksBalance = balance.works + Number(payment);

      updateBalancePromise = updateDoc(balanceDocRef, {
        current: Number(newBalance),
        credit: Number(credit),
        works: Number(worksBalance),
      });
    }
    //   console.log("Entry added successfully!");

    await Promise.all([updateClientPromise, updateBalancePromise]);
  } catch (error) {
    //   console.error("Error adding running balance entry:", error);
    return Promise.reject(error); // Return the error as a rejected promise
  }
}

export async function enterReceivablePayment(
  clientId,
  reference,
  paymentAmount
) {
  try {
    const clientDocRef = doc(firestore, "clients", clientId);

    // Fetch the client's document
    const clientSnapshot = await getDoc(clientDocRef);

    if (!clientSnapshot.exists()) {
      // console.error("Client not found");
      return;
    }

    const clientData = clientSnapshot.data();
    const runningBalance = clientData.runningBalance || [];

    // Find the entry with the matching reference
    const entryToUpdate = runningBalance.find(
      (entry) =>
        (entry.workReference && entry.workReference.path === reference.path) ||
        (entry.saleReference && entry.saleReference.path === reference.path)
    );

    if (!entryToUpdate) {
      // console.error("Reference not found");
      return;
    }

    // Add the new payment
    const paymentEntry = {
      datetime: new Date(), // Use serverTimestamp() if preferred
      paymentAmount: paymentAmount,
    };

    // Ensure the payments array exists
    entryToUpdate.payments = entryToUpdate.payments || [];

    // Append the new payment
    entryToUpdate.payments.push(paymentEntry);

    // Save the updated runningBalance back to Firestore
    const updateClientPromise = await updateDoc(clientDocRef, {
      runningBalance: runningBalance, // The reference is updated directly since arrays are by reference
    });

    const balanceDocRef = doc(firestore, "balance", "ca2R5LtmarqOwnunaOu8");
    const balanceSnap = await getDoc(balanceDocRef);
    const balance = balanceSnap.data();
    const credit = Number(balance.credit) - Number(paymentAmount);
    const newBalance = Number(credit) - Number(balance.debit);

    // Update Total Balance
    const updateBalancePromise = updateDoc(balanceDocRef, {
      current: Number(newBalance),
      credit: Number(credit),
    });

    // console.log("Payment added successfully!");
    await Promise.all([updateClientPromise, updateBalancePromise]);
  } catch (error) {
    // console.error("Error adding payment");
    return Promise.reject(error);
  }
}

export async function addRunningBalanceToSupplier(supplierId, docRef, payment) {
  try {
    const supplierDocRef = doc(firestore, "suppliers", supplierId);

    const balanceDocRef = doc(firestore, "balance", "ca2R5LtmarqOwnunaOu8");
    const balanceSnap = await getDoc(balanceDocRef); // Await the document snapshot
    const balance = balanceSnap.data(); // Access the data
    const debit = Number(balance.debit) + Number(payment);
    const newBalance = Number(balance.credit) - Number(debit);

    // Update User Balance
    const updateSupplierPromise = updateDoc(supplierDocRef, {
      runningBalance: arrayUnion({
        purchaseReference: docRef,
        total: Number(payment),
        datetime: new Date(),
      }),
    });

    // Update Total Balance
    const purchaseBalance = Number(balance.purchases) + Number(payment);

    const updateBalancePromise = updateDoc(balanceDocRef, {
      current: Number(newBalance),
      purchases: Number(purchaseBalance),
      debit: Number(debit),
    });

    //   console.log("Entry added successfully!");
    await Promise.all([updateSupplierPromise, updateBalancePromise]);
  } catch (error) {
    //   console.error("Error adding running balance entry:", error);
    return Promise.reject(error); // Return the error as a rejected promise
  }
}

export async function enterPayablePayment(
  supplierId,
  reference,
  paymentAmount
) {
  try {
    const supplierDocRef = doc(firestore, "suppliers", supplierId);

    // Fetch the client's document
    const supplierSnapshot = await getDoc(supplierDocRef);

    if (!supplierSnapshot.exists()) {
      // console.error("Supplier not found");
      return;
    }

    const supplierData = supplierSnapshot.data();
    const runningBalance = supplierData.runningBalance || [];

    // Find the entry with the matching reference
    const entryToUpdate = runningBalance.find(
      (entry) =>
        // (entry.workReference && entry.workReference.path === reference.path) ||
        entry.purchaseReference &&
        entry.purchaseReference.path === reference.path
    );

    if (!entryToUpdate) {
      // console.error("Reference not found");
      return;
    }
    console.log(entryToUpdate);
    // Add the new payment
    const paymentEntry = {
      datetime: new Date(), // Use serverTimestamp() if preferred
      paymentAmount: paymentAmount,
    };

    // Ensure the payments array exists
    entryToUpdate.payments = entryToUpdate.payments || [];

    // Append the new payment
    entryToUpdate.payments.push(paymentEntry);

    // Save the updated runningBalance back to Firestore
    const updateSupplierPromise = await updateDoc(supplierDocRef, {
      runningBalance: runningBalance, // The reference is updated directly since arrays are by reference
    });

    const balanceDocRef = doc(firestore, "balance", "ca2R5LtmarqOwnunaOu8");
    const balanceSnap = await getDoc(balanceDocRef); // Await the document snapshot
    const balance = balanceSnap.data(); // Access the data
    const debit = Number(balance.debit) - Number(paymentAmount);
    const newBalance = Number(balance.credit) - Number(debit);

    // Update Total Balance
    const updateBalancePromise = updateDoc(balanceDocRef, {
      current: Number(newBalance),
      debit: Number(debit),
    });

    // console.log("Payment added successfully!");
    await Promise.all([updateSupplierPromise, updateBalancePromise]);
  } catch (error) {
    // console.error("Error adding payment");
    return Promise.reject(error);
  }
}

export async function updateWorkRunningBalance(
  clientId,
  workReference,
  newTotal
) {
  try {
    const clientDocRef = doc(firestore, "clients", clientId);

    // Fetch the client's document
    const clientSnapshot = await getDoc(clientDocRef);

    if (!clientSnapshot.exists()) {
      // console.error("Client not found");
      return;
    }

    const clientData = clientSnapshot.data();
    const runningBalance = clientData.runningBalance || [];

    // Find the entry with the matching workReference
    const entryToUpdate = runningBalance.find(
      (entry) => entry.workReference?.path === workReference.path
    );

    if (!entryToUpdate) {
      // console.error("Work reference not found in runningBalance");
      return;
    }

    // Update the total field
    const oldTotal = entryToUpdate.total;
    entryToUpdate.total = newTotal;

    // Save the updated runningBalance back to Firestore
    const clientBalancePromise = updateDoc(clientDocRef, { runningBalance });

    const balanceDocRef = doc(firestore, "balance", "ca2R5LtmarqOwnunaOu8");
    const balanceSnap = await getDoc(balanceDocRef);
    const balance = balanceSnap.data();
    const credit = Number(balance.credit) - Number(oldTotal) + Number(newTotal);
    const newBalance = Number(credit) - Number(balance.debit);

    // Update Total Balance
    const worksBalance =
      Number(balance.works) - Number(oldTotal) + Number(newTotal);

    const updateBalancePromise = updateDoc(balanceDocRef, {
      current: Number(newBalance),
      credit: Number(credit),
      works: Number(worksBalance),
    });
    // console.log("Work runningBalance updated successfully!");
    await Promise.all([clientBalancePromise, updateBalancePromise]);
  } catch (error) {
    // console.error("Error updating work runningBalance:", error);
    return Promise.reject(error);
  }
}

export async function updateSaleRunningBalance(
  clientId,
  saleReference,
  newTotal
) {
  try {
    const clientDocRef = doc(firestore, "clients", clientId);

    // Fetch the client's document
    const clientSnapshot = await getDoc(clientDocRef);

    if (!clientSnapshot.exists()) {
      // console.error("Client not found");
      return;
    }

    const clientData = clientSnapshot.data();
    const runningBalance = clientData.runningBalance || [];

    // Find the entry with the matching saleReference
    const entryToUpdate = runningBalance.find(
      (entry) => entry.saleReference?.path === saleReference.path
    );

    if (!entryToUpdate) {
      // console.error("Sale reference not found in runningBalance");
      return;
    }

    // Update the total field
    const oldTotal = entryToUpdate.total;
    entryToUpdate.total = newTotal;

    // Save the updated runningBalance back to Firestore
    const clientBalancePromise = updateDoc(clientDocRef, { runningBalance });

    const balanceDocRef = doc(firestore, "balance", "ca2R5LtmarqOwnunaOu8");
    const balanceSnap = await getDoc(balanceDocRef);
    const balance = balanceSnap.data();
    const credit = Number(balance.credit) - Number(oldTotal) + Number(newTotal);
    const newBalance = Number(credit) - Number(balance.debit);

    // Update Total Balance
    const salesBalance =
      Number(balance.sales) - Number(oldTotal) + Number(newTotal);

    const updateBalancePromise = updateDoc(balanceDocRef, {
      current: Number(newBalance),
      credit: Number(credit),
      sales: Number(salesBalance),
    });

    // console.log("Sale runningBalance updated successfully!");
    await Promise.all([clientBalancePromise, updateBalancePromise]);
  } catch (error) {
    // console.error("Error updating sale runningBalance:", error);
    return Promise.reject(error);
  }
}

export async function updatePurchaseRunningBalance(
  supplierId,
  purchaseReference,
  newTotal
) {
  try {
    const supplierDocRef = doc(firestore, "suppliers", supplierId);

    // Fetch the client's document
    const supplierSnapshot = await getDoc(supplierDocRef);

    if (!supplierSnapshot.exists()) {
      // console.error("Client not found");
      return;
    }

    const supplierData = supplierSnapshot.data();
    const runningBalance = supplierData.runningBalance || [];

    // Find the entry with the matching purchaseReference
    const entryToUpdate = runningBalance.find(
      (entry) => entry.purchaseReference?.path === purchaseReference.path
    );

    if (!entryToUpdate) {
      // console.error("Purchase reference not found in runningBalance");
      return;
    }

    // Update the total field
    const oldTotal = entryToUpdate.total;
    entryToUpdate.total = newTotal;

    // Save the updated runningBalance back to Firestore
    const clientBalancePromise = updateDoc(supplierDocRef, { runningBalance });

    const balanceDocRef = doc(firestore, "balance", "ca2R5LtmarqOwnunaOu8");
    const balanceSnap = await getDoc(balanceDocRef);
    const balance = balanceSnap.data();
    const debit = Number(balance.debit) - Number(oldTotal) + Number(newTotal);
    const newBalance = Number(balance.credit) - Number(debit);

    // Update Total Balance
    const purchasesBalance =
      Number(balance.purchases) - Number(oldTotal) + Number(newTotal);

    const updateBalancePromise = updateDoc(balanceDocRef, {
      current: Number(newBalance),
      debit: Number(debit),
      purchases: Number(purchasesBalance),
    });

    // console.log("Purchase runningBalance updated successfully!");
    await Promise.all([clientBalancePromise, updateBalancePromise]);
  } catch (error) {
    // console.error("Error updating purchase runningBalance:", error);
    return Promise.reject(error);
  }
}

//   return {
//     addRunningBalanceEntry,
//     updateRunningBalanceEntry,
//   };
// };

// export default DatabaseOps;
