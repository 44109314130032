// src/screens/EnterReceivablePayment.jsx

import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  useTheme,
  Backdrop,
  Modal,
  Fade,
  IconButton,
} from "@mui/material";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useData } from "../components/DataProvider";
// Icons
import CloseIcon from "@mui/icons-material/Close";
import LoadingWindow from "../components/LoadingWindow";
import { enterReceivablePayment } from "../components/DatabaseOps";
import ViewWorkDetails from "./ViewWorkDetails";
import ViewSaleDetails from "./ViewSaleDetails";

const EnterReceivablePayment = ({ onClose, onFeedback, paymentDetails }) => {
  // const { currentUser } = useAuth();
  const { works, clients, sales } = useData();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [client, setClient] = useState({});
  const [work, setWork] = useState(null);
  const [sale, setSale] = useState(null);
  const [errors, setErrors] = useState({});
  const [payment, setPayment] = useState("");
  const [workDetails, setWorkDetails] = useState([]);
  const [isWorkDetailsOpen, setIsWorkDetailsOpen] = useState(false);
  const [saleDetails, setSaleDetails] = useState([]);
  const [isSaleDetailsOpen, setIsSaleDetailsOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    setClient(clients.find((client) => client.docID === paymentDetails.client));

    if (paymentDetails.type === t("Work")) {
      setWork(works.find((work) => work.docID === paymentDetails.doc));
    } else {
      setSale(sales.find((sale) => sale.docID === paymentDetails.doc));
    }

    setLoading(false);
  }, [
    works,
    clients,
    sales,
    paymentDetails.client,
    paymentDetails.doc,
    paymentDetails.type,
    t,
  ]);

  // Toggle modal open/close
  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  const handlePaymentChange = (e) => {
    const { value } = e.target;
    setPayment(value);
    setErrors({});
  };

  const closeModal = () => {
    setLoading(false);
  };

  const handleCancel = async () => {
    handleClose();
  };

  const validateFields = () => {
    const newErrors = {};
    if (!payment) newErrors.payment = t("Payment is required");
    return newErrors;
  };

  const handleClearData = () => {
    setPayment(0);
    setErrors({});
  };

  const handleDetails = () => {
    if (paymentDetails.type === t("Work")) {
      setWorkDetails(work);
      setIsWorkDetailsOpen(true);
    } else {
      setSaleDetails(sale);
      setIsSaleDetailsOpen(true);
    }
  };

  const handleSubmit = async () => {
    const fieldErrors = validateFields();
    if (Object.keys(fieldErrors).length === 0) {
      setLoading(true);
      try {
        await enterReceivablePayment(
          client.docID,
          paymentDetails.docRef,
          payment
        );
        handleClearData();
        onFeedback(t("Operation Successfull!"));
        handleClose();
      } catch (error) {
        console.error("Error adding document: ", error);
        onFeedback(t("Failed to create new payment"));
      } finally {
        setLoading(false);
      }
    } else {
      setErrors(fieldErrors);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open} timeout={250}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "95vh",
                maxWidth: 600,
                width: "100%",
                margin: "0 auto",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                  maxHeight: "95vh",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                {/* Modal */}
                {loading && <LoadingWindow onClose={closeModal} />}

                <IconButton
                  aria-label="delete"
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>

                {/* View Work Details Modal */}
                {isWorkDetailsOpen && (
                  <ViewWorkDetails
                    onClose={closeModal}
                    workDetails={workDetails}
                  />
                )}

                {/* View Sale Details Modal */}
                {isSaleDetailsOpen && (
                  <ViewSaleDetails
                    onClose={closeModal}
                    saleDetails={saleDetails}
                  />
                )}

                <Grid container spacing={2}>
                  {/* Header Section */}
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("View Details")}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Type Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Type")}
                      name="corporation"
                      color="secondary"
                      value={paymentDetails.type}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Client Name Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Client")}
                      name="contact_name"
                      color="secondary"
                      value={client.full_name}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Sale Date Field */}
                  {sale && (
                    <Grid item xs={12}>
                      <TextField
                        label={t("Date")}
                        name="date"
                        color="secondary"
                        value={
                          sale.date
                            ? format(sale.date.toDate(), "dd/MM/yyyy")
                            : ""
                        }
                        readOnly
                        fullWidth
                      />
                    </Grid>
                  )}

                  {work && (
                    <React.Fragment>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label={t("Start Date")}
                          name="startDate"
                          color="secondary"
                          value={
                            work.startDate
                              ? format(work.startDate.toDate(), "dd/MM/yyyy")
                              : ""
                          }
                          readOnly
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          label={t("Completion Date")}
                          name="completionDate"
                          color="secondary"
                          value={
                            work.completionDate
                              ? format(
                                  work.completionDate.toDate(),
                                  "dd/MM/yyyy"
                                )
                              : ""
                          }
                          readOnly
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          label={t("Description")}
                          name="completionDate"
                          color="secondary"
                          value={work.work_details}
                          readOnly
                          fullWidth
                        />
                      </Grid>
                    </React.Fragment>
                  )}

                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Payment Amount")}
                      name="payment"
                      color="secondary"
                      value={payment}
                      onChange={handlePaymentChange}
                      fullWidth
                      required
                      error={!!errors.payment}
                      helperText={errors.payment}
                    />
                  </Grid>

                  {/* Show Details Button */}
                  <Grid item xs={6}>
                    <Box
                      justifyContent={"center"}
                      alignContent={"center"}
                      flexDirection={"row"}
                      flex={true}
                    >
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() => handleDetails()}
                        sx={{
                          backgroundColor: colors.greenAccent[700],
                          color: "#FFFFFF",
                          height: "50px",
                          "&:hover": {
                            backgroundColor: colors.greenAccent[500],
                          },
                        }}
                      >
                        <Typography
                          component="h1"
                          variant="r16"
                          paddingLeft="10px"
                        >
                          {t("Show Details")}
                        </Typography>
                      </Button>
                    </Box>
                  </Grid>

                  {/* Submit Button */}
                  <Grid item xs={12}>
                    <Box
                      justifyContent={"center"}
                      alignContent={"center"}
                      flexDirection={"row"}
                      flex={true}
                    >
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() => handleSubmit()}
                        sx={{
                          mt: 1,
                          mb: 0,
                          backgroundColor: colors.greenAccent[700],
                          color: "#FFFFFF",
                          height: "50px",
                          "&:hover": {
                            backgroundColor: colors.greenAccent[500],
                          },
                        }}
                      >
                        <Typography
                          component="h1"
                          variant="r16"
                          paddingLeft="10px"
                        >
                          {t("Confirm")}
                        </Typography>
                      </Button>
                    </Box>
                  </Grid>

                  {/* Cancel Button */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#A60A05", //D10A03
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Cancel")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default EnterReceivablePayment;
