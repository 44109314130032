// src/App.js

// import { useState } from "react";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ColorModeContext, useMode } from "./theme";
import { AuthProvider, useAuth } from "./components/AuthContext";
import { CssBaseline, ThemeProvider } from "@mui/material";
import PrivateRoute from "./components/PrivateRoute";
import "./App.css";
import Login from "./screens/Login";
import { DataProvider } from "./components/DataProvider";
import NavBar from "./components/NavBar";
import Dashboard from "./screens/Dashboard";
import Stocks from "./screens/Stocks";
import Sales from "./screens/Sales";
import Works from "./screens/Works";
import Clients from "./screens/Clients";
import Motorcycles from "./screens/Motorcycles";
import Purchases from "./screens/Purchases";
import Suppliers from "./screens/Suppliers";
import Appointments from "./screens/Appointments";
import KeyElements from "./screens/KeyElements";
import ProjectManagement from "./screens/ProjectManagement";
import Balance from "./screens/Balance";
// import SignUp from "./screens/SignUp";

function App() {
  const [theme, colorMode] = useMode();

  return (
    <AuthProvider>
      <DataProvider>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />

            <Router>
              <Routes>
                <Route
                  path="/login"
                  element={
                    <RedirectToDashboardIfLoggedIn>
                      <Login />
                    </RedirectToDashboardIfLoggedIn>
                  }
                />

                {/* <Route path="/signup" element={<SignUp />} /> */}

                <Route
                  path="/dashboard"
                  element={
                    <PrivateRoute>
                      <div className="app">
                        <main className="content">
                          <NavBar />
                          <Dashboard />
                        </main>
                      </div>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/stocks"
                  element={
                    <PrivateRoute>
                      <div className="app">
                        <main className="content">
                          <NavBar />
                          <Stocks />
                        </main>
                      </div>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/purchases"
                  element={
                    <PrivateRoute>
                      <div className="app">
                        <main className="content">
                          <NavBar />
                          <Purchases />
                        </main>
                      </div>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/sales"
                  element={
                    <PrivateRoute>
                      <div className="app">
                        <main className="content">
                          <NavBar />
                          <Sales />
                        </main>
                      </div>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/works"
                  element={
                    <PrivateRoute>
                      <div className="app">
                        <main className="content">
                          <NavBar />
                          <Works />
                        </main>
                      </div>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/clients"
                  element={
                    <PrivateRoute>
                      <div className="app">
                        <main className="content">
                          <NavBar />
                          <Clients />
                        </main>
                      </div>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/motorcycles"
                  element={
                    <PrivateRoute>
                      <div className="app">
                        <main className="content">
                          <NavBar />
                          <Motorcycles />
                        </main>
                      </div>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/suppliers"
                  element={
                    <PrivateRoute>
                      <div className="app">
                        <main className="content">
                          <NavBar />
                          <Suppliers />
                        </main>
                      </div>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/appointments"
                  element={
                    <PrivateRoute>
                      <div className="app">
                        <main className="content">
                          <NavBar />
                          <Appointments />
                        </main>
                      </div>
                    </PrivateRoute>
                  }
                />

                {/* <Route
                  path="/profile"
                  element={
                    <PrivateRoute>
                      <div className="app">
                        <main className="content">
                          <NavBar />
                          <Profile />
                        </main>
                      </div>
                    </PrivateRoute>
                  }
                /> */}

                <Route
                  path="/keyelements"
                  element={
                    <PrivateRoute>
                      <div className="app">
                        <main className="content">
                          <NavBar />
                          <KeyElements />
                        </main>
                      </div>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/projectmanagement"
                  element={
                    <PrivateRoute>
                      <div className="app">
                        <main className="content">
                          <NavBar />
                          <ProjectManagement />
                        </main>
                      </div>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/balance"
                  element={
                    <PrivateRoute>
                      <div className="app">
                        <main className="content">
                          <NavBar />
                          <Balance />
                        </main>
                      </div>
                    </PrivateRoute>
                  }
                />

                <Route path="*" element={<Navigate to="/login" />} />
              </Routes>
            </Router>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </DataProvider>
    </AuthProvider>
  );
}

const RedirectToDashboardIfLoggedIn = ({ children }) => {
  const { currentUser } = useAuth();
  return currentUser ? <Navigate to="/dashboard" /> : children;
};

export default App;
