// src/screens/Balance.jsx

import React, { useEffect, useState } from "react";
import { useAuth } from "../components/AuthContext";
import {
  Box,
  Button,
  Collapse,
  Grid,
  Typography,
  useTheme,
  List,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  IconButton,
  ListItem,
} from "@mui/material";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { CustomMessage } from "../components/Common";
import { useData } from "../components/DataProvider";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CheckIcon from "@mui/icons-material/Check";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EnterReceivablePayment from "./EnterReceivablePayment";
import EnterPayablePayment from "./EnterPayablePayment";

const Balance = () => {
  const { currentUser } = useAuth();
  const { clients, suppliers, works, sales, purchases } = useData();
  const [clientsData, setClientsData] = useState([]);
  const [suppliersData, setSuppliersData] = useState([]);
  const [openClients, setOpenClients] = useState({});
  const [openReceivablePayments, setOpenReceivablePayments] = useState({});
  const [openSuppliers, setOpenSuppliers] = useState({});
  const [openPayablePayments, setOpenPayablePayments] = useState({});
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [message, setMessage] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [isEnterReceivablePaymentOpen, setIsEnterReceivablePaymentOpen] =
    useState(false);
  const [isEnterPayablePaymentOpen, setIsEnterPayablePaymentOpen] =
    useState(false);
  const [paymentDetails, setPaymentDetails] = useState({});
  const { t } = useTranslation();

  const closeModal = () => {
    setIsEnterReceivablePaymentOpen(false);
    setIsEnterPayablePaymentOpen(false);
  };

  const handleEnterReceivablePayment = async (
    clientID,
    docID,
    docRef,
    type
  ) => {
    const details = {
      client: clientID,
      doc: docID,
      docRef: docRef,
      type: type,
    };

    // Set ID to edit
    setPaymentDetails(details);
    // Open Modal
    setIsEnterReceivablePaymentOpen(true);
  };

  const handleEnterPayablePayment = async (supplierID, docID, docRef, type) => {
    const details = {
      supplier: supplierID,
      doc: docID,
      docRef: docRef,
      tyoe: type,
    };

    // Set ID to edit
    setPaymentDetails(details);
    // Open Modal
    setIsEnterPayablePaymentOpen(true);
  };

  const handleFeedback = (feedback) => {
    setMessage(feedback);
    setOpenMessage(true);
  };

  const handleMessageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  useEffect(() => {
    async function fetchClients() {
      try {
        const _clientsData = [];
        const _suppliersData = [];

        for (const client of clients) {
          const runningBalance = client.runningBalance || [];

          // Fetching the work data
          const workDetails = runningBalance.map((entry) => {
            if (entry.workReference) {
              // Convert Firestore DocumentReference to string and extract ID
              const workReferenceString =
                typeof entry.workReference === "string"
                  ? entry.workReference
                  : entry.workReference?.path;

              const workId = workReferenceString?.split("/")[1];
              const work = works.find((work) => work.docID === workId);

              // Format dates
              const startDateFormatted = work?.startDate
                ? format(work.startDate.toDate(), "dd/MM/yyyy")
                : "";
              const completionDateFormatted = work?.completionDate
                ? format(work.completionDate.toDate(), "dd/MM/yyyy")
                : "";

              return {
                workID: work.docID,
                reference: entry.workReference,
                startDate: startDateFormatted,
                completionDate: completionDateFormatted,
                type: t("Work"),
              };
            }
            // Convert Firestore DocumentReference to string and extract ID
            const saleReferenceString =
              typeof entry.saleReference === "string"
                ? entry.saleReference
                : entry.saleReference?.path;

            const saleId = saleReferenceString?.split("/")[1];
            const sale = sales.find((sale) => sale.docID === saleId);

            // Format dates
            const startDateFormatted = sale?.date
              ? format(sale.date.toDate(), "dd/MM/yyyy")
              : "";

            return {
              saleID: sale.docID,
              reference: entry.saleReference,
              date: startDateFormatted,
              type: t("Sale"),
            };
          });

          // Calculate total remainingPayment and total payments for each debt
          const runningBalanceWithPayments = runningBalance.map((entry) => {
            const totalPayments = (entry.payments || []).reduce(
              (sum, payment) => {
                return Number(sum + (Number(payment.paymentAmount) || 0));
              },
              0
            );

            return {
              ...entry,
              totalPayments,
            };
          });

          // Calculate total remaining debt (total - totalPayments)
          const totalRemainingDebt = runningBalanceWithPayments.reduce(
            (total, entry) => {
              const remainingDebt =
                (entry.total || 0) - (entry.totalPayments || 0);
              return total + remainingDebt;
            },
            0
          );

          if (totalRemainingDebt > 0) {
            _clientsData.push({
              id: client.docID,
              fullName: client.full_name || "Unnamed Client",
              total: runningBalance.length === 0 ? "" : totalRemainingDebt,
              runningBalance: runningBalanceWithPayments.map(
                (entry, index) => ({
                  ...entry,
                  workDetails: workDetails[index], // Keep workDetails mapping
                })
              ),
            });
          }
        }

        setClientsData(_clientsData);

        for (const supplier of suppliers) {
          const runningBalance = supplier.runningBalance || [];

          // Fetching the work data
          const workDetails = runningBalance.map((entry) => {
            // if (entry.workReference) {
            //   // Convert Firestore DocumentReference to string and extract ID
            //   const workReferenceString =
            //     typeof entry.workReference === "string"
            //       ? entry.workReference
            //       : entry.workReference?.path;

            //   const workId = workReferenceString?.split("/")[1];
            //   const work = works.find((work) => work.docID === workId);

            //   // Format dates
            //   const startDateFormatted = work?.startDate
            //     ? format(work.startDate.toDate(), "dd/MM/yyyy")
            //     : "";
            //   const completionDateFormatted = work?.completionDate
            //     ? format(work.completionDate.toDate(), "dd/MM/yyyy")
            //     : "";

            //   return {
            //     workID: work.docID,
            //     reference: entry.workReference,
            //     startDate: startDateFormatted,
            //     completionDate: completionDateFormatted,
            //     type: t("Work"),
            //   };
            // }
            // Convert Firestore DocumentReference to string and extract ID
            const purchaseReferenceString =
              typeof entry.purchaseReference === "string"
                ? entry.purchaseReference
                : entry.purchaseReference?.path;

            const purchaseId = purchaseReferenceString?.split("/")[1];
            const purchase = purchases.find(
              (purchase) => purchase.docID === purchaseId
            );

            // Format dates
            const startDateFormatted = purchase?.date
              ? format(purchase.date.toDate(), "dd/MM/yyyy")
              : "";

            return {
              purchaseID: purchase.docID,
              reference: entry.purchaseReference,
              date: startDateFormatted,
              type: t("Purchase"),
            };
          });

          // Calculate total remainingPayment and total payments for each debt
          const runningBalanceWithPayments = runningBalance.map((entry) => {
            const totalPayments = (entry.payments || []).reduce(
              (sum, payment) => {
                return Number(sum + (Number(payment.paymentAmount) || 0));
              },
              0
            );

            return {
              ...entry,
              totalPayments,
            };
          });

          // Calculate total remaining debt (total - totalPayments)
          const totalRemainingDebt = runningBalanceWithPayments.reduce(
            (total, entry) => {
              const remainingDebt =
                (entry.total || 0) - (entry.totalPayments || 0);
              return total + remainingDebt;
            },
            0
          );

          if (totalRemainingDebt > 0) {
            _suppliersData.push({
              id: supplier.docID,
              fullName: supplier.corporation || "Unnamed Supplier",
              total: runningBalance.length === 0 ? "" : totalRemainingDebt,
              runningBalance: runningBalanceWithPayments.map(
                (entry, index) => ({
                  ...entry,
                  workDetails: workDetails[index], // Keep workDetails mapping
                })
              ),
            });
          }
        }

        setSuppliersData(_suppliersData);
      } catch (error) {
        // console.error("Error fetching clients:", error);
        return;
      }
    }

    fetchClients();
  }, [clients, suppliers, works, sales, purchases, t]);

  const handleClientClick = (clientId) => {
    setOpenClients((prevState) => ({
      ...prevState,
      [clientId]: !prevState[clientId],
    }));
  };

  const handleReceivablePaymentToggle = (index) => {
    setOpenReceivablePayments((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleSupplierClick = (supplierId) => {
    setOpenSuppliers((prevState) => ({
      ...prevState,
      [supplierId]: !prevState[supplierId],
    }));
  };

  const handlePayablePaymentToggle = (index) => {
    setOpenPayablePayments((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  if (currentUser.purchasedProducts.includes("stocksModule")) {
    return (
      <Box m="20px" sx={{ height: "100vh" }}>
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Grid container spacing={2}>
            {/* Feedback Mechanism */}
            <CustomMessage
              open={openMessage}
              onClose={handleMessageClose}
              message={message}
            />
            <Grid item xs={2}>
              <Box mb="30px">
                <Typography
                  variant="h2"
                  color={colors.grey[200]}
                  fontWeight="bold"
                  sx={{
                    fontSize: {
                      xs: "1.5rem", // font size for extra-small screens
                      sm: "2rem", // font size for small screens
                      md: "3rem", // font size for medium screens
                      lg: "3.5rem", // font size for large screens
                      xl: "3.5rem", // font size for extra-large screens
                    },
                    whiteSpace: "nowrap",
                  }}
                >
                  {t("Receivable/Payable Status")}
                </Typography>
                <Typography
                  variant="r16"
                  color={colors.greenAccent[400]}
                  sx={{
                    fontSize: {
                      xs: "1rem", // font size for extra-small screens
                      sm: "1.2rem", // font size for small screens
                      md: "1.2rem", // font size for medium screens
                      lg: "1.5rem", // font size for large screens
                      xl: "1.5rem", // font size for extra-large screens
                    },
                    whiteSpace: "nowrap", // Prevent text wrapping
                    overflow: "hidden", // Hide overflow if any
                    textOverflow: "ellipsis", // Add ellipsis if text overflows
                  }}
                >
                  {t("See the details of your receivables and payables")}
                </Typography>
              </Box>
            </Grid>

            {/* Evaluate Appointment */}
            {isEnterReceivablePaymentOpen && (
              <EnterReceivablePayment
                onClose={closeModal}
                paymentDetails={paymentDetails}
                onFeedback={handleFeedback}
              />
            )}

            {/* Evaluate Appointment */}
            {isEnterPayablePaymentOpen && (
              <EnterPayablePayment
                onClose={closeModal}
                paymentDetails={paymentDetails}
                onFeedback={handleFeedback}
              />
            )}

            <Box flexGrow={1} />
          </Grid>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            overflowY: "auto",
            pb: "20px",
          }}
        >
          <Grid container spacing={2}>
            {/* Receivables */}
            <Grid
              item
              xs={6}
              alignContent={"center"}
              alignItems={"center"}
              justifyContent={"center"}
              display="flex"
              flex-direction="column"
            >
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  // maxHeight: 480,
                  borderRadius: 5,
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader
                    component="div"
                    id="nested-list-subheader"
                    sx={{
                      fontSize: {
                        xs: "0.8rem",
                        sm: "1rem",
                        md: "1.2rem",
                        lg: "1.5rem",
                      },
                      borderRadius: 5,
                    }}
                  >
                    {t("Receivables")}
                  </ListSubheader>
                }
              >
                {clientsData.length > 0 ? (
                  clientsData.map((client) => (
                    <div key={client.id}>
                      <ListItemButton
                        onClick={() => handleClientClick(client.id)}
                        sx={{
                          borderRadius: 5,
                        }}
                      >
                        <ListItemIcon>
                          <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={client.fullName}
                          secondary={`${t("Total Debt")}: ${
                            client.totalRemaining === "" ? "N/A" : client.total
                          }`}
                        />
                        {openClients[client.id] ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItemButton>
                      <Collapse
                        in={openClients[client.id]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {client.runningBalance.map((entry, index) => (
                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                            <div key={index}>
                              <ListItemButton
                                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                key={index}
                                sx={{ pl: 4, borderRadius: 5 }}
                              >
                                <ListItemIcon>
                                  {Number(entry.total) ===
                                  Number(entry.totalPayments) ? (
                                    <CheckIcon style={{ color: "green" }} />
                                  ) : (
                                    <MonetizationOnIcon
                                      style={{ color: "gold" }}
                                    />
                                  )}
                                </ListItemIcon>
                                <ListItemText
                                  primary={`${
                                    entry.workDetails?.type || t("Unknown")
                                  } - ${t("Total Price")}: ${
                                    entry.total || 0
                                  } / ${t("Total Payments")}: ${
                                    entry.totalPayments || 0
                                  }`}
                                  secondary={
                                    entry.workDetails?.date
                                      ? `${t("Date")}: ${
                                          entry.workDetails?.date
                                            ? entry.workDetails.date
                                            : t("Unknown")
                                        }`
                                      : `${t("Start Date")}: ${
                                          entry.workDetails?.startDate
                                            ? entry.workDetails.startDate
                                            : t("Unknown")
                                        } - ${t("Completion Date")}: ${
                                          entry.workDetails?.completionDate
                                            ? entry.workDetails.completionDate
                                            : t("Not completed yet")
                                        }`
                                  }
                                />
                                <ListItemSecondaryAction
                                  onClick={() =>
                                    handleReceivablePaymentToggle(index)
                                  }
                                >
                                  <Tooltip title={t("Enter Payment")}>
                                    <IconButton
                                      edge="end"
                                      aria-label="enter payment"
                                      sx={{ mr: 1 }}
                                      onClick={() =>
                                        entry.workDetails.type === t("Work")
                                          ? handleEnterReceivablePayment(
                                              client.id,
                                              entry.workDetails.workID,
                                              entry.workDetails.reference,
                                              entry.workDetails.type
                                            )
                                          : handleEnterReceivablePayment(
                                              client.id,
                                              entry.workDetails.saleID,
                                              entry.workDetails.reference,
                                              entry.workDetails.type
                                            )
                                      }
                                    >
                                      <AttachMoneyIcon />
                                    </IconButton>
                                  </Tooltip>

                                  {openReceivablePayments[index] ? (
                                    <ExpandLess />
                                  ) : (
                                    <ExpandMore />
                                  )}
                                </ListItemSecondaryAction>
                              </ListItemButton>

                              {/* Nested List for Payments */}
                              <Collapse
                                in={openReceivablePayments[index]}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding>
                                  {entry.payments?.map((payment, pIndex) => (
                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                    <ListItem key={pIndex} sx={{ pl: 8 }}>
                                      <ListItemIcon>
                                        <AttachMoneyIcon
                                          style={{ color: "green" }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={`${t("Payment Amount")}: ${
                                          payment.paymentAmount || 0
                                        }`}
                                        secondary={`${t("Payment Date")}: ${
                                          payment.datetime
                                            ? format(
                                                payment.datetime.toDate(),
                                                "dd/MM/yyyy"
                                              )
                                            : t("Unknown")
                                        }`}
                                      />
                                    </ListItem>
                                  ))}
                                </List>
                              </Collapse>
                            </div>
                          ))}
                        </List>
                      </Collapse>
                    </div>
                  ))
                ) : (
                  <ListItemText
                    primary={t("No receivables found")}
                    sx={{ ml: 2 }}
                  />
                )}
              </List>
            </Grid>

            {/* Payables */}
            <Grid
              item
              xs={6}
              alignContent={"center"}
              alignItems={"center"}
              justifyContent={"center"}
              display="flex"
              flex-direction="column"
            >
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  maxHeight: 480,
                  borderRadius: 5,
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader
                    component="div"
                    id="nested-list-subheader"
                    sx={{
                      fontSize: {
                        xs: "0.8rem",
                        sm: "1rem",
                        md: "1.2rem",
                        lg: "1.5rem",
                      },
                      borderRadius: 5,
                    }}
                  >
                    {t("Payables")}
                  </ListSubheader>
                }
              >
                {suppliersData.length > 0 ? (
                  suppliersData.map((supplier) => (
                    <div key={supplier.id}>
                      <ListItemButton
                        onClick={() => handleSupplierClick(supplier.id)}
                        sx={{
                          borderRadius: 5,
                        }}
                      >
                        <ListItemIcon>
                          <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={supplier.fullName}
                          secondary={`${t("Total Debt")}: ${
                            supplier.totalRemaining === ""
                              ? "N/A"
                              : supplier.total
                          }`}
                        />
                        {openSuppliers[supplier.id] ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItemButton>
                      <Collapse
                        in={openSuppliers[supplier.id]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {supplier.runningBalance.map((entry, index) => (
                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                            <div key={index}>
                              <ListItemButton
                                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                key={index}
                                sx={{ pl: 4, borderRadius: 5 }}
                              >
                                <ListItemIcon>
                                  {Number(entry.total) ===
                                  Number(entry.totalPayments) ? (
                                    <CheckIcon style={{ color: "green" }} />
                                  ) : (
                                    <MonetizationOnIcon
                                      style={{ color: "gold" }}
                                    />
                                  )}
                                </ListItemIcon>
                                <ListItemText
                                  primary={`${
                                    entry.workDetails?.type || t("Unknown")
                                  } - ${t("Total Price")}: ${
                                    entry.total || 0
                                  } / ${t("Total Payments")}: ${
                                    entry.totalPayments || 0
                                  }`}
                                  secondary={
                                    entry.workDetails?.date
                                      ? `${t("Date")}: ${
                                          entry.workDetails?.date
                                            ? entry.workDetails.date
                                            : t("Unknown")
                                        }`
                                      : `${t("Start Date")}: ${
                                          entry.workDetails?.startDate
                                            ? entry.workDetails.startDate
                                            : t("Unknown")
                                        } - ${t("Completion Date")}: ${
                                          entry.workDetails?.completionDate
                                            ? entry.workDetails.completionDate
                                            : t("Not completed yet")
                                        }`
                                  }
                                />
                                <ListItemSecondaryAction
                                  onClick={() =>
                                    handlePayablePaymentToggle(index)
                                  }
                                >
                                  <Tooltip title={t("Enter Payment")}>
                                    <IconButton
                                      edge="end"
                                      aria-label="enter payment"
                                      sx={{ mr: 1 }}
                                      onClick={() =>
                                        entry.workDetails.type === t("Work")
                                          ? handleEnterPayablePayment(
                                              supplier.id,
                                              entry.workDetails.workID,
                                              entry.workDetails.reference,
                                              entry.workDetails.type
                                            )
                                          : handleEnterPayablePayment(
                                              supplier.id,
                                              entry.workDetails.purchaseID,
                                              entry.workDetails.reference,
                                              entry.workDetails.type
                                            )
                                      }
                                    >
                                      <AttachMoneyIcon />
                                    </IconButton>
                                  </Tooltip>

                                  {openPayablePayments[index] ? (
                                    <ExpandLess />
                                  ) : (
                                    <ExpandMore />
                                  )}
                                </ListItemSecondaryAction>
                              </ListItemButton>

                              {/* Nested List for Payments */}
                              <Collapse
                                in={openPayablePayments[index]}
                                timeout="auto"
                                unmountOnExit
                              >
                                <List component="div" disablePadding>
                                  {entry.payments?.map((payment, pIndex) => (
                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                    <ListItem key={pIndex} sx={{ pl: 8 }}>
                                      <ListItemIcon>
                                        <AttachMoneyIcon
                                          style={{ color: "green" }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={`${t("Payment Amount")}: ${
                                          payment.paymentAmount || 0
                                        }`}
                                        secondary={`${t("Payment Date")}: ${
                                          payment.datetime
                                            ? format(
                                                payment.datetime.toDate(),
                                                "dd/MM/yyyy"
                                              )
                                            : t("Unknown")
                                        }`}
                                      />
                                    </ListItem>
                                  ))}
                                </List>
                              </Collapse>
                            </div>
                          ))}
                        </List>
                      </Collapse>
                    </div>
                  ))
                ) : (
                  <ListItemText
                    primary={t("No payables found")}
                    sx={{ ml: 2 }}
                  />
                )}
              </List>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
  return (
    <Box m="20px" sx={{ height: "100vh" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "95vh",
          width: "100%",
          overflow: "hidden",
          boxSizing: "border-box",
        }}
      >
        {/* HEADER */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Grid container spacing={2}>
            {/* Feedback Mechanism */}
            <CustomMessage
              open={openMessage}
              onClose={handleMessageClose}
              message={message}
            />
            <Grid item xs={2}>
              <Box mb="30px">
                <Typography
                  variant="h2"
                  color={colors.redAccent[500]}
                  fontWeight="bold"
                  sx={{
                    fontSize: {
                      xs: "1.5rem", // font size for extra-small screens
                      sm: "2rem", // font size for small screens
                      md: "3rem", // font size for medium screens
                      lg: "3.5rem", // font size for large screens
                      xl: "3.5rem", // font size for extra-large screens
                    },
                    display: {
                      userSelect: "none",
                    },
                    whiteSpace: "nowrap",
                  }}
                >
                  {t("ACCESS DENIED!")}
                </Typography>
                <Typography
                  variant="r16"
                  color={colors.grey[300]}
                  sx={{
                    fontSize: {
                      xs: "0.9rem", // font size for extra-small screens
                      sm: "1.1rem", // font size for small screens
                      md: "1.2rem", // font size for medium screens
                      lg: "1.5rem", // font size for large screens
                      xl: "1.5rem", // font size for extra-large screens
                    },
                    display: {
                      userSelect: "none",
                    },
                    whiteSpace: "nowrap", // Prevent text wrapping
                    overflow: "hidden", // Hide overflow if any
                    textOverflow: "ellipsis", // Add ellipsis if text overflows
                  }}
                >
                  {t(
                    "Unauthorized login attempt! You may purchase this module."
                  )}
                </Typography>
              </Box>
            </Grid>

            {/* Horizontal Space */}
            <Box flexGrow={1} />

            {/* Buy Button */}
            <Grid item xs={2}>
              <Button
                variant="contained"
                // onClick={handleRedirection("clients")}
                fullWidth
                sx={{
                  mt: {
                    xs: 0,
                    sm: 0,
                    md: 1,
                    lg: 1,
                    xl: 1,
                  },
                  backgroundColor: colors.greenAccent[600],
                  color: colors.grey[100],
                  height: "50px",
                  "&:hover": {
                    backgroundColor: colors.greenAccent[800],
                  },
                }}
              >
                <Typography
                  component="h1"
                  variant="r16"
                  sx={{
                    fontSize: {
                      xs: "0.7rem", // font size for extra-small screens
                      sm: "0.8rem", // font size for small screens
                      md: "0.9rem", // font size for medium screens
                      lg: "1.2rem", // font size for large screens
                      xl: "1.5rem", // font size for extra-large screens
                    },
                  }}
                >
                  {t("Purchase This Module")}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Balance;
