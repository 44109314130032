// src/mobileFirebaseConfig.jsx

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyDjC0gLD34GmRqxIAc2kkERm6dk7CE_Bp0",
//   authDomain: "bnb-chameleon-app.firebaseapp.com",
//   projectId: "bnb-chameleon-app",
//   storageBucket: "bnb-chameleon-app.appspot.com",
//   messagingSenderId: "966637688567",
//   appId: "1:966637688567:web:1c5717f1248fcf937a65f2",
// };

const firebaseConfig = {
  apiKey: "AIzaSyDjmrcxZp553FALECM8qBPctQy3EA79CuM",
  authDomain: "bnb-garage-mobile.firebaseapp.com",
  projectId: "bnb-garage-mobile",
  storageBucket: "bnb-garage-mobile.firebasestorage.app",
  messagingSenderId: "597138334780",
  appId: "1:597138334780:web:c5bbe6c4c144d290fb8f39",
  measurementId: "G-QFGFRSHL38",
};

// Initialize Firebase
const mobileApp = initializeApp(firebaseConfig, "MobileApp");
const mobileFirestore = getFirestore(mobileApp);
const mobileStorage = getStorage(mobileApp);
const mobileAuth = getAuth(mobileApp);

export { mobileFirestore, mobileStorage, mobileAuth };
