// src/components/DataProvider.jsx

import React, { createContext, useContext, useEffect, useState } from "react";
import { firestore } from "../firebaseConfig";
import { mobileFirestore } from "../mobileFirebaseConfig";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  // updateDoc,
} from "firebase/firestore";
import { parse, format } from "date-fns";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [balance, setBalance] = useState([]);
  const [categories, setCategories] = useState([]);
  const [docCategories, setDocCategories] = useState([]);
  const [brandsAndModels, setBrandsAndModels] = useState([]);
  const [partBrands, setPartBrands] = useState([]);
  const [partOrigins, setPartOrigins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [parts, setParts] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [sales, setSales] = useState([]);
  const [works, setWorks] = useState([]);
  const [clients, setClients] = useState([]);
  const [motorcycles, setMotorcycles] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [appointmentRequests, setAppointmentRequests] = useState([]);

  useEffect(() => {
    setLoading(true);
    // Setup Firestore listeners
    const unsubscribeBalance = onSnapshot(
      doc(firestore, "balance", "ca2R5LtmarqOwnunaOu8"),
      (snapshot) => {
        const balanceData = snapshot.data();
        setBalance(balanceData);
      },
      (error) => {
        console.error("Error fetching document categories: ", error);
      }
    );

    const unsubscribeCategories = onSnapshot(
      doc(firestore, "categories", "vsZr8KRfxtxhUJitMQC6"),
      (snapshot) => {
        const categoriesData = snapshot.data();
        const categoriesArray = categoriesData?.category || [];
        setCategories(categoriesArray || []);
      },
      (error) => {
        console.error("Error fetching categories: ", error);
      }
    );

    const unsubscribeDocCategories = onSnapshot(
      doc(firestore, "docCategories", "LfcAlMIcJjA35VYE5U6j"),
      (snapshot) => {
        const docCategoriesData = snapshot.data();
        const docCategoriesArray = docCategoriesData?.category || [];
        setDocCategories(docCategoriesArray || []);
      },
      (error) => {
        console.error("Error fetching document categories: ", error);
      }
    );

    const unsubscribePartBrands = onSnapshot(
      doc(firestore, "partElements", "g99hwS1QRRqZiXYpPwsq"),
      (snapshot) => {
        const brandsData = snapshot.data();
        const brandsArray = brandsData?.brand || [];
        setPartBrands(brandsArray || []);
      },
      (error) => {
        console.error("Error fetching part brands: ", error);
      }
    );

    const unsubscribePartOrigins = onSnapshot(
      doc(firestore, "partElements", "TAGe6Aek0pX4amRYZWuG"),
      (snapshot) => {
        const originsData = snapshot.data();
        const originsArray = originsData?.origin || [];
        setPartOrigins(originsArray || []);
      },
      (error) => {
        console.error("Error fetching part origins: ", error);
      }
    );

    const unsubscribeMotos = onSnapshot(
      doc(firestore, "motoSelect", "JPFgTWQGxhhqRbv526Wy"),
      (snapshot) => {
        const motoData = snapshot.data();
        setBrandsAndModels(motoData || []);
      },
      (error) => {
        console.error("Error fetching motorcycle brands and models: ", error);
      }
    );

    const unsubscribeParts = onSnapshot(
      collection(firestore, "parts"),
      (snapshot) => {
        const partData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setParts(partData || []);
      },
      (error) => {
        console.error("Error fetching parts: ", error);
      }
    );

    const unsubscribePurchases = onSnapshot(
      collection(firestore, "purchases"),
      async (snapshot) => {
        const purchaseData = await Promise.all(
          snapshot.docs.map(async (docSnapshot) => {
            const docData = docSnapshot.data();
            try {
              //   console.log(docData);
              // Supplier Document
              const supplierRef = docData.supplier;
              const supplierDoc = await getDoc(supplierRef);
              const supplierData = supplierDoc.data();

              let partsData = [];

              if (typeof docData.parts === "object" && docData.parts !== null) {
                const partEntries = Object.entries(docData.parts);
                // console.log("partsUsedEntries: ", partsUsedEntries);

                const partPromises = partEntries.map(async ([key, part]) => {
                  try {
                    //   console.log("Fetching part: ", part[0]);
                    // Index 0: Document Reference
                    // Index 1: Quantity
                    // Index 2: Purchase Price
                    // Index 3: Sale Price

                    const partDoc = await getDoc(part[0]);
                    if (partDoc.exists()) {
                      return {
                        part: partDoc.data(),
                        quantity: part[1],
                        purchasePrice: part[2],
                        salePrice: part[3],
                      };
                      // biome-ignore lint/style/noUselessElse: <explanation>
                    } else {
                      console.error(
                        `Part document ${part[0].id} does not exist`
                      );
                      return null;
                    }
                  } catch (error) {
                    console.error(
                      `Error fetching part document ${part[0].id}:`,
                      error
                    );
                    return null;
                  }
                });

                partsData = await Promise.all(partPromises);
                // console.log(partsData);
              } else {
                console.error("Parts is not a valid map");
              }

              const dataToShow = {
                docID: docSnapshot.id,
                id: docData.id,
                date: docData.date,
                partsPurchased: partsData,
                seller: supplierData.corporation,
                total_price: docData.total_price,
                ...docData,
                // dateOfPurchase: format(
                //   docData.date.toDate(),
                //   "dd/MM/yyyy HH:mm:ss"
                // ),
                // name: partData.name,
                // purchase_price: docData.purchase_price,
                // quantity: docData.quantity,
                // sale_price: docData.sale_price,
                // unit: partData.unit,
              };

              return dataToShow;
            } catch (error) {
              console.error("Error fetching purchases data:", error);
              return null;
            } finally {
            }
          })
        );
        setPurchases(purchaseData.filter((item) => item !== null));
      }
    );

    const unsubscribeSales = onSnapshot(
      collection(firestore, "sales"),
      async (snapshot) => {
        const saleData = await Promise.all(
          snapshot.docs.map(async (docSnapshot) => {
            const docData = docSnapshot.data();
            try {
              //   console.log(docData);
              // Client Document
              const clientRef = docData.client;
              const clientDoc = await getDoc(clientRef);
              const clientData = clientDoc.data();

              let partsData = [];

              if (typeof docData.parts === "object" && docData.parts !== null) {
                const partEntries = Object.entries(docData.parts);
                // console.log("partsUsedEntries: ", partsUsedEntries);

                const partPromises = partEntries.map(async ([key, part]) => {
                  try {
                    //   console.log("Fetching part: ", part[0]);
                    // Index 0: Document Reference
                    // Index 1: Quantity
                    // Index 2: Sale Price

                    const partDoc = await getDoc(part[0]);
                    if (partDoc.exists()) {
                      return {
                        part: partDoc.data(),
                        quantity: part[1],
                        salePrice: part[2],
                      };
                      // biome-ignore lint/style/noUselessElse: <explanation>
                    } else {
                      console.error(
                        `Part document ${part[0].id} does not exist`
                      );
                      return null;
                    }
                  } catch (error) {
                    console.error(
                      `Error fetching part document ${part[0].id}:`,
                      error
                    );
                    return null;
                  }
                });

                partsData = await Promise.all(partPromises);
                // console.log(partsData);
              } else {
                console.error("Parts is not a valid map");
              }

              const dataToShow = {
                docID: docSnapshot.id,
                id: docData.id,
                clientName: clientData.full_name,
                date: docData.date,
                partsSold: partsData,
                total_price: docData.total_price,
                ...docData,
                // barcode: partData.barcode,
                // category: partData.category,
                // dateOfSale: format(
                //   docData.date.toDate(),
                //   "dd/MM/yyyy HH:mm:ss"
                // ),
                // name: partData.name,
                // purchase_price: partData.purchase_price,
                // quantity: docData.quantity,
                // sale_price: docData.sale_price,
                // total_price: docData.sale_price * docData.quantity,
                // unit: partData.unit,
              };

              return dataToShow;
            } catch (error) {
              console.error("Error fetching sales data:", error);
              return null;
            } finally {
            }
          })
        );
        setSales(saleData.filter((item) => item !== null));
      }
    );

    const unsubscribeWorks = onSnapshot(
      collection(firestore, "works"),
      async (snapshot) => {
        const workData = await Promise.all(
          snapshot.docs.map(async (docSnapshot) => {
            const docData = docSnapshot.data();
            const clientRef = docData.client;
            const motoRef = docData.motorcycle;

            // console.log("docData", docData);
            // console.log("docData.client:", docData.client);
            // console.log("docData.motorcycle:", docData.motorcycle);

            let clientData = {};
            let motoData = {};
            let partsUsedData = [];

            try {
              // Client Document
              const clientDoc = await getDoc(clientRef);
              if (clientDoc.exists()) {
                // console.log("clientDoc.data():", clientDoc.data());
                clientData = clientDoc.data();
              } else {
                console.error(`Client document ${clientRef.id} does not exist`);
                return null;
              }

              // Motorcycle Document
              const motorcycleDoc = await getDoc(motoRef);
              if (motorcycleDoc.exists()) {
                // console.log("motorcycleDoc.data():", motorcycleDoc.data());
                motoData = motorcycleDoc.data();
              } else {
                console.error(
                  `Motorcycle document ${motoRef.id} does not exist`
                );
                return null;
              }

              // Parts Used Data
              //   console.log("docData.parts_used:", docData.parts_used);

              if (
                typeof docData.parts_used === "object" &&
                docData.parts_used !== null
              ) {
                const partsUsedEntries = Object.entries(docData.parts_used);
                // console.log("partsUsedEntries: ", partsUsedEntries);

                const partsUsedPromises = partsUsedEntries.map(
                  async ([key, part]) => {
                    try {
                      //   console.log("Fetching part: ", part[0]);
                      // Index 0: Document Reference
                      // Index 1: Quantity
                      // Index 2: Sale Price
                      const partDoc = await getDoc(part[0]);
                      if (partDoc.exists()) {
                        return {
                          part: partDoc.data(),
                          quantity: part[1],
                          salePrice: part[2],
                        };
                        // biome-ignore lint/style/noUselessElse: <explanation>
                      } else {
                        console.error(
                          `Part document ${part[0].id} does not exist`
                        );
                        return null;
                      }
                    } catch (error) {
                      console.error(
                        `Error fetching part document ${part[0].id}:`,
                        error
                      );
                      return null;
                    }
                  }
                );

                partsUsedData = await Promise.all(partsUsedPromises);
              } else {
                console.error("parts_used is not a valid map");
              }
            } catch (error) {
              console.error(
                "Error fetching client, motorcycle, or parts data:",
                error
              );
              return null;
            } finally {
            }

            // console.log(docSnapshot.id);
            // console.log(partsUsedData);

            return {
              docID: docSnapshot.id,
              clientInfo: clientData,
              motoInfo: motoData,
              partsUsed: partsUsedData,
              // amount: Number(docData.amount),
              // dateOfWork: format(docData.date.toDate(), "dd/MM/yyyy HH:mm:ss"),
              ...docData,
            };
          })
        );

        // console.log(updatedData);

        setWorks(workData.filter((item) => item !== null));
      }
    );

    const unsubscribeClients = onSnapshot(
      collection(firestore, "clients"),
      async (snapshot) => {
        const clientData = await Promise.all(
          snapshot.docs.map(async (docSnapshot) => {
            const docData = docSnapshot.data();
            const moto = docData.motorcycles;

            // Ensure moto is populated before checking motorcycles
            if (moto && moto.length > 0) {
              const motorcycleData = await Promise.all(
                moto.map(async (motorcyclesRef) => {
                  try {
                    const motorcycleDoc = await getDoc(motorcyclesRef);
                    if (motorcycleDoc.exists()) {
                      const data = motorcycleDoc.data();
                      return data;
                      // biome-ignore lint/style/noUselessElse: <explanation>
                    } else {
                      return null;
                    }
                  } catch (error) {
                    return null;
                  }
                })
              );
              docData.motorcycles_all = motorcycleData.filter(
                (data) => data !== null
              );
            }

            return {
              docID: docSnapshot.id,
              // Convert Firestore timestamp to a readable date format
              birthday: format(
                docSnapshot.data().birthdate.toDate(),
                "dd/MM/yyyy"
              ),
              ...docData,
            };
          })
        );

        setClients(clientData);
      }
    );

    const unsubscribeMotorcycles = onSnapshot(
      collection(firestore, "motorcycles"),
      (snapshot) => {
        const motorcycleData = snapshot.docs.map((doc) => ({
          docID: doc.id,
          ...doc.data(),
        }));
        setMotorcycles(motorcycleData || []);
      },
      (error) => {
        console.error("Error fetching motorcycles: ", error);
      }
    );

    const unsubscribeSuppliers = onSnapshot(
      collection(firestore, "suppliers"),
      (snapshot) => {
        const supplierData = snapshot.docs.map((doc) => ({
          docID: doc.id,
          businessStartDate: format(
            doc.data().business_commencement.toDate(),
            "dd/MM/yyyy HH:mm:ss"
          ),
          ...doc.data(),
        }));
        setSuppliers(supplierData || []);
      },
      (error) => {
        console.error("Error fetching suppliers: ", error);
      }
    );

    const unsubscribeAppointments = onSnapshot(
      collection(firestore, "appointments"),
      async (snapshot) => {
        const appointmentData = await Promise.all(
          snapshot.docs.map(async (docSnapshot) => {
            const docData = docSnapshot.data();
            try {
              //   console.log(docData);
              // Client Document
              const clientRef = docData.client;
              const clientDoc = await getDoc(clientRef);
              const clientData = clientDoc.data();
              //   console.log(partData);

              const motorcycleRef = docData.motorcycle;
              const motorcycleDoc = await getDoc(motorcycleRef);
              const motorcycleData = motorcycleDoc.data();

              // const appointmentDoc = docSnapshot.ref;
              // Create reference to this document
              // const docRef = doc(firestore, "appointments", appointmentDoc.id);

              // const dateNow = new Date();
              // 0: Waiting
              // 1: Expired
              // 2: Completed
              // 3: Cancelled
              // if (
              //   docData.datetime.toDate() < dateNow &&
              //   (docData.status !== "2" || docData.status !== "3")
              // ) {
              //   console.log(
              //     "Entered There! Client: ",
              //     docData.client,
              //     ", Status: ",
              //     docData.status
              //   );
              //   // Update the document
              //   await updateDoc(docRef, { status: "1" });
              // } else if (docData.status !== "3") {
              //   // Update the document
              //   await updateDoc(docRef, { status: "0" });
              // }

              const dataToShow = {
                docID: docSnapshot.id,
                id: docData.id,
                photo: clientData.photo,
                full_name: clientData.full_name,
                motorcycle: motorcycleData,
                phone: clientData.phone,
                datetime: docData.datetime,
                // dateOfAppointment: format(
                //   docData.datetime.toDate(),
                //   "dd/MM/yyyy HH:mm:ss"
                // ),
                status: docData.status,
              };

              return dataToShow;
            } catch (error) {
              console.error("Error fetching appointments data:", error);
              return null;
            } finally {
            }
          })
        );
        setAppointments(appointmentData.filter((item) => item !== null));
      }
    );

    const unsubscribeMobileAppointments = onSnapshot(
      collection(mobileFirestore, "appointments"),
      async (snapshot) => {
        try {
          const appointmentRequestsData = await Promise.all(
            snapshot.docs.map(async (docSnapshot) => {
              const docId = docSnapshot.id;
              const docData = docSnapshot.data();
              try {
                //   console.log(docData);
                // Client Document
                const userRef = doc(mobileFirestore, "clients", docData.userId);
                const userDoc = await getDoc(userRef);
                const userData = userDoc.data();

                const mechanicRef = doc(
                  mobileFirestore,
                  "mechanics",
                  docData.mechanicId
                );
                const mechanicDoc = await getDoc(mechanicRef);
                const mechanicData = mechanicDoc.data();

                const startTimestamp = parse(
                  `${docData.startDate} ${docData.startTime}`,
                  "yyyy-MM-dd HH:mm",
                  new Date()
                );

                const endTimestamp = parse(
                  `${docData.endDate} ${docData.endTime}`,
                  "yyyy-MM-dd HH:mm",
                  new Date()
                );

                const dataToShow = {
                  id: docId,
                  brand: docData.brand,
                  model: docData.model,
                  year: docData.year,
                  plate: docData.plate,
                  client: userData.full_name,
                  clientPhone: userData.phone,
                  clientPhoto: userData.photo,
                  startDatetime: startTimestamp,
                  endDatetime: endTimestamp,
                  description: docData.description,
                  mechanic: mechanicData.name,
                  status: docData.status,
                  ...docData,
                };

                console.log(dataToShow);
                return dataToShow;
              } catch (error) {
                console.error("Error fetching appointments data:", error);
                return null;
              } finally {
              }
            })
          );

          setAppointmentRequests(appointmentRequestsData || []);
        } catch (error) {
          console.error("Error processing snapshot:", error);
        }
      },
      (error) => {
        console.error("Error fetching suppliers: ", error);
      }
    );

    setLoading(false);

    // Cleanup on unmount
    return () => {
      unsubscribeBalance();
      unsubscribeCategories();
      unsubscribeDocCategories();
      unsubscribePartBrands();
      unsubscribePartOrigins();
      unsubscribeMotos();
      unsubscribeParts();
      unsubscribePurchases();
      unsubscribeSales();
      unsubscribeWorks();
      unsubscribeClients();
      unsubscribeMotorcycles();
      unsubscribeSuppliers();
      unsubscribeAppointments();
      unsubscribeMobileAppointments();
    };
  }, []);

  return (
    <DataContext.Provider
      value={{
        balance,
        categories,
        docCategories,
        brandsAndModels,
        partBrands,
        partOrigins,
        parts,
        purchases,
        sales,
        works,
        clients,
        motorcycles,
        suppliers,
        appointments,
        appointmentRequests,
        loading,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
