// src/components/Board.jsx
import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Column from "./Column";
import { firestore } from "../firebaseConfig";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  query,
  where,
  deleteDoc,
  onSnapshot,
  getDoc,
} from "firebase/firestore";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  useTheme,
  ClickAwayListener,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../theme";
import AddIcon from "@mui/icons-material/Add";

const Board = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [columns, setColumns] = useState([]);
  const [newColumnTitle, setNewColumnTitle] = useState("");
  const [hideAdd, setHideAdd] = useState(true);

  useEffect(() => {
    const fetchColumns = () => {
      const columnsCollection = collection(firestore, "columns");

      const unsubscribe = onSnapshot(
        columnsCollection,
        (snapshot) => {
          const columnsData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setColumns(columnsData);
        },
        (error) => {
          console.error("Error fetching columns:", error);
        }
      );

      // Clean up the listener on unmount
      return () => unsubscribe();
    };

    fetchColumns();
  }, []);

  const handleAddClicked = () => {
    setHideAdd(false);
  };

  const handleCancelClicked = () => {
    setHideAdd(true);
  };

  // Convert array of cards to map
  const arrayToMap = (array) => {
    return array.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
  };

  const mapToArray = (map) => {
    return Object.values(map);
  };

  const onDragEnd = async (result) => {
    const { destination, source, type } = result; // draggableId

    if (!destination) {
      return;
    }

    // Handling column dragging
    if (type === "COLUMN") {
      const reorderedColumns = Array.from(columns);
      // console.log(reorderedColumns);
      const [movedColumn] = reorderedColumns.splice(source.index, 1);
      // console.log(movedColumn);
      reorderedColumns.splice(destination.index, 0, movedColumn);
      // console.log(reorderedColumns);

      // Update Firestore with new column order
      await Promise.all(
        reorderedColumns.map((column, index) =>
          updateDoc(doc(firestore, "columns", column.id), {
            order: index,
          })
        )
      );

      setColumns(reorderedColumns);
      return;
    }

    // Handling card dragging
    const startColumn = columns.find(
      (column) => column.id === source.droppableId
    );
    const finishColumn = columns.find(
      (column) => column.id === destination.droppableId
    );

    if (startColumn === finishColumn) {
      const newCardIds = Array.from(mapToArray(startColumn.cards));
      const [movedCard] = newCardIds.splice(source.index, 1);
      newCardIds.splice(destination.index, 0, movedCard);
      // console.log(newCardIds);

      const newColumn = {
        ...startColumn,
        cards: arrayToMap(newCardIds),
      };

      await updateDoc(doc(firestore, "columns", newColumn.id), newColumn);
      setColumns(
        columns.map((column) =>
          column.id === newColumn.id ? newColumn : column
        )
      );
      return;
    }

    const startCardIds = Array.from(mapToArray(startColumn.cards));
    const [movedCard] = startCardIds.splice(source.index, 1);
    const newStartColumn = {
      ...startColumn,
      cards: arrayToMap(startCardIds),
    };

    const finishCardIds = Array.from(mapToArray(finishColumn.cards));
    finishCardIds.splice(destination.index, 0, movedCard);
    const newFinishColumn = {
      ...finishColumn,
      cards: arrayToMap(finishCardIds),
    };

    await updateDoc(
      doc(firestore, "columns", newStartColumn.id),
      newStartColumn
    );
    await updateDoc(
      doc(firestore, "columns", newFinishColumn.id),
      newFinishColumn
    );

    setColumns(
      columns.map((column) =>
        column.id === newStartColumn.id
          ? newStartColumn
          : column.id === newFinishColumn.id
          ? newFinishColumn
          : column
      )
    );
  };

  const addColumn = async () => {
    if (newColumnTitle.trim() === "") return;

    const columnRef = await addDoc(collection(firestore, "columns"), {
      title: newColumnTitle,
      cards: [],
      order: columns.length, // Set initial order
    });

    setColumns([
      ...columns,
      {
        id: columnRef.id,
        title: newColumnTitle,
        cards: [],
        order: columns.length,
      },
    ]);

    await updateDoc(doc(firestore, "columns", columnRef.id), {
      id: columnRef.id,
    });
    setNewColumnTitle("");
  };

  const updateColumnTitle = async (columnId, newTitle) => {
    const columnRef = doc(firestore, "columns", columnId);
    await updateDoc(columnRef, { title: newTitle });

    setColumns(
      columns.map((col) =>
        col.id === columnId ? { ...col, title: newTitle } : col
      )
    );
  };

  const deleteColumn = async (columnId) => {
    const columnRef = collection(firestore, "columns");
    const q = query(columnRef, where("id", "==", columnId));
    const querySnapshot = await getDocs(q);
    const columnDoc = querySnapshot.docs[0];
    const docRef = doc(firestore, "columns", columnDoc.id);
    await deleteDoc(docRef);

    setColumns(columns.filter((column) => column.id !== columnId));
  };

  const addCard = async (columnId, newCard) => {
    const columnDocRef = doc(firestore, "columns", columnId);
    const columnDoc = await getDoc(columnDocRef);
    const columnData = columnDoc.data();

    // console.log("Column Data: ", columnData);

    if (columnData) {
      // Convert the existing cards map to an array, add the new card, then convert back to a map
      const existingCardsArray = Object.values(columnData.cards || {});
      const updatedCardsArray = [...existingCardsArray, newCard];
      const updatedCardsMap = arrayToMap(updatedCardsArray);

      // console.log("existingCardsArray: ", existingCardsArray);
      // console.log("updatedCardsArray: ", updatedCardsArray);
      // console.log("updatedCardsMap: ", updatedCardsMap);

      // Update Firestore with the new cards map
      await updateDoc(columnDocRef, {
        cards: updatedCardsMap,
      });
    } else {
      console.error("Column not found");
    }
  };

  const deleteCard = async (cardId) => {
    const updatedColumns = columns.map((column) => ({
      ...column,
      cards: column.cards.filter((card) => card.id !== cardId),
    }));

    setColumns(updatedColumns);

    await Promise.all(
      updatedColumns.map((column) =>
        updateDoc(doc(firestore, "columns", column.id), { cards: column.cards })
      )
    );
  };

  return (
    <Box m="20px" sx={{ height: "100vh" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Box mb="30px">
              <Typography
                variant="h2"
                color={colors.grey[200]}
                fontWeight="bold"
                sx={{
                  fontSize: {
                    xs: "1.5rem",
                    sm: "2rem",
                    md: "3rem",
                    lg: "3.5rem",
                    xl: "3.5rem",
                  },
                  display: {
                    userSelect: "none",
                  },
                }}
              >
                {t("Projects")}
              </Typography>
              <Typography
                variant="r16"
                color={colors.greenAccent[400]}
                sx={{
                  fontSize: {
                    xs: "1rem",
                    sm: "1.2rem",
                    md: "1.2rem",
                    lg: "1.5rem",
                    xl: "1.5rem",
                  },
                  display: {
                    userSelect: "none",
                  },
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {t("Select a project to see it's board")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Check ID Later */}
      <Grid container>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="4" type="COLUMN" direction="horizontal">
            {(provided) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{ display: "flex", flexDirection: "row", padding: "10px" }}
              >
                {columns.map((column, index) => (
                  <Column
                    key={column.id}
                    column={column}
                    index={index}
                    addCard={addCard}
                    deleteCard={deleteCard}
                    deleteColumn={deleteColumn}
                    updateColumnTitle={updateColumnTitle}
                  />
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>

        {hideAdd && (
          <Button
            variant="outlined"
            color="info"
            onClick={handleAddClicked}
            sx={{
              mt: 1,
              height: 32,
            }}
          >
            <AddIcon />
          </Button>
        )}

        {!hideAdd && (
          <Box sx={{ marginTop: "10px", display: hideAdd ? "none" : "block" }}>
            <ClickAwayListener onClickAway={handleCancelClicked}>
              <TextField
                value={newColumnTitle}
                onChange={(e) => setNewColumnTitle(e.target.value)}
                color="info"
                label={t("Add New List")}
                variant="outlined"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    addColumn();
                  }
                }}
              />
            </ClickAwayListener>
            <Button
              variant="outlined"
              color="info"
              onClick={addColumn}
              sx={{
                marginLeft: 2,
                height: 50,
              }}
            >
              <Typography variant="r16">{t("Add")}</Typography>
            </Button>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default Board;
