/* eslint-disable react-hooks/exhaustive-deps */
// src/screens/AddPurchase.jsx

import React, { useEffect, useState, useMemo } from "react";
// import { useAuth } from "../components/AuthContext";
import { firestore, storage } from "../firebaseConfig";
import {
  collection,
  addDoc,
  doc,
  getDocs,
  query,
  where,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  Autocomplete,
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Grid,
  Paper,
  Typography,
  useTheme,
  Backdrop,
  Modal,
  Fade,
  IconButton,
  ListItemIcon,
  ListItemText,
  Avatar,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CustomLinearProgress, { CustomToolbar } from "../components/Common";
import HoverImage from "../components/HoverImage";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import { useData } from "../components/DataProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { setHours, setMinutes, setSeconds } from "date-fns";
import { enGB } from "date-fns/locale";
import { styled } from "@mui/material/styles";
// Icons
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import CloseIcon from "@mui/icons-material/Close";
import LoadingWindow from "../components/LoadingWindow";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileIcon from "@mui/icons-material/InsertDriveFile";
import { addRunningBalanceToSupplier } from "../components/DatabaseOps";

const StyledButton = styled(IconButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
}));

const StyledDate = styled(DesktopDatePicker)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  color:
    theme.palette.mode === "light"
      ? theme.palette.secondary.dark
      : theme.palette.secondary.light,
}));

const AddPurchase = ({ onClose, onFeedback }) => {
  // const { currentUser } = useAuth();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [selectedPart, setSelectedPart] = useState(null);
  const [partsData, setPartsData] = useState([]);
  const [dataCommit, setDataCommit] = useState([]);
  const [partToRemove, setPartToRemove] = useState("");
  const [quantity, setQuantity] = useState("");
  const [recommendedPrice, setRecommendedPrice] = useState("");
  const [recommendedPriceText, setRecommendedPriceText] = useState(
    t("Purchase Price")
  );
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [supplierRef, setSupplierRef] = useState(null);
  const { parts, suppliers } = useData();
  let [purchaseDate, setPurchaseDate] = useState(null);
  const [errors, setErrors] = useState({});
  const [documents, setDocuments] = useState([]);
  const [openDocumentsDialog, setOpenDocumentsDialog] = useState(false);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 });
  const [purchaseData, setPurchaseData] = useState({
    date: null,
    documents: [],
    id: "",
    parts: [],
    sale_price: "",
    total_price: "",
    supplier: null,
  });
  let totalPrice = 0;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    // Calculate the total amount
    const total = partsData.reduce((acc, item) => acc + item.totalPrice, 0);
    // console.log(total.toFixed(2));
    setPurchaseData({ ...purchaseData, total_price: total.toFixed(2) });
  }, [partsData]);

  const partNames = useMemo(
    () =>
      parts.map((part) => {
        // Check if both brand and origin exist
        return `${part.brand} (${part.origin}) ${part.name} - ${part.barcode}`;
      }),
    [parts]
  );

  // Memoize the supplier names array
  const supplierNames = useMemo(
    () => suppliers.map((supplier) => supplier.corporation),
    [suppliers]
  );

  const handleMouseEnter = (e, src) => {
    const rect = e.target.getBoundingClientRect();
    setHoverPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setHoveredImage(src);
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
  };

  const handlePartComboboxChange = (event, value) => {
    if (value) {
      const selectedPartData = parts.find(
        (part) =>
          `${part.brand} (${part.origin}) ${part.name} - ${part.barcode}` ===
          value
      );
      if (selectedPartData) {
        setErrors({ ...errors, selectedPart: "" });
        setSelectedPart(value);
        // console.log("Selected Part: ", selectedPartData);
        // console.log("Selected Part's Price: ", selectedPartData.sale_price);
        setRecommendedPriceText(t("Last Purchase Price"));
        setRecommendedPrice(selectedPartData.purchase_price || "");
        setPurchaseData({
          ...purchaseData,
          sale_price: selectedPartData.sale_price,
        });
      } else {
        setRecommendedPriceText(t("Purchase Price"));
        setRecommendedPrice("");
      }
    } else {
      setRecommendedPriceText(t("Purchase Price"));
      setRecommendedPrice("");
    }
  };

  const handleQuantityChange = (event) => {
    // Allow only numbers and dots
    if (/^\d*\.?\d*$/.test(event.target.value)) {
      setErrors({ ...errors, quantity: "" });
      setQuantity(event.target.value);
    }
  };

  const handlePriceChange = (event) => {
    if (/^\d*\.?\d*$/.test(event.target.value)) {
      setRecommendedPrice(event.target.value);
      setRecommendedPriceText(t("Unit Price"));
      setErrors({ ...errors, recommendedPrice: "" });
    }
  };

  const preValidateFields = () => {
    const newErrors = {};
    if (!quantity) newErrors.quantity = t("Quantity is required");
    if (!recommendedPrice)
      newErrors.recommendedPrice = t("Unit Price is required");
    if (!selectedPart) newErrors.selectedPart = t("Part is required");
    if (!purchaseData.sale_price)
      newErrors.sale_price = t("Sale Price is required");
    return newErrors;
  };

  const handleSavePart = (event) => {
    setErrors({ ...errors, parts: "" });
    const err = preValidateFields();

    if (Object.keys(err).length === 0) {
      const id = uuidv4();

      if (selectedPart) {
        const selectedPartData = parts.find(
          (part) =>
            `${part.brand} (${part.origin}) ${part.name} - ${part.barcode}` ===
            selectedPart
        );
        if (selectedPartData) {
          // console.log("Selected Part: ", selectedPartData);
          // console.log("Selected Part's Price: ", selectedPartData.sale_price);

          const usedPartData = {
            id: id,
            photo: selectedPartData.photo,
            barcode: selectedPartData.barcode,
            name: selectedPartData.name,
            category: selectedPartData.category,
            quantity: quantity,
            purchasePrice: recommendedPrice,
            totalPrice: quantity * recommendedPrice,
          };

          const dataStore = {
            id: id,
            part: selectedPartData,
            quantity: quantity,
            purchasePrice: recommendedPrice,
            salePrice: purchaseData.sale_price,
          };

          setDataCommit((prevDataCommit) => [...prevDataCommit, dataStore]);

          // console.log(dataCommit);

          setPartsData((prevPartsData) => [...prevPartsData, usedPartData]);
        } else {
          console.error("Part not found among parts!");
        }
      } else {
        console.error("Selected Part is not valid!");
      }
    } else {
      // console.log(err);
      setErrors(err);
    }
  };

  const handlePartSelectionChange = async (newSelection) => {
    setPartToRemove(newSelection);
  };

  const handleRemovePart = (event) => {
    setPartsData(partsData.filter((part) => part.id !== partToRemove[0]));
    setDataCommit(dataCommit.filter((data) => data.id !== partToRemove[0]));
  };

  const handleSupplierChange = (event, value) => {
    let selectedPartData;

    if (value) {
      selectedPartData = suppliers.find(
        (supplier) => supplier.corporation === value
      );

      if (selectedPartData) {
        setErrors({ ...errors, supplier: "" });
        setSupplierRef(selectedPartData);
        setSelectedSupplier(value);
      } else {
        setErrors({
          ...errors,
          supplier: t("Supplier is not valid!"),
        });
      }
    } else {
      //   setErrors({ ...errors, selectedPart: t("Search result is not valid!") });
    }
  };

  const handlePurchaseDayChange = (dateSent) => {
    if (dateSent === null || Number.isNaN(dateSent.getTime())) {
      console.error("Not a valid date!");
    } else {
      // Get the current time
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      const currentSecond = now.getSeconds();

      // Combine the selected date with the current time
      let combinedDate = setHours(dateSent, currentHour);
      combinedDate = setMinutes(combinedDate, currentMinute);
      combinedDate = setSeconds(combinedDate, currentSecond);

      // Convert the combined date-time to a Firestore timestamp
      const timestamp = new Date(combinedDate);

      setPurchaseDate(timestamp);
    }
  };

  // Toggle modal open/close
  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  const closeModal = () => {
    setLoading(false);
  };

  const handleNumericChange = (event) => {
    const { name, value } = event.target;
    // Allow only numbers and dots
    if (/^\d*\.?\d*$/.test(value)) {
      const number = Number.parseFloat(value);
      if (Number.isNaN(number)) {
        console.error("Invalid number");
      } else {
        setPurchaseData({ ...purchaseData, [name]: value });
        setErrors({ ...errors, [name]: "" });
      }
    }
  };

  const handleDocumentsSelection = (event) => {
    const files = Array.from(event.target.files);
    setDocuments(files);
  };

  const validateFields = () => {
    const newErrors = {};
    if (!partsData.length)
      newErrors.parts = t("You must indicate the parts you used");
    if (!supplierRef) newErrors.supplier = t("Supplier is required");
    if (!purchaseDate) newErrors.date = t("Purchase Date is required");
    return newErrors;
  };

  const handleClearData = () => {
    setPurchaseData({
      date: null,
      documents: [],
      id: "",
      parts: null,
      sale_price: "",
      supplier: null,
    });
    setPartsData([]);
    setDocuments([]);
    setSelectedSupplier(null);
    setSupplierRef(null);
    setErrors({});
    setPurchaseDate(null);
    setRecommendedPrice("");
    setRecommendedPriceText(t("Unit Price"));
    setQuantity("");
    setSelectedPart(null);
    setPartToRemove("");
    setDataCommit([]);
  };

  // Mark files for delete operation
  const handleDeleteFile = (filePath) => {
    setDocuments(documents.filter((file) => file !== filePath));
  };

  function arrayToObject(arr) {
    const obj = {};
    for (let i = 0; i < arr.length; ++i) {
      obj[i] = arr[i];
    }
    return obj;
  }

  // Set selected IDs
  const getUsedParts = async () => {
    const itemRefs = await Promise.all(
      dataCommit.map(async (itemId) => {
        try {
          const partRef = collection(firestore, "parts");
          const q = query(partRef, where("id", "==", itemId.part.id));
          const querySnapshot = await getDocs(q);
          const partDoc = querySnapshot.docs[0];
          // console.log(partDoc);

          const partsUsedEntry = [
            doc(firestore, partDoc.ref.path),
            itemId.quantity, // Ensure you pass the correct quantity
            itemId.purchasePrice, // Ensure you pass the correct price
            itemId.salePrice,
          ];

          totalPrice += Number(itemId.purchasePrice) * Number(itemId.quantity);

          // console.log(partDoc.ref.path); // Reference
          // console.log(partDoc.data()); // Data itself
          // console.log(partsUsedEntry);

          return partsUsedEntry;
        } catch (error) {
          console.error("Error fetching document:", error);
          return null;
        }
      })
    );

    return itemRefs;
  };

  const handleSubmit = async () => {
    const fieldErrors = validateFields();
    const id = uuidv4();

    if (Object.keys(fieldErrors).length === 0) {
      setLoading(true);
      try {
        // Upload documents
        const documentUrls = [];
        for (const file of documents) {
          const documentRef = ref(storage, `purchases/${id}/doc/${file.name}`);
          await uploadBytes(documentRef, file);
          const url = await getDownloadURL(documentRef);
          documentUrls.push(url);
        }

        const supplierReference = collection(firestore, "suppliers");
        // console.log(partRef);
        const q = query(supplierReference, where("id", "==", supplierRef.id));
        const querySnapshot = await getDocs(q);
        // console.log(querySnapshot);
        const supplierDoc = querySnapshot.docs[0];
        // console.log(partDoc);
        const refToSave = doc(firestore, supplierDoc.ref.path);
        // console.log(refToSave);

        if (!purchaseDate) purchaseDate = new Date();

        // Set photo and documents URL in purchaseData
        const updatedPurchaseData = {
          createdAt: new Date(),
          date: purchaseDate,
          documents: documentUrls,
          id: id,
          parts: arrayToObject(await getUsedParts()),
          supplier: refToSave,
          total_price: purchaseData.total_price,
        };

        // console.log(updatedPartData);
        // Create new document
        const purchaseID = await addDoc(
          collection(firestore, "purchases"),
          updatedPurchaseData
        );
        // console.log("Purchase added! Stocks are updating now...");

        const partsUsedEntries = Object.entries(updatedPurchaseData.parts);

        const partsUsedPromises = partsUsedEntries.map(async ([key, part]) => {
          try {
            // console.log("Fetching part: ", part[0]);
            // Index 0: Document Reference
            // Index 1: Quantity
            // Index 2: Purchase Price
            // Index 3: Sale Price

            const partDoc = await getDoc(part[0]);
            // console.log("Part Data: ", partDoc.data(), key);
            if (partDoc.exists()) {
              const currentStock = Number(partDoc.data().current_stock);
              let updatedStock;
              if (currentStock && currentStock > 0)
                updatedStock = currentStock + Number(part[1]);
              else updatedStock = Number(part[1]);
              // console.log(
              //   "Current Stock: ",
              //   currentStock,
              //   "Updated Stock: ",
              //   updatedStock
              // );
              // Create reference to this document
              const docRef = doc(firestore, "parts", partDoc.id);
              // Update the parts document
              await updateDoc(docRef, {
                current_stock: updatedStock,
                purchase_price: Number(part[2]),
                sale_price: Number(part[3]),
              });
            } else {
              // console.error(`Part document ${part[0].id} does not exist`);
              onFeedback(t("Failed to Update Part"));
              return null;
            }
          } catch (error) {
            // console.error(`Error fetching part document ${part[0].id}:`, error);
            onFeedback(t("Document fetching error"));
            return null;
          }
        });
        await Promise.all(partsUsedPromises);

        // Update client balance
        // Convert Firestore DocumentReference to string and extract ID
        const supplierReferenceString =
          typeof refToSave === "string" ? refToSave : refToSave?.path;
        const supplierId = supplierReferenceString?.split("/")[1];

        await addRunningBalanceToSupplier(
          supplierId,
          purchaseID,
          Number(totalPrice)
        );

        handleClearData(); // Clear form fields after submission
        onFeedback(t("Operation Successfull!"));
        handleClose();
      } catch (error) {
        console.error("Error adding document: ", error);
        onFeedback(t("Failed to Create New Purchase"));
      } finally {
        setLoading(false);
      }
    } else {
      setErrors(fieldErrors);
    }
  };

  const handleCancel = async () => {
    handleClearData();
    handleClose();
  };

  const partsColumns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "photo",
      headerName: t("Photo"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          position="relative"
          onMouseEnter={(e) => handleMouseEnter(e, params.value)}
          onMouseLeave={handleMouseLeave}
        >
          <Avatar
            src={params.value}
            // variant="square"
            style={{ width: 45, height: 45 }}
          />
        </Box>
      ),
    },
    {
      field: "barcode",
      headerName: t("Barcode"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      //   renderCell: (params) => {
      //     const part = params.row.partsUsed.part;
      //     return part ? part.barcode : "N/A";
      //   },
    },
    {
      field: "name",
      headerName: t("Name"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      //   renderCell: (params) => {
      //     const part = params.row.partsUsed.part;
      //     return part ? part.name : "N/A";
      //   },
    },
    {
      field: "category",
      headerName: t("Category"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
      //   renderCell: (params) => {
      //     const part = params.row.partsUsed.part;
      //     return part ? part.category : "N/A";
      //   },
    },
    // {
    //   field: "purchase_price",
    //   headerName: t("Purchase Price"),
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   width: 100,
    // },
    // {
    //   field: "sale_price",
    //   headerName: t("Sale Price"),
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   width: 100,
    // },
    {
      field: "quantity",
      headerName: t("Quantity"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      type: "number",
    },
    {
      field: "purchasePrice",
      headerName: t("Unit Price"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      type: "number",
    },
    {
      field: "totalPrice",
      headerName: t("Total Price"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      type: "number",
    },
  ];

  const datagridMaxHeight = 247;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open} timeout={250}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "95vh",
                maxWidth: 900,
                width: "100%",
                margin: "0 auto",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                  maxHeight: "95vh",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                {/* Modal */}
                {loading && <LoadingWindow onClose={closeModal} />}

                <IconButton
                  aria-label="delete"
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Create New Purchase")}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Parts Field */}
                  <Grid item xs={12}>
                    <Autocomplete
                      options={partNames}
                      fullWidth
                      disablePortal
                      //   multiple
                      id="combobox-part"
                      name="part"
                      color="secondary"
                      value={selectedPart}
                      filterSelectedOptions
                      // isOptionEqualToValue={(option, value) =>
                      //   option.label === value.value
                      // }
                      onChange={handlePartComboboxChange}
                      defaultValue={[]}
                      // filterSelectedOptions
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Part")}
                          error={!!errors.selectedPart}
                          helperText={errors.selectedPart}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Quantity Field */}
                  <Grid item xs={4}>
                    <TextField
                      label={t("Quantity")}
                      name="quantity"
                      id="textfield-quantity"
                      color="secondary"
                      required
                      value={quantity}
                      onChange={handleQuantityChange}
                      fullWidth
                      error={!!errors.quantity}
                      helperText={errors.quantity}
                      inputProps={{
                        inputMode: "decimal", // mobile keyboard with numbers and dot
                      }}
                    />
                  </Grid>

                  {/* Unit Price Field */}
                  <Grid item xs={4}>
                    <TextField
                      label={recommendedPriceText}
                      name="lastPrice"
                      id="textfield-lastPrice"
                      color="secondary"
                      required
                      value={recommendedPrice}
                      //   defaultValue={recommendedPrice}
                      onChange={handlePriceChange}
                      fullWidth
                      error={!!errors.recommendedPrice}
                      helperText={errors.recommendedPrice}
                      inputProps={{
                        inputMode: "decimal", // mobile keyboard with numbers and dot
                      }}
                      InputLabelProps={
                        recommendedPrice ? { shrink: true } : { shrink: false }
                      }
                    />
                  </Grid>

                  {/* Sale Price Field */}
                  <Grid item xs={4}>
                    <TextField
                      label={t("Sale Price")}
                      name="sale_price"
                      color="secondary"
                      required
                      value={purchaseData.sale_price}
                      onChange={handleNumericChange}
                      fullWidth
                      error={!!errors.sale_price}
                      helperText={errors.sale_price}
                      inputProps={{
                        inputMode: "decimal", // mobile keyboard with numbers and dot
                      }}
                    />
                  </Grid>

                  {/* Data Grid for Parts Used Details */}
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        // component="h1"
                        variant="h6"
                        paddingLeft="10px"
                        color="red"
                      >
                        {errors.parts ? errors.parts : ""}
                      </Typography>
                    </Box>
                    <Box
                      m="0px 0px 0px 0px"
                      display="flex"
                      flex-direction="column"
                      //height="100vh" /* Full height of the viewport */
                      min-height="0" /* Ensure it can shrink */
                      width="100%"
                      sx={{
                        height: `${datagridMaxHeight}px`,
                        width: "100%",
                        "& .MuiDataGrid-root": {
                          fontSize: "16px",
                          ...(theme.palette.mode === "dark"
                            ? {
                                border: "1px solid rgba(62, 67, 150, 1)",
                              }
                            : { border: "1px solid rgba(175, 63, 59, 1)" }),
                        },
                        "& .MuiDataGrid-cell": {
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "12px",
                        },
                        "& .name-column--cell": {
                          color: colors.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          backgroundColor: colors.blueAccent[700],
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "0.9rem",
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.greenAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                        "& .MuiDataGrid-virtualScroller": {
                          backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                          borderTop: "none",
                          ...(theme.palette.mode === "dark"
                            ? {
                                backgroundColor: colors.blueAccent[700],
                              }
                            : { backgroundColor: colors.redAccent[800] }),
                        },
                        "& .MuiCheckbox-root": {
                          color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-cell:hover": {
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.blueAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                      }}
                    >
                      <DataGrid
                        columnVisibilityModel={{
                          id: false,
                        }}
                        disableMultipleRowSelection
                        checkboxSelection={true}
                        rows={partsData}
                        columns={partsColumns}
                        slots={{
                          toolbar: CustomToolbar,
                          loadingOverlay: CustomLinearProgress,
                        }}
                        loading={loading}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pageSizeOptions={[5, 10]}
                        disableSelectionOnClick
                        //   getRowHeight={calculateRowHeight}
                        onRowSelectionModelChange={(newSelection) =>
                          handlePartSelectionChange(newSelection)
                        }
                        style={{ height: "100%", width: "100%" }}
                      />
                    </Box>
                  </Grid>

                  <HoverImage
                    src={hoveredImage}
                    isVisible={!!hoveredImage}
                    position={hoverPosition}
                  />

                  {/* Supplier Field */}
                  <Grid item xs={8} sm={8}>
                    <Autocomplete
                      options={supplierNames}
                      fullWidth
                      disablePortal
                      //   multiple
                      id="combobox-part"
                      name="part"
                      color="secondary"
                      value={selectedSupplier}
                      filterSelectedOptions
                      //   isOptionEqualToValue={(option, value) =>
                      //     option.label === value.value
                      //   }
                      onChange={handleSupplierChange}
                      // filterSelectedOptions
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Supplier")}
                          required
                          error={!!errors.supplier}
                          helperText={errors.supplier}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Date Field */}
                  <Grid item xs={4}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={enGB}
                    >
                      <DesktopDatePicker
                        label={t("Date")}
                        name="date"
                        id="datepicker-date"
                        color="secondary"
                        value={purchaseData.date}
                        onChange={(newValue) =>
                          handlePurchaseDayChange(newValue)
                        }
                        required
                        slots={{
                          openPickerButton: StyledButton,
                          date: StyledDate,
                        }}
                        // renderInput={(params) => (
                        //   <TextField
                        //     {...params}
                        //     error={!!errors.date}
                        //     helperText={errors.date}
                        //   />
                        // )}
                        slotProps={{
                          clearable: true,
                          openPickerIcon: { fontSize: "large" },
                          openPickerButton: { color: "secondary" },
                          textField: {
                            variant: "outlined",
                            color: "secondary",
                            fullWidth: true,
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!errors.date}
                            helperText={errors.date}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  {/* Save Part Button */}
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      onClick={handleSavePart}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#0EC6EA",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Save Part")}
                      </Typography>
                    </Button>
                  </Grid>

                  {/* Remove Part Button */}
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      onClick={handleRemovePart}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#A60A05",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Remove Part")}
                      </Typography>
                    </Button>
                  </Grid>

                  {/* Upload Documents Button */}
                  <Grid item xs={12} sm={12}>
                    <Button
                      variant="contained"
                      onClick={() => setOpenDocumentsDialog(true)}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#F6B411",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <PostAddOutlinedIcon />
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Upload Documents")}
                      </Typography>
                    </Button>
                  </Grid>

                  {/* File List */}
                  <Grid item xs={12}>
                    <List>
                      {documents.map((doc, index) => (
                        <ListItem
                          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                          key={index}
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleDeleteFile(doc)}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          }
                        >
                          <ListItemIcon>
                            <FileIcon />
                          </ListItemIcon>
                          <ListItemText primary={doc.name} />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>

                  {/* Submit Button */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#28a745",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Create")}
                      </Typography>
                    </Button>
                  </Grid>
                  {/* Cancel Button */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#A60A05", //D10A03
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Cancel")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>

                <Dialog
                  open={openDocumentsDialog}
                  onClose={() => setOpenDocumentsDialog(false)}
                >
                  <DialogTitle>{t("Upload Documents")}</DialogTitle>
                  <DialogContent>
                    <input
                      type="file"
                      multiple
                      onChange={handleDocumentsSelection}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenDocumentsDialog(false)}
                      color="secondary"
                    >
                      {t("Close")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default AddPurchase;
