/* eslint-disable react-hooks/exhaustive-deps */
// src/screens/AddWork.jsx

import React, { useEffect, useMemo, useState, useCallback } from "react";
// import { useAuth } from "../components/AuthContext";
import { firestore, storage } from "../firebaseConfig";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import {
  deleteObject,
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import {
  Autocomplete,
  Avatar,
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Grid,
  Paper,
  Typography,
  useTheme,
  Backdrop,
  Modal,
  Fade,
  IconButton,
  ListItemIcon,
  ListItemText,
  FormControl,
  FormGroup,
} from "@mui/material";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
import { useData } from "../components/DataProvider";
import { DataGrid } from "@mui/x-data-grid";
import CustomLinearProgress, {
  CustomToolbar,
  SpadaSwitch,
} from "../components/Common";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { format, parseISO, setHours, setMinutes, setSeconds } from "date-fns";
import { enGB } from "date-fns/locale";
import { styled } from "@mui/material/styles";
// Icons
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import CloseIcon from "@mui/icons-material/Close";
import LoadingWindow from "../components/LoadingWindow";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileIcon from "@mui/icons-material/InsertDriveFile";
// import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import HoverImage from "../components/HoverImage";
import "../App.css";
import { updateWorkRunningBalance } from "../components/DatabaseOps";
import { mobileFirestore } from "../mobileFirebaseConfig";

const StyledButton = styled(IconButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
}));

const StyledDate = styled(DesktopDatePicker)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  color:
    theme.palette.mode === "light"
      ? theme.palette.secondary.dark
      : theme.palette.secondary.light,
}));

const EditWork = ({ onClose, workDetails, onFeedback }) => {
  // const { currentUser } = useAuth();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [selectedClientRef, setSelectedClientRef] = useState(
    workDetails.client
  );
  const [selectedMotoRef, setSelectedMotoRef] = useState(
    workDetails.motorcycle
  );
  const [documents, setDocuments] = useState(workDetails.documents);
  const [files, setFiles] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const [openDocumentsDialog, setOpenDocumentsDialog] = useState(false);
  const [workDay, setWorkDay] = useState(workDetails.startDate.toDate());
  const [endDay, setEndDay] = useState(
    workDetails.endDate ? workDetails.endDate.toDate() : new Date()
  );
  const [completed, setCompleted] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [recommendedPrice, setRecommendedPrice] = useState("");
  const [recommendedPriceText, setRecommendedPriceText] = useState(
    t("Unit Price")
  );
  const [partsData, setPartsData] = useState([]);
  const [dataCommit, setDataCommit] = useState([]);
  const [selectedPart, setSelectedPart] = useState(null);
  const [partToRemove, setPartToRemove] = useState("");
  const { parts } = useData();
  const [motoData, setMotoData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [motoSelectionModel, setMotoSelectionModel] = useState([]);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 });
  // const [motoSearchOptions, setMotoSearchOptions] = useState([]);
  // const [selectedMotoSearchOption, setSelectedMotoSearchOption] =
  //   useState(null);
  // const [motoSearch, setMotoSearch] = useState("");
  // const [filteredMotos, setFilteredMotos] = useState([]);
  const [workData, setWorkData] = useState({
    amount: workDetails.amount,
    client: null,
    startDate: workDetails.startDate,
    completionDate: workDetails.completionDate
      ? workDetails.completionDate
      : null,
    id: workDetails.id,
    motorcycle: null,
    currentOdometer: null,
    notes: workDetails.notes,
    parts_used: workDetails.parts_used,
    work_details: workDetails.work_details,
    documents: [],
    status: workDetails.status === "Done",
  });
  let totalPrice = 0;

  //   const datagridMaxHeight = 250;

  // Trigger to AutoUpdate
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const fetchData = async () => {
      let partsUsedData = [];
      setCompleted(workDetails.status === "Done");

      try {
        // Get Client
        const clientDoc = await getDoc(workDetails.client);

        if (!clientDoc.exists()) {
          console.error(`Client document ${clientDoc.id} does not exist`);
          return null;
        }

        const clientDetails = clientDoc.data();
        // console.log(clientDetails);

        const dataClient = {
          birthday: parseISO(
            format(clientDetails.birthdate.toDate(), "yyyy-MM-dd")
          ),
          ...clientDetails,
        };

        // console.log(dataClient);
        setClientData([dataClient] || []);

        // Get Motorcycles and check the selected one
        const itemData = await Promise.all(
          clientDetails.motorcycles.map(async (docRef) => {
            let motoData;
            try {
              const motorcycleDoc = await getDoc(docRef);

              if (motorcycleDoc.exists()) {
                motoData = motorcycleDoc.data();

                return motoData;
              }
            } catch (error) {
              console.error("Error fetching document:", error);
              return null;
            }
          })
        );

        const validData = itemData.filter((ref) => ref !== null);
        setMotoData(validData);
        // setFilteredMotos(validData);
        // console.log(validData);

        const motorcycleDoc = await getDoc(workDetails.motorcycle);
        const motoDataL = motorcycleDoc.data();
        setWorkData({
          ...workData,
          currentOdometer: motoDataL.odometer,
        });
        setMotoSelectionModel(motoDataL.id);

        // Get Parts Used
        const partsUsedEntries = Object.entries(workDetails.parts_used);
        // console.log("partsUsedEntries: ", partsUsedEntries);

        const partsUsedPromises = partsUsedEntries.map(async ([key, part]) => {
          try {
            // console.log("Fetching part: ", part[0]);
            // Index 0: Document Reference
            // Index 1: Quantity
            // Index 2: Sale Price

            const partDoc = await getDoc(part[0]);
            // console.log("Part Data: ", partDoc.data(), key);
            if (partDoc.exists()) {
              return {
                id: key, // Unique identifier for DataGrid rows
                part: partDoc.data(),
                quantity: part[1],
                salePrice: part[2],
              };
              // biome-ignore lint/style/noUselessElse: <explanation>
            } else {
              console.error(`Part document ${part[0].id} does not exist`);
              return null;
            }
          } catch (error) {
            console.error(`Error fetching part document ${part[0].id}:`, error);
            return null;
          }
        });
        partsUsedData = await Promise.all(partsUsedPromises);
      } catch (error) {
        console.error(
          "Error fetching client, motorcycle, or parts data:",
          error
        );
        return null;
      } finally {
        setLoading(false);
      }

      const transformedPartsUsedData = partsUsedData.map((item) => ({
        id: item.id,
        photo: item.part.photo,
        barcode: item.part.barcode,
        name: item.part.name,
        category: item.part.category,
        quantity: item.quantity,
        salePrice: item.salePrice,
        totalPrice: item.salePrice * item.quantity,
      }));

      //   console.log(transformedPartsUsedData);
      setPartsData(transformedPartsUsedData);
      setDataCommit(partsUsedData);
    };
    fetchData();
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    // Calculate the total amount
    const total = partsData.reduce((acc, item) => acc + item.totalPrice, 0);
    // console.log(total.toFixed(2));
    setWorkData({ ...workData, amount: total.toFixed(2) });
  }, [partsData]);

  // Memoize the parts array
  const memoizedParts = useMemo(() => parts, [parts]);

  // Memoize the part names array
  const partNames = useMemo(
    () =>
      memoizedParts.map((part) => {
        // Check if both brand and origin exist
        return `${part.brand} (${part.origin}) ${part.name} - ${part.barcode}`;
      }),
    [memoizedParts]
  );

  const handleMouseEnter = (e, src) => {
    const rect = e.target.getBoundingClientRect();
    setHoverPosition({
      top: rect.top + window.scrollY,
      left: rect.right + window.scrollX,
    });
    setHoveredImage(src);
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
  };

  // Toggle modal open/close
  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  const closeModal = () => {
    setLoading(false);
  };

  // const handleSearchMotoChange = (event, value) => {
  //   setSelectedMotoSearchOption(value);
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // if (name === "motoSearch") setMotoSearch(value);
    // else {
    setWorkData({ ...workData, [name]: value });
    setErrors({ ...errors, [name]: "" });
    // }
  };

  const handleNumericChange = (event) => {
    const { name, value } = event.target;
    // Allow only numbers and dots
    if (/^\d*\.?\d*$/.test(value)) {
      setWorkData({ ...workData, [name]: value });
    }
  };

  const handlePartComboboxChange = (event, value) => {
    if (value) {
      const selectedPartData = memoizedParts.find(
        (part) =>
          `${part.brand} (${part.origin}) ${part.name} - ${part.barcode}` ===
          value
      );
      if (selectedPartData) {
        setErrors({ ...errors, selectedPart: "" });
        setSelectedPart(value);
        // console.log("Selected Part: ", selectedPartData);
        // console.log("Selected Part's Price: ", selectedPartData.sale_price);
        setRecommendedPriceText(t("Recommended Price"));
        setRecommendedPrice(selectedPartData.sale_price || "");
      } else {
        setRecommendedPriceText(t("Unit Price"));
        setRecommendedPrice("");
      }
    } else {
      setRecommendedPriceText(t("Unit Price"));
      setRecommendedPrice("");
    }
  };

  const handleQuantityChange = (event) => {
    // Allow only numbers and dots
    if (/^\d*\.?\d*$/.test(event.target.value)) {
      setErrors({ ...errors, quantity: "" });
      setQuantity(event.target.value);
    }
  };

  const getFileName = useCallback((url2process) => {
    const httpsReference = ref(storage, url2process);
    return httpsReference.name;
  }, []);

  // Mark files for delete operation
  const handleMarkFiles = (filePath) => {
    setFiles((prevFiles) => ({
      ...prevFiles,
      filePath: [...(prevFiles.filePath || []), filePath],
    }));
    // console.log(files);
    setDocuments(documents.filter((file) => file !== filePath));
  };

  // Delete new files from list
  const handleNewDeleteFile = (filePath) => {
    setNewFiles(newFiles.filter((file) => file !== filePath));
  };

  // Delete files
  const handleFileDelete = async () => {
    const docsRef = ref(storage, `works/${workData.id}/doc`);

    try {
      // List all items (file paths) under the path
      const res = await listAll(docsRef);

      // Delete files that match
      await Promise.all(
        res.items.map(async (itemRef) => {
          const downloadURL = await getDownloadURL(itemRef);

          if (files.filePath.includes(downloadURL)) {
            await deleteObject(itemRef);
            console.log("Deleted:", downloadURL);
          } else {
            console.log("Not Deleted:", downloadURL);
          }
        })
      );

      // console.log("All matching files deleted");
    } catch (error) {
      console.error("Error deleting files:", error);
    }
  };

  const handlePriceChange = (event) => {
    if (/^\d*\.?\d*$/.test(event.target.value)) {
      setRecommendedPrice(event.target.value);
      setRecommendedPriceText(t("Unit Price"));
      setErrors({ ...errors, recommendedPrice: "" });
    }
  };

  const handleWorkDayChange = (dateSent) => {
    if (dateSent === null || Number.isNaN(dateSent.getTime())) {
      console.error("Not a valid date!");
    } else {
      // Get the current time
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      const currentSecond = now.getSeconds();

      // Combine the selected date with the current time
      let combinedDate = setHours(dateSent, currentHour);
      combinedDate = setMinutes(combinedDate, currentMinute);
      combinedDate = setSeconds(combinedDate, currentSecond);

      // Convert the combined date-time to a Firestore timestamp
      const timestamp = new Date(combinedDate);

      setWorkDay(timestamp);
    }
  };

  const handleEndDayChange = (dateSent) => {
    if (dateSent === null || Number.isNaN(dateSent.getTime())) {
      console.error("Not a valid date!");
    } else {
      // Get the current time
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();
      const currentSecond = now.getSeconds();

      // Combine the selected date with the current time
      let combinedDate = setHours(dateSent, currentHour);
      combinedDate = setMinutes(combinedDate, currentMinute);
      combinedDate = setSeconds(combinedDate, currentSecond);

      // Convert the combined date-time to a Firestore timestamp
      const timestamp = new Date(combinedDate);

      setEndDay(timestamp);
    }
  };

  const preValidateFields = () => {
    const newErrors = {};
    if (!quantity) newErrors.quantity = t("Quantity is required");
    if (!recommendedPrice)
      newErrors.recommendedPrice = t("Unit Price is required");
    if (!selectedPart) newErrors.selectedPart = t("Part is required");
    return newErrors;
  };

  const handleSavePart = (event) => {
    setErrors({ ...errors, parts: "" });
    const err = preValidateFields();

    if (Object.keys(err).length === 0) {
      const id = uuidv4();

      if (selectedPart) {
        const selectedPartData = memoizedParts.find(
          (part) =>
            `${part.brand} (${part.origin}) ${part.name} - ${part.barcode}` ===
            selectedPart
        );
        if (selectedPartData) {
          //   console.log("Selected Part: ", selectedPartData);
          //   console.log("Selected Part's Price: ", selectedPartData.sale_price);

          const usedPartData = {
            id: id,
            photo: selectedPartData.photo,
            barcode: selectedPartData.barcode,
            name: selectedPartData.name,
            category: selectedPartData.category,
            quantity: quantity,
            salePrice: recommendedPrice,
            totalPrice: quantity * recommendedPrice,
          };

          const dataStore = {
            id: id,
            part: selectedPartData,
            quantity: quantity,
            salePrice: recommendedPrice,
          };

          setDataCommit((prevDataCommit) => [...prevDataCommit, dataStore]);

          //   console.log(dataCommit);

          setPartsData((prevPartsData) => [...prevPartsData, usedPartData]);
        } else {
          console.error("Part not found among parts!");
        }
      } else {
        console.error("Selected Part is not valid!");
      }
    } else {
      setErrors(err);
    }
  };

  const handleRemovePart = (event) => {
    setPartsData(partsData.filter((part) => part.id !== partToRemove[0]));
    setDataCommit(dataCommit.filter((data) => data.id !== partToRemove[0]));
  };

  const handleDocumentsSelection = (event) => {
    const files = Array.from(event.target.files);
    setNewFiles(files);
  };

  // May not be able to change client
  //   const handleClientSelectionChange = async (newSelection) => {
  //     setErrors({ ...errors, client: "" });
  //     newSelection.map(async (itemId) => {
  //       try {
  //         const clientRef = collection(firestore, "clients");
  //         const q = query(clientRef, where("id", "==", itemId));
  //         const querySnapshot = await getDocs(q);
  //         const clientDoc = querySnapshot.docs[0];

  //         //   console.log(doc(firestore, clientDoc.ref.path));
  //         setSelectedClientRef(doc(firestore, clientDoc.ref.path));

  //         const motoRef = clientDoc.data().motorcycles;
  //         //   console.log(motoRef);

  //         const itemData = await Promise.all(
  //           motoRef.map(async (docRef) => {
  //             let motoData;
  //             try {
  //               const motorcycleDoc = await getDoc(docRef);

  //               if (motorcycleDoc.exists()) {
  //                 motoData = motorcycleDoc.data();

  //                 return motoData;
  //               }
  //             } catch (error) {
  //               console.error("Error fetching document:", error);
  //               return null;
  //             }
  //           })
  //         );
  //         setMotoData(itemData);
  //         //   console.log(itemData);
  //       } catch (error) {
  //         console.error("Error fetching document:", error);
  //         return null;
  //       }
  //     });
  //   };

  // const motoColumnMapping = [
  //   { field: "brand", headerName: t("Brand") },
  //   { field: "model", headerName: t("Model") },
  //   { field: "year", headerName: t("Year") },
  //   { field: "license_plate", headerName: t("License Plate") },
  //   { field: "chasis_no", headerName: t("Chasis Number") },
  //   { field: "motor_no", headerName: t("Motor Number") },
  // ];

  // Create a mapping from headerName to field
  // const motoHeaderToFieldMap = motoColumnMapping.reduce((acc, column) => {
  //   acc[column.headerName] = column.field;
  //   return acc;
  // }, {});

  // const handleMotoSearchButtonClicked = () => {
  //   const field = motoHeaderToFieldMap[selectedMotoSearchOption] || "";
  //   if (!selectedMotoSearchOption) setFilteredMotos(motoData);
  //   else {
  //     const filtered = motoData.filter((motorcycle) => {
  //       return motorcycle[field]
  //         ?.toString()
  //         .toLowerCase()
  //         .includes(motoSearch.toLowerCase());
  //     });
  //     setFilteredMotos(filtered);
  //   }
  // };

  // Set selected IDs
  const handleMotoSelectionChange = async (newSelection) => {
    setErrors({ ...errors, motorcycle: "" });

    if (newSelection.length) {
      const itemRefs = await Promise.all(
        newSelection.map(async (itemId) => {
          try {
            const ref = collection(firestore, "motorcycles");
            const q = query(ref, where("id", "==", itemId));
            const querySnapshot = await getDocs(q);
            const refDoc = querySnapshot.docs[0];

            workData.currentOdometer = refDoc.data().odometer;

            // console.log(doc(firestore, refDoc.ref.path));
            setSelectedMotoRef(doc(firestore, refDoc.ref.path));
            return refDoc;
          } catch (error) {
            console.error("Error fetching document:", error);
            return null;
          }
        })
      );

      const validItemRefs = itemRefs.filter((ref) => ref !== null);
      const selectedIds = validItemRefs.map((moto) => moto.data().id);
      setMotoSelectionModel(selectedIds);
    } else {
      setSelectedMotoRef(null);
      setMotoSelectionModel([]);
    }
  };

  // Set selected IDs
  const handlePartSelectionChange = async (newSelection) => {
    setPartToRemove(newSelection);
  };

  const validateFields = () => {
    const newErrors = {};
    // eslint-disable-next-line no-undef
    if (!partsData.length)
      newErrors.parts = t("You must indicate the parts you used");
    if (!workData.amount) newErrors.amount = t("Amount is required");
    if (!workDetails.startDate) newErrors.startDate = t("Work day is required");
    if (!workData.work_details)
      newErrors.work_details = t("Work Details is required");
    if (!selectedClientRef || selectedClientRef.length < 2)
      newErrors.client = t("Client is required");
    if (!selectedMotoRef || selectedMotoRef.length < 2)
      newErrors.motorcycle = t("Motorcycle is required");
    return newErrors;
  };

  const handleClearData = () => {
    setWorkData({
      amount: "",
      client: null,
      currentOdometer: null,
      startDate: "",
      completionDate: "",
      id: "",
      motorcycle: null,
      notes: "",
      parts_used: [],
      work_details: "",
      documents: [],
    });
    setDocuments([]);
    setSelectedClientRef([]);
    setSelectedMotoRef([]);
    setWorkDay(null);
    setQuantity("");
    setRecommendedPrice("");
    setNewFiles([]);
    setMotoData([]);
    setPartsData([]);
    setSelectedPart(null);
    setErrors({});
  };

  function arrayToObject(arr) {
    const obj = {};
    for (let i = 0; i < arr.length; ++i) {
      obj[i] = arr[i];
    }
    return obj;
  }

  // Set selected IDs
  const getUsedParts = async () => {
    const itemRefs = await Promise.all(
      dataCommit.map(async (itemId) => {
        try {
          const partRef = collection(firestore, "parts");
          const q = query(partRef, where("id", "==", itemId.part.id));
          const querySnapshot = await getDocs(q);
          const partDoc = querySnapshot.docs[0];
          // console.log(partDoc);

          const partsUsedEntry = [
            doc(firestore, partDoc.ref.path),
            itemId.quantity, // Ensure you pass the correct quantity
            itemId.salePrice, // Ensure you pass the correct price
          ];

          totalPrice += Number(itemId.salePrice) * Number(itemId.quantity);

          // console.log(partDoc.ref.path); // Reference
          // console.log(partDoc.data()); // Data itself
          // console.log(partsUsedEntry);

          return partsUsedEntry;
        } catch (error) {
          console.error("Error fetching document:", error);
          return null;
        }
      })
    );

    return itemRefs;
  };

  const handleSubmit = async () => {
    const fieldErrors = validateFields();

    if (Object.keys(fieldErrors).length === 0) {
      setLoading(true);
      try {
        // Delete marked files
        await handleFileDelete();
        // Upload documents
        const documentUrls = [];
        for (const file of newFiles) {
          const documentRef = ref(
            storage,
            `works/${workData.id}/doc/${file.name}`
          );
          await uploadBytes(documentRef, file);
          const url = await getDownloadURL(documentRef);
          documentUrls.push(url);
        }

        if (documents.length) documents.map((file) => documentUrls.push(file));

        let tempDate;
        if (!workDay) tempDate = workDetails.startDate;
        else tempDate = workDay;

        let compDate;
        if (completed) {
          if (!endDay) {
            compDate = workDetails.endDate ? workDetails.endDate : new Date();
          } else {
            compDate = endDay;
          }
        }

        // const partsUsedArray = await getUsedParts();

        // Set photo and documents URL in workData
        const updatedWorkData = {
          ...workData,
          client: selectedClientRef,
          motorcycle: selectedMotoRef,
          documents: documentUrls,
          parts_used: arrayToObject(await getUsedParts()),
          startDate: tempDate,
          completionDate: completed ? compDate : null,
          status: completed ? "Done" : "In Progress",
        };

        // console.log(updatedWorkData);
        // Create document reference
        const workRef = collection(firestore, "works");
        // Search exact ID within documents
        const q = query(workRef, where("id", "==", updatedWorkData.id));
        // Search the query
        const querySnapshot = await getDocs(q);
        // Find the exact document
        const workDoc = querySnapshot.docs[0];
        // Create reference to this document
        const docRef = doc(firestore, "works", workDoc.id);
        // Update the document
        await updateDoc(docRef, updatedWorkData);

        // Update Mobile DB too
        const mobileWorkRef = collection(mobileFirestore, "works");
        // Search exact ID within documents
        const q1 = query(mobileWorkRef, where("id", "==", updatedWorkData.id));
        // Search the query
        const mobileQuerySnapshot = await getDocs(q1);
        // Find the exact document
        const mobileWorkDoc = mobileQuerySnapshot.docs[0];
        // Create reference to this document
        const mobileDocRef = doc(mobileFirestore, "works", mobileWorkDoc.id);

        const mClientRef = doc(
          mobileFirestore,
          "clients",
          selectedClientRef.id
        );
        const mMotoRef = doc(
          mobileFirestore,
          "motorcycles",
          selectedMotoRef.id
        );
        // Prepare the updated workData for the target database
        const { client, motorcycle, parts_used, ...remainingWorkData } =
          updatedWorkData;

        // Add to mobile DB
        await updateDoc(mobileDocRef, {
          ...remainingWorkData,
          motorcycle: mMotoRef,
          client: mClientRef,
        });

        // Update client balance
        const clientDoc = await getDoc(selectedClientRef);
        await updateWorkRunningBalance(
          clientDoc.id,
          docRef,
          Number(totalPrice)
        );

        // Update motorcycle's odometer
        const motoDoc = await getDoc(selectedMotoRef);
        try {
          if (motoDoc.exists()) {
            // Create reference to this document
            const docRef = doc(firestore, "motorcycles", motoDoc.id);
            // Update the motorcycle's document
            await updateDoc(docRef, {
              odometer: workData.currentOdometer,
            });
          }
        } catch (error) {
          console.error("Error fetching motorcycle document", error);
          onFeedback(t("Document fetching error"));
          return null;
        }

        const oldPartsUsed = Object.entries(workDetails.parts_used);

        const partsOldPromises = oldPartsUsed.map(async ([key, part]) => {
          try {
            // [0]: Dump
            // [1]:
            //  |--> Index 0: Document Reference
            //  |--> Index 1: Quantity
            //  |--> Index 2: Sale Price

            const partDoc = await getDoc(part[0]);
            // console.log("Part Data: ", partDoc.data(), key);
            if (partDoc.exists()) {
              const currentStock = Number(partDoc.data().current_stock);
              const updatedStock =
                currentStock + Number(oldPartsUsed[key][1][1]);
              // console.log(
              //   "Current Stock: ",
              //   currentStock,
              //   "Updated Stock: ",
              //   updatedStock
              // );
              // Create reference to this document
              const docRef = doc(firestore, "parts", partDoc.id);
              // Update the parts document
              await updateDoc(docRef, {
                current_stock: updatedStock,
              });
            }
          } catch (error) {
            console.error(`Error fetching part document ${part[0].id}:`, error);
            onFeedback(t("Document fetching error"));
            return null;
          }
        });

        await Promise.all(partsOldPromises);

        const partsUsedEntries = Object.entries(updatedWorkData.parts_used);

        const partsUsedPromises = partsUsedEntries.map(async ([key, part]) => {
          try {
            // [0]: Dump
            // [1]:
            //  |--> Index 0: Document Reference
            //  |--> Index 1: Quantity
            //  |--> Index 2: Sale Price

            const partDoc = await getDoc(part[0]);
            // console.log("Part Data: ", partDoc.data(), key);
            if (partDoc.exists()) {
              const currentStock = Number(partDoc.data().current_stock);
              const updatedStock = currentStock - Number(part[1]);
              // console.log(
              //   "Current Stock: ",
              //   currentStock,
              //   "Updated Stock: ",
              //   updatedStock
              // );
              // Create reference to this document
              const docRef = doc(firestore, "parts", partDoc.id);
              // Update the parts document
              await updateDoc(docRef, {
                current_stock: updatedStock,
              });
            }
          } catch (error) {
            console.error(`Error fetching part document ${part[0].id}:`, error);
            onFeedback(t("Document fetching error"));
            return null;
          }
        });

        await Promise.all(partsUsedPromises);

        handleClearData();
        onFeedback(t("Operation Successfull!"));
        handleClose();
      } catch (error) {
        console.error("Error adding document: ", error);
        onFeedback(t("Failed to Update Work"));
      } finally {
        setLoading(false);
      }
    } else {
      console.log(errors);
      // console.log(selectedClientRef);
      setErrors(fieldErrors);
    }
  };

  const handleCancel = async () => {
    handleClearData();
    handleClose();
  };

  const clientColumns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "photo",
      headerName: t("Photo"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          position="relative"
          onMouseEnter={(e) => handleMouseEnter(e, params.value)}
          onMouseLeave={handleMouseLeave}
        >
          <Avatar
            src={params.value}
            // variant="square"
            style={{ width: 45, height: 45 }}
          />
        </Box>
      ),
    },
    {
      field: "full_name",
      headerName: t("Full Name"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "birthday",
      headerName: t("Date of Birth"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 120,
      renderCell: (params) => {
        const workDay = format(params.row.birthdate.toDate(), "dd/MM/yyyy");
        return workDay ? workDay : "N/A";
      },
    },
    {
      field: "driving_licence",
      headerName: t("Driving License"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "phone",
      headerName: t("Phone Number"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "address",
      headerName: t("Address"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "description",
      headerName: t("Description"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
  ];

  const motoColumns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "photo",
      headerName: t("Photo"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          position="relative"
          onMouseEnter={(e) => handleMouseEnter(e, params.value)}
          onMouseLeave={handleMouseLeave}
        >
          <Avatar
            src={params.value}
            // variant="square"
            style={{ width: 45, height: 45 }}
          />
        </Box>
      ),
    },
    {
      field: "brand",
      headerName: t("Brand"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "model",
      headerName: t("Model"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "year",
      headerName: t("Year"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "odometer",
      headerName: t("Current KM"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "license_plate",
      headerName: t("License Plate"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "chasis_no",
      headerName: t("Chasis Number"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "motor_no",
      headerName: t("Motor Number"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "description",
      headerName: t("Description"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
  ];

  // useEffect(() => {
  //   setMotoSearchOptions(
  //     motoColumns
  //       .filter(
  //         (column) =>
  //           column.field !== "id" &&
  //           column.field !== "description" &&
  //           column.field !== "odometer" &&
  //           column.field !== "photo"
  //       )
  //       .map((column) => t(column.headerName))
  //   );
  // }, [t]);

  const partsColumns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "photo",
      headerName: t("Photo"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          position="relative"
          onMouseEnter={(e) => handleMouseEnter(e, params.value)}
          onMouseLeave={handleMouseLeave}
        >
          <Avatar
            src={params.value}
            // variant="square"
            style={{ width: 45, height: 45 }}
          />
        </Box>
      ),
    },
    {
      field: "barcode",
      headerName: t("Barcode"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      //   renderCell: (params) => {
      //     const part = params.row.partsUsed.part;
      //     return part ? part.barcode : "N/A";
      //   },
    },
    {
      field: "name",
      headerName: t("Name"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      //   renderCell: (params) => {
      //     const part = params.row.partsUsed.part;
      //     return part ? part.name : "N/A";
      //   },
    },
    {
      field: "category",
      headerName: t("Category"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 80,
      //   renderCell: (params) => {
      //     const part = params.row.partsUsed.part;
      //     return part ? part.category : "N/A";
      //   },
    },
    // {
    //   field: "purchase_price",
    //   headerName: t("Purchase Price"),
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   width: 100,
    // },
    // {
    //   field: "sale_price",
    //   headerName: t("Sale Price"),
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   width: 100,
    // },
    {
      field: "quantity",
      headerName: t("Quantity"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      type: "number",
    },
    {
      field: "salePrice",
      headerName: t("Unit Price"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      type: "number",
    },
    {
      field: "totalPrice",
      headerName: t("Total Price"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      type: "number",
    },
  ];

  const datagridMaxHeight = 251;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open} timeout={250}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                maxWidth: 950,
                width: "100%",
                margin: "0 auto",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                  maxHeight: "95vh",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                {/* Modal */}
                {loading && <LoadingWindow onClose={closeModal} />}

                <IconButton
                  aria-label="delete"
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>

                {/* Container */}
                <Grid container spacing={2}>
                  {/* Header Section */}
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Edit Work")}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Data Grid for Client Details */}
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        // component="h1"
                        variant="h6"
                        paddingLeft="10px"
                        color="red"
                      >
                        {errors.client ? errors.client : ""}
                      </Typography>
                    </Box>
                    <Box
                      m="0px 0px 0px 0px"
                      display="flex"
                      flex-direction="column"
                      //height="100vh" /* Full height of the viewport */
                      min-height="0" /* Ensure it can shrink */
                      width="100%"
                      sx={{
                        height: `${datagridMaxHeight / 1.25 - 2}px`,
                        width: "100%",
                        "& .MuiDataGrid-root": {
                          fontSize: "16px",
                          ...(theme.palette.mode === "dark"
                            ? {
                                border: "1px solid rgba(62, 67, 150, 1)",
                              }
                            : { border: "1px solid rgba(175, 63, 59, 1)" }),
                        },
                        "& .MuiDataGrid-cell": {
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "12px",
                        },
                        "& .name-column--cell": {
                          color: colors.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          backgroundColor: colors.blueAccent[700],
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "0.9rem",
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.greenAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                        "& .MuiDataGrid-virtualScroller": {
                          backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                          borderTop: "none",
                          ...(theme.palette.mode === "dark"
                            ? {
                                backgroundColor: colors.blueAccent[700],
                              }
                            : { backgroundColor: colors.redAccent[800] }),
                        },
                        "& .MuiCheckbox-root": {
                          color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-cell:hover": {
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.blueAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                      }}
                    >
                      <DataGrid
                        columnVisibilityModel={{
                          id: false,
                        }}
                        checkboxSelection={false}
                        disableMultipleRowSelection
                        rows={clientData}
                        columns={clientColumns}
                        slots={{
                          toolbar: CustomToolbar,
                          loadingOverlay: CustomLinearProgress,
                        }}
                        loading={loading}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 5,
                            },
                          },
                        }}
                        pageSizeOptions={[5, 10]}
                        disableSelectionOnClick
                        style={{ height: "100%", width: "100%" }}
                      />
                    </Box>
                  </Grid>

                  {/* Search Moto Options Autocomplete */}
                  {/* <Grid item xs={4}>
                    <Autocomplete
                      options={motoSearchOptions}
                      fullWidth
                      disablePortal
                      id="combobox-motoSearch"
                      name="motoSearch"
                      color="secondary"
                      value={selectedMotoSearchOption || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.value
                      }
                      onChange={(event, newValue) =>
                        handleSearchMotoChange(
                          "motoSearchOption",
                          newValue ? newValue : ""
                        )
                      }
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Search with...")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid> */}

                  {/* Search Moto TextField */}
                  {/* <Grid item xs={6}>
                    <TextField
                      label={t("Search")}
                      name="motoSearch"
                      color="secondary"
                      value={motoSearch}
                      onChange={handleInputChange}
                      fullWidth
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleMotoSearchButtonClicked();
                        }
                      }}
                    />
                  </Grid> */}

                  {/* Search Moto Button */}
                  {/* <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={handleMotoSearchButtonClicked}
                      fullWidth
                      sx={{
                        backgroundColor: "#555555",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <ManageSearchOutlinedIcon />
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft={"10px"}
                        sx={{
                          fontSize: {
                            xs: "0.5rem", // font size for extra-small screens
                            sm: "0.7rem", // font size for small screens
                            md: "0.9rem", // font size for medium screens
                            lg: "1.2rem", // font size for large screens
                            xl: "1.5rem", // font size for extra-large screens
                          },
                        }}
                      >
                        {t("Search")}
                      </Typography>
                    </Button>
                  </Grid> */}

                  {/* Data Grid for Motorcycle Details */}
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        // component="h1"
                        variant="h6"
                        paddingLeft="10px"
                        color="red"
                      >
                        {errors.motorcycle ? errors.motorcycle : ""}
                      </Typography>
                    </Box>
                    <Box
                      m="0px 0px 0px 0px"
                      display="flex"
                      flex-direction="column"
                      //height="100vh" /* Full height of the viewport */
                      min-height="0" /* Ensure it can shrink */
                      width="100%"
                      sx={{
                        height: `${datagridMaxHeight}px`,
                        width: "100%",
                        "& .MuiDataGrid-root": {
                          fontSize: "16px",
                          ...(theme.palette.mode === "dark"
                            ? {
                                border: "1px solid rgba(62, 67, 150, 1)",
                              }
                            : { border: "1px solid rgba(175, 63, 59, 1)" }),
                        },
                        "& .MuiDataGrid-cell": {
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "12px",
                        },
                        "& .name-column--cell": {
                          color: colors.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          backgroundColor: colors.blueAccent[700],
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "0.9rem",
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.greenAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                        "& .MuiDataGrid-virtualScroller": {
                          backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                          borderTop: "none",
                          ...(theme.palette.mode === "dark"
                            ? {
                                backgroundColor: colors.blueAccent[700],
                              }
                            : { backgroundColor: colors.redAccent[800] }),
                        },
                        "& .MuiCheckbox-root": {
                          color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-cell:hover": {
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.blueAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                      }}
                    >
                      <DataGrid
                        columnVisibilityModel={{
                          id: false,
                        }}
                        checkboxSelection={true}
                        disableMultipleRowSelection
                        rows={motoData || []}
                        columns={motoColumns}
                        slots={{
                          toolbar: CustomToolbar,
                          loadingOverlay: CustomLinearProgress,
                        }}
                        loading={loading}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 5,
                            },
                          },
                        }}
                        pageSizeOptions={[5, 10]}
                        disableSelectionOnClick
                        onRowSelectionModelChange={(newSelection) =>
                          handleMotoSelectionChange(newSelection)
                        }
                        rowSelectionModel={motoSelectionModel}
                        style={{ height: "100%", width: "100%" }}
                      />
                    </Box>
                  </Grid>

                  {/* Parts Field */}
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      options={partNames}
                      fullWidth
                      disablePortal
                      //   multiple
                      id="combobox-part"
                      name="part"
                      color="secondary"
                      value={selectedPart}
                      filterSelectedOptions
                      // isOptionEqualToValue={(option, value) =>
                      //   option.label === value.value
                      // }
                      onChange={handlePartComboboxChange}
                      defaultValue={[]}
                      // filterSelectedOptions
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Part")}
                          error={!!errors.selectedPart}
                          helperText={errors.selectedPart}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  {/* Quantity Field */}
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label={t("Quantity")}
                      name="quantity"
                      id="textfield-quantity"
                      color="secondary"
                      required
                      value={quantity}
                      onChange={handleQuantityChange}
                      fullWidth
                      error={!!errors.quantity}
                      helperText={errors.quantity}
                      inputProps={{
                        inputMode: "decimal", // mobile keyboard with numbers and dot
                      }}
                    />
                  </Grid>

                  {/* Unit Price Field */}
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label={recommendedPriceText}
                      name="lastPrice"
                      id="textfield-lastPrice"
                      color="secondary"
                      required
                      value={recommendedPrice}
                      //   defaultValue={recommendedPrice}
                      onChange={handlePriceChange}
                      fullWidth
                      error={!!errors.recommendedPrice}
                      helperText={errors.recommendedPrice}
                      inputProps={{
                        inputMode: "decimal", // mobile keyboard with numbers and dot
                      }}
                      InputLabelProps={
                        recommendedPrice ? { shrink: true } : { shrink: false }
                      }
                    />
                  </Grid>

                  {/* Save Part Button */}
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      onClick={handleSavePart}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#0EC6EA",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Save Part")}
                      </Typography>
                    </Button>
                  </Grid>

                  {/* Remove Part Button */}
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      onClick={handleRemovePart}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#A60A05",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Remove Part")}
                      </Typography>
                    </Button>
                  </Grid>

                  {/* Data Grid for Parts Used Details */}
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        // component="h1"
                        variant="h6"
                        paddingLeft="10px"
                        color="red"
                      >
                        {errors.parts ? errors.parts : ""}
                      </Typography>
                    </Box>
                    <Box
                      m="0px 0px 0px 0px"
                      display="flex"
                      flex-direction="column"
                      //height="100vh" /* Full height of the viewport */
                      min-height="0" /* Ensure it can shrink */
                      width="100%"
                      sx={{
                        height: `${datagridMaxHeight * 1.65}px`,
                        width: "100%",
                        "& .MuiDataGrid-root": {
                          fontSize: "16px",
                          ...(theme.palette.mode === "dark"
                            ? {
                                border: "1px solid rgba(62, 67, 150, 1)",
                              }
                            : { border: "1px solid rgba(175, 63, 59, 1)" }),
                        },
                        "& .MuiDataGrid-cell": {
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "12px",
                        },
                        "& .name-column--cell": {
                          color: colors.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          backgroundColor: colors.blueAccent[700],
                          borderBottom: "none",
                          fontFamily: "Poppins",
                          fontSize: "0.9rem",
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.greenAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                        "& .MuiDataGrid-virtualScroller": {
                          backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                          borderTop: "none",
                          ...(theme.palette.mode === "dark"
                            ? {
                                backgroundColor: colors.blueAccent[700],
                              }
                            : { backgroundColor: colors.redAccent[800] }),
                        },
                        "& .MuiCheckbox-root": {
                          color: `${colors.greenAccent[200]} !important`,
                        },
                        "& .MuiDataGrid-cell:hover": {
                          ...(theme.palette.mode === "dark"
                            ? {
                                color: colors.blueAccent[500],
                              }
                            : { color: colors.redAccent[500] }),
                        },
                      }}
                    >
                      <DataGrid
                        columnVisibilityModel={{
                          id: false,
                        }}
                        disableMultipleRowSelection
                        checkboxSelection={true}
                        rows={partsData}
                        columns={partsColumns}
                        slots={{
                          toolbar: CustomToolbar,
                          loadingOverlay: CustomLinearProgress,
                        }}
                        loading={loading}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 5,
                            },
                          },
                        }}
                        pageSizeOptions={[5, 10]}
                        disableSelectionOnClick
                        onRowSelectionModelChange={(newSelection) =>
                          handlePartSelectionChange(newSelection)
                        }
                        style={{ height: "100%", width: "100%" }}
                      />
                    </Box>
                  </Grid>

                  <HoverImage
                    src={hoveredImage}
                    isVisible={!!hoveredImage}
                    position={hoverPosition}
                  />

                  <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                      {/* Start Date Field */}
                      <Grid item xs={4}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          adapterLocale={enGB}
                        >
                          <DesktopDatePicker
                            label={t("Start Date")}
                            name="startDate"
                            id="datepicker-date"
                            color="secondary"
                            value={parseISO(format(workDay, "yyyy-MM-dd"))}
                            onChange={(newValue) =>
                              handleWorkDayChange(newValue)
                            }
                            required
                            slots={{
                              openPickerButton: StyledButton,
                              date: StyledDate,
                            }}
                            // renderInput={(params) => (
                            //   <TextField
                            //     {...params}
                            //     error={!!errors.date}
                            //     helperText={errors.date}
                            //   />
                            // )}
                            slotProps={{
                              clearable: true,
                              openPickerIcon: { fontSize: "large" },
                              openPickerButton: { color: "secondary" },
                              textField: {
                                variant: "outlined",
                                color: "secondary",
                                fullWidth: true,
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={!!errors.startDate}
                                helperText={errors.startDate}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>

                      {/* Complete Switch */}
                      <Grid
                        item
                        xs={4}
                        alignContent={"center"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        display="flex"
                        flex-direction="column"
                      >
                        <FormControl
                          component="fieldset"
                          style={{ marginLeft: "0px" }}
                        >
                          <FormGroup>
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                            >
                              <Typography
                                variant="r12"
                                sx={{
                                  fontSize: {
                                    xs: "0.5rem",
                                    sm: "0.6rem",
                                    md: "0.7rem",
                                    lg: "0.8rem",
                                    xl: "1rem",
                                  },
                                }}
                              >
                                {t("Complete The Work")}
                              </Typography>
                              <SpadaSwitch
                                foreColor={colors.greenAccent[500]}
                                iconColor={"#000000"}
                                onChange={() => setCompleted(!completed)}
                                checked={completed}
                              />
                            </Box>
                          </FormGroup>
                        </FormControl>
                      </Grid>

                      {/* Completion Date Field */}
                      {completed && (
                        <Grid item xs={4}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={enGB}
                          >
                            <DesktopDatePicker
                              label={t("Completion Date")}
                              name="completionDate"
                              id="datepicker-comp-date"
                              color="secondary"
                              value={
                                workDetails.completionDate
                                  ? parseISO(format(endDay, "yyyy-MM-dd"))
                                  : parseISO(format(new Date(), "yyyy-MM-dd"))
                              }
                              onChange={(newValue) =>
                                handleEndDayChange(newValue)
                              }
                              required
                              slots={{
                                openPickerButton: StyledButton,
                                date: StyledDate,
                              }}
                              // renderInput={(params) => (
                              //   <TextField
                              //     {...params}
                              //     error={!!errors.date}
                              //     helperText={errors.date}
                              //   />
                              // )}
                              slotProps={{
                                clearable: true,
                                openPickerIcon: { fontSize: "large" },
                                openPickerButton: { color: "secondary" },
                                textField: {
                                  variant: "outlined",
                                  color: "secondary",
                                  fullWidth: true,
                                },
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={!!errors.completionDate}
                                  helperText={errors.completionDate}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                      )}

                      {/* Work Details Field */}
                      <Grid item xs={9}>
                        <TextField
                          label={t("Work Details")}
                          name="work_details"
                          id="textfield-work_details"
                          color="secondary"
                          value={workData.work_details}
                          onChange={handleInputChange}
                          required
                          fullWidth
                          multiline
                          error={!!errors.work_details}
                          helperText={errors.work_details}
                          InputLabelProps={
                            workData.work_details
                              ? { shrink: true }
                              : { shrink: false }
                          }
                        />
                      </Grid>

                      {/* Current Odometer Field */}
                      <Grid item xs={3}>
                        <TextField
                          label={t("Current KM")}
                          name="currentOdometer"
                          id="textfield-odometer"
                          color="secondary"
                          value={workData.currentOdometer}
                          onChange={handleNumericChange}
                          fullWidth
                          multiline
                          InputLabelProps={
                            workData.currentOdometer
                              ? { shrink: true }
                              : { shrink: false }
                          }
                        />
                      </Grid>

                      {/* Notes Field */}
                      <Grid item xs={9}>
                        <TextField
                          label={t("Notes")}
                          name="notes"
                          id="textfield-notes"
                          color="secondary"
                          value={workData.notes}
                          onChange={handleInputChange}
                          fullWidth
                          multiline
                          InputLabelProps={
                            workData.notes
                              ? { shrink: true }
                              : { shrink: false }
                          }
                        />
                      </Grid>

                      {/* Amount Field */}
                      <Grid item xs={3}>
                        <TextField
                          label={t("Amount")}
                          name="amount"
                          id="textfield-amount"
                          color="secondary"
                          value={workData.amount}
                          // onChange={handleAmountChange}
                          fullWidth
                          readOnly
                          error={!!errors.amount}
                          helperText={errors.amount}
                          InputLabelProps={
                            workData.amount
                              ? { shrink: true }
                              : { shrink: false }
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* File List */}
                  <Grid item xs={12} md={4} container justifyContent="center">
                    {/* Old File List */}
                    <Grid item xs={6}>
                      <List
                        sx={{
                          width: "100%",
                          // maxWidth: 360,
                          bgcolor: "background.paper",
                          position: "relative",
                          overflow: "auto",
                          maxHeight: 100,
                          "& ul": { padding: 0 },
                        }}
                        subheader={<li />}
                      >
                        {documents.map((doc, index) => (
                          <ListItem
                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                            key={index}
                            secondaryAction={
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => handleMarkFiles(doc)}
                              >
                                <DeleteOutlineOutlinedIcon />
                              </IconButton>
                            }
                          >
                            <ListItemIcon>
                              <FileIcon />
                            </ListItemIcon>
                            <ListItemText primary={getFileName(doc)} />
                          </ListItem>
                        ))}
                        {/* {[0, 1, 2, 3, 4].map((sectionId) => (
                        <li key={`section-${sectionId}`}>
                          <ul>
                            <ListSubheader>{`I'm sticky ${sectionId}`}</ListSubheader>
                            {[0, 1, 2].map((item) => (
                              <ListItem key={`item-${sectionId}-${item}`}>
                                <ListItemText primary={`Item ${item}`} />
                              </ListItem>
                            ))}
                          </ul>
                        </li>
                      ))} */}
                      </List>
                    </Grid>

                    {/* New File List */}
                    <Grid item xs={6}>
                      <List
                        sx={{
                          width: "100%",
                          // maxWidth: 360,
                          bgcolor: "background.paper",
                          position: "relative",
                          overflow: "auto",
                          maxHeight: 100,
                          "& ul": { padding: 0 },
                        }}
                        subheader={<li />}
                      >
                        {newFiles.map((doc, index) => (
                          <ListItem
                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                            key={index}
                            secondaryAction={
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => handleNewDeleteFile(doc)}
                              >
                                <DeleteOutlineOutlinedIcon />
                              </IconButton>
                            }
                          >
                            <ListItemIcon>
                              <FileIcon />
                            </ListItemIcon>
                            <ListItemText primary={getFileName(doc.name)} />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                  </Grid>

                  {/* Upload Documents Button */}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      onClick={() => setOpenDocumentsDialog(true)}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#F6B411",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <PostAddOutlinedIcon />
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Upload Documents")}
                      </Typography>
                    </Button>
                  </Grid>

                  {/* Submit Button */}
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#28a745",
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Save Work")}
                      </Typography>
                    </Button>
                  </Grid>

                  {/* Cancel Button */}
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      fullWidth
                      sx={{
                        mt: 1,
                        mb: 0,
                        backgroundColor: "#A60A05", //D10A03
                        color: "#fff",
                        height: "50px",
                      }}
                    >
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Cancel")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>

                <Dialog
                  open={openDocumentsDialog}
                  onClose={() => setOpenDocumentsDialog(false)}
                >
                  <DialogTitle>{t("Upload Documents")}</DialogTitle>
                  <DialogContent>
                    <input
                      type="file"
                      multiple
                      onChange={handleDocumentsSelection}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setOpenDocumentsDialog(false)}
                      color="secondary"
                    >
                      {t("Close")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default EditWork;
