import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// color design tokens export
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          100: "#e0e0e0",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#858585",
          500: "#666666",
          600: "#525252",
          700: "#3d3d3d",
          800: "#292929",
          900: "#141414",
        },
        primary: {
          100: "#d0d1d5",
          200: "#a1a4ab",
          300: "#727681",
          400: "#1F2A40",
          500: "#141b2d",
          600: "#101624",
          700: "#0c101b",
          800: "#080b12",
          900: "#040509",
        },
        greenAccent: {
          100: "#dbf5ee",
          200: "#b7ebde",
          300: "#94e2cd",
          400: "#70d8bd",
          500: "#4cceac",
          600: "#3da58a",
          700: "#2e7c67",
          800: "#1e5245",
          900: "#0f2922",
        },
        redAccent: {
          100: "#f8dcdb",
          200: "#f1b9b7",
          300: "#e99592",
          400: "#e2726e",
          500: "#db4f4a",
          600: "#af3f3b",
          700: "#832f2c",
          800: "#58201e",
          900: "#2c100f",
        },
        blueAccent: {
          100: "#e1e2fe",
          200: "#c3c6fd",
          300: "#a4a9fc",
          400: "#868dfb",
          500: "#6870fa",
          600: "#535ac8",
          700: "#3e4396",
          800: "#2a2d64",
          900: "#151632",
        },
        pinkAccent: {
          100: "#FFF0F3",
          200: "#FFCCD5",
          300: "#FFB3C1",
          400: "#FF8FA3",
          500: "#FF758F",
          600: "#FF4D6D",
          700: "#C9184A",
          800: "#A4133C",
          900: "#800F2F",
          990: "#590D22",
        },
        orangeAccent: {
          100: "#FFEA00",
          200: "#FFDD00",
          300: "#FFD000",
          400: "#FFC300",
          500: "#FFB700",
          600: "#FFAA00",
          700: "#FFA200",
          800: "#FF9500",
          900: "#FF8800",
          990: "#FF7B00",
        },
        brownAccent: {
          100: "#FFEDD8",
          200: "#F3D5B5",
          300: "#E7BC91",
          400: "#D4A276",
          500: "#BC8A5F",
          600: "#A47148",
          700: "#8B5E34",
          800: "#6F4518",
          900: "#603808",
          990: "#583101",
        },
        violetAccent: {
          100: "#DEC9E9",
          200: "#DAC3E8",
          300: "#D2B7E5",
          400: "#C19EE0",
          500: "#B185DB",
          600: "#A06CD5",
          700: "#9163CB",
          800: "#815AC0",
          900: "#7251B5",
          990: "#6247AA",
        },
        petrolGreen: {
          100: "#99E2B4",
          200: "#88D4AB",
          300: "#78C6A3",
          400: "#67B99A",
          500: "#56AB91",
          600: "#469D89",
          700: "#358F80",
          800: "#248277",
          900: "#14746F",
          990: "#036666",
        },
        limeAccent: {
          100: "#FFFF3F",
          200: "#EEEF20",
          300: "#DDDF00",
          400: "#D4D700",
          500: "#BFD200",
          600: "#AACC00",
          700: "#80B918",
          800: "#55A630",
          900: "#2B9348",
          990: "#007F5F",
        },
      }
    : {
        grey: {
          100: "#141414",
          200: "#292929",
          300: "#3d3d3d",
          400: "#525252",
          500: "#666666",
          600: "#858585",
          700: "#a3a3a3",
          800: "#c2c2c2",
          900: "#e0e0e0",
        },
        primary: {
          100: "#040509",
          200: "#080b12",
          300: "#0c101b",
          400: "#f2f0f0", // manually changed
          500: "#141b2d",
          600: "#1F2A40",
          700: "#727681",
          800: "#a1a4ab",
          900: "#d0d1d5",
        },
        greenAccent: {
          100: "#0f2922",
          200: "#1e5245",
          300: "#2e7c67",
          400: "#3da58a",
          500: "#4cceac",
          600: "#70d8bd",
          700: "#94e2cd",
          800: "#b7ebde",
          900: "#dbf5ee",
        },
        redAccent: {
          100: "#2c100f",
          200: "#58201e",
          300: "#832f2c",
          400: "#af3f3b",
          500: "#db4f4a",
          600: "#e2726e",
          700: "#e99592",
          800: "#f1b9b7",
          900: "#f8dcdb",
        },
        blueAccent: {
          100: "#151632",
          200: "#2a2d64",
          300: "#3e4396",
          400: "#535ac8",
          500: "#6870fa",
          600: "#868dfb",
          700: "#a4a9fc",
          800: "#c3c6fd",
          900: "#e1e2fe",
        },
        pinkAccent: {
          100: "#590D22",
          200: "#800F2F",
          300: "#A4133C",
          400: "#C9184A",
          500: "#FF4D6D",
          600: "#FF758F",
          700: "#FF8FA3",
          800: "#FFB3C1",
          900: "#FFCCD5",
          990: "#FFF0F3",
        },
        orangeAccent: {
          100: "#FF7B00",
          200: "#FF8800",
          300: "#FF9500",
          400: "#FFA200",
          500: "#FFAA00",
          600: "#FFB700",
          700: "#FFC300",
          800: "#FFD000",
          900: "#FFDD00",
          990: "#FFEA00",
        },
        brownAccent: {
          100: "#583101",
          200: "#603808",
          300: "#6F4518",
          400: "#8B5E34",
          500: "#A47148",
          600: "#BC8A5F",
          700: "#D4A276",
          800: "#E7BC91",
          900: "#F3D5B5",
          990: "#FFEDD8",
        },
        violetAccent: {
          100: "#6247AA",
          200: "#7251B5",
          300: "#815AC0",
          400: "#9163CB",
          500: "#A06CD5",
          600: "#B185DB",
          700: "#C19EE0",
          800: "#D2B7E5",
          900: "#DAC3E8",
          990: "#DEC9E9",
        },
        petrolGreen: {
          100: "#036666",
          200: "#14746F",
          300: "#248277",
          400: "#358F80",
          500: "#469D89",
          600: "#56AB91",
          700: "#67B99A",
          800: "#78C6A3",
          900: "#88D4AB",
          990: "#99E2B4",
        },
        limeAccent: {
          100: "#007F5F",
          200: "#2B9348",
          300: "#55A630",
          400: "#80B918",
          500: "#AACC00",
          600: "#BFD200",
          700: "#D4D700",
          800: "#DDDF00",
          900: "#EEEF20",
          990: "#FFFF3F",
        },
      }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: colors.primary[600],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[500],
            },
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.primary[600],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: "#fcfcfc",
            },
          }),
    },
    typography: {
      fontFamily: ["Source Sans 3", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans 3", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans 3", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans 3", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans 3", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans 3", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans 3", "sans-serif"].join(","),
        fontSize: 14,
      },
      r16: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 16,
      },
      r32: {
        fontFamily: ["Roboto", "sans-serif"].join(","),
        fontSize: 32,
      },
      rbi16: {
        fontFamily: ["Robot Rebels", "sans-serif"].join(","),
        fontSize: 16,
      },
      rbi32: {
        fontFamily: ["Robot Rebels Italic", "sans-serif"].join(","),
        fontSize: 32,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("dark");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
