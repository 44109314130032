// src/screens/ClientSettings.jsx

import React, { useState } from "react";
// import { useAuth } from "../components/AuthContext";
import { firestore } from "../firebaseConfig";
import {
  collection,
  doc,
  updateDoc,
  query,
  where,
  getDocs,
  setDoc,
  getDoc,
} from "firebase/firestore";
import {
  Box,
  TextField,
  Grid,
  Paper,
  Typography,
  useTheme,
  Backdrop,
  Modal,
  Fade,
  IconButton,
  Avatar,
  FormGroup,
  FormControlLabel,
  FormControl,
  Checkbox,
  Button,
  FormLabel,
} from "@mui/material";
import { tokens } from "../theme";
import { useTranslation } from "react-i18next";
// Images
import emptyPhoto from "../assets/no_image.png";
// Icons
import CloseIcon from "@mui/icons-material/Close";
import LoadingWindow from "../components/LoadingWindow";
import { format } from "date-fns";
import { pink } from "@mui/material/colors";
import { mobileFirestore } from "../mobileFirebaseConfig";

const ClientSettings = ({ onClose, onFeedback, clientDetails }) => {
  // const { currentUser } = useAuth();
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const birthday = format(clientDetails.birthdate.toDate(), "dd/MM/yyyy");
  const [permissions, setPermissions] = useState({
    mobileAppPerm: clientDetails.permissions
      ? clientDetails.permissions[0]
      : false,
    mobileAppProfileCheck: clientDetails.permissions
      ? clientDetails.permissions[1]
      : false,
    mobileAppMotoCheck: clientDetails.permissions
      ? clientDetails.permissions[2]
      : false,
    mobileAppAppointmentCheck: clientDetails.permissions
      ? clientDetails.permissions[3]
      : false,
  });

  const handleChange = (event) => {
    const { name, checked } = event.target;

    setPermissions((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const {
    mobileAppPerm,
    mobileAppProfileCheck,
    mobileAppMotoCheck,
    mobileAppAppointmentCheck,
  } = permissions;

  // Toggle modal open/close
  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  const closeModal = () => {
    setLoading(false);
  };

  const handleClearData = () => {};

  async function syncUserDocument(
    sourceFirestore,
    targetFirestore,
    srcDocument,
    dstDocument,
    docId
  ) {
    try {
      // Create a reference to the source document
      const sourceDocRef = doc(sourceFirestore, srcDocument, docId);

      // Fetch the source document
      const sourceDoc = await getDoc(sourceDocRef);

      // Check if the source document exists
      if (!sourceDoc.exists()) {
        // console.error(`Source document with ID ${docId} does not exist.`);
        return;
      }

      const dataToWrite = sourceDoc.data();

      //   console.log("Source document data:", dataToWrite);

      const motorcyclesRefs = dataToWrite.motorcycles || [];
      const motorcyclesData = [];

      for (const motorcycleRef of motorcyclesRefs) {
        // Get each motorcycle document by its reference
        const motorcycleDocSnapshot = await getDoc(motorcycleRef);
        if (motorcycleDocSnapshot.exists()) {
          motorcyclesData.push({
            // Save the document ID of the motorcycle
            id: motorcycleRef.id,
            // Store motorcycle data
            data: motorcycleDocSnapshot.data(),
          });
        }
      }

      //   console.log("Source motos: ", motorcyclesRefs);

      // Step 4: Copy the motorcycles data to the target Firestore
      const targetMotorcyclesCollection = collection(
        targetFirestore,
        "motorcycles"
      );

      const motorcycleDocsRef = [];

      for (const motorcycle of motorcyclesData) {
        const targetMotorcycleDocRef = doc(
          targetMotorcyclesCollection,
          motorcycle.id
        ); // Use the same document ID

        // Check if the motorcycle document already exists in the target database
        const targetMotorcycleDocSnapshot = await getDoc(
          targetMotorcycleDocRef
        );

        if (targetMotorcycleDocSnapshot.exists()) {
          //   console.log(
          //     `Motorcycle with ID ${motorcycle.id} already exists in target database. Skipping.`
          //   );
          continue; // Skip this motorcycle if it already exists
        }

        // Log the motorcycle data before writing it to Firestore
        // console.log("Motorcycle data:", motorcycle.data);

        // Save the document with the same ID
        await setDoc(targetMotorcycleDocRef, motorcycle.data); // Save the document with the same ID
        motorcycleDocsRef.push(targetMotorcycleDocRef); // Store the reference for future use
      }

      //   console.log("Destination motos: ", motorcycleDocsRef);

      // Step 5: Update the target document (excluding motorcycles field)
      const { motorcycles, ...documentWithoutMotorcycles } = dataToWrite;
      // Create a reference to the target document in the second database
      const targetDocRef = doc(targetFirestore, dstDocument, docId);
      // Fetch the target document to check if it already exists
      const targetDoc = await getDoc(targetDocRef);

      if (targetDoc.exists()) {
        // console.log(
        //   `Document with ID ${docId} already exists in the target database.`
        // );
        if (motorcycleDocsRef.length > 0) {
          await updateDoc(targetDocRef, {
            ...documentWithoutMotorcycles,
            motorcycles: { ...motorcycles, motorcycleDocsRef },
          });
        } else {
          await updateDoc(targetDocRef, {
            ...documentWithoutMotorcycles,
            motorcycles: targetDoc.data().motorcycles,
          });
        }
      } else {
        // Replace the 'motorcycles' field in the target document with references to the newly copied motorcycle documents
        await setDoc(targetDocRef, {
          ...documentWithoutMotorcycles,
          motorcycles: motorcycleDocsRef,
        });
      }

      // Fetch works where the motorcycle field is one of the motorcycle references
      for (const motorcycleRef of motorcyclesRefs) {
        const worksQuerySnapshot = await getDocs(
          query(
            collection(sourceFirestore, "works"),
            where("motorcycle", "==", motorcycleRef)
          )
        );

        for (const workDoc of worksQuerySnapshot.docs) {
          const workData = workDoc.data();
          const workId = workDoc.id;

          const targetMotorcycleRef = motorcycleDocsRef.find(
            (ref) => ref.id === motorcycleRef.id
          );

          // Prepare the updated workData for the target database
          const { client, motorcycle, parts_used, ...remainingWorkData } =
            workData;

          const targetWorkDocRef = doc(targetFirestore, "works", workId);
          console.log(targetWorkDocRef);

          // Check if the work document already exists in the target database
          const targetWorkDocSnapshot = await getDoc(targetWorkDocRef);

          if (!targetWorkDocSnapshot.exists()) {
            // Create a new work document in the target DB with the same ID
            await setDoc(targetWorkDocRef, {
              ...remainingWorkData,
              motorcycle: targetMotorcycleRef,
              client: targetDocRef,
            });
          }
        }
      }

      //   console.log(
      //     `Document with ID ${docId} successfully copied to the target database.`
      //   );
    } catch (error) {
      console.error("Error syncing document:", error);
    }
  }

  const handleSubmit = async () => {
    setLoading(true);

    try {
      // Create document reference
      const clientRef = collection(firestore, "clients");
      // Search exact ID within documents
      const q = query(clientRef, where("id", "==", clientDetails.id));
      // Search the query
      const querySnapshot = await getDocs(q);
      // Find the exact document
      const clientDoc = querySnapshot.docs[0];
      // Create reference to this document
      const docRef = doc(firestore, "clients", clientDoc.id);
      // Update the availableDates object
      const updatedPermissions = {
        permissions: [
          mobileAppPerm,
          mobileAppProfileCheck,
          mobileAppMotoCheck,
          mobileAppAppointmentCheck,
        ],
      };

      // Update the document
      await updateDoc(docRef, updatedPermissions);
      // Get Data
      //   const userData = await getDoc(docRef);
      if (permissions.mobileAppPerm) {
        await syncUserDocument(
          firestore,
          mobileFirestore,
          "clients",
          "clients",
          clientDoc.id
        );
      }
      handleClearData(); // Clear form fields after submission
      onFeedback(t("Operation Successfull!"));
      handleClose();
    } catch (error) {
      //   console.error("Error adding document: ", error);
      onFeedback(t("Failed to Update Client"));
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = async () => {
    handleClose();
  };

  const imageSize = {
    width: 250, // Adjust the width as needed
    height: 250, // Adjust the height as needed
  };

  const renderPhoto = () => {
    if (clientDetails.photo) {
      return (
        <Avatar
          src={clientDetails.photo}
          alt="Client"
          sx={{
            width: imageSize.width,
            height: imageSize.height,
            objectFit: "scale-down",
            mb: "10px",
          }}
        />
      );
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else {
      return (
        <Avatar
          src={emptyPhoto}
          alt="Client"
          sx={{
            width: imageSize.width,
            height: imageSize.height,
            objectFit: "scale-down",
            mb: "10px",
          }}
        />
      );
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Fade in={open} timeout={250}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "95vh",
                maxWidth: 600,
                width: "100%",
                margin: "0 auto",
                overflowY: "auto",
              }}
            >
              <Paper
                elevation={6}
                sx={{
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  backdropFilter: "blur(10px)",
                  borderRadius: "20px",
                  maxHeight: "95vh",
                  width: "100%",
                  overflowY: "auto",
                }}
              >
                {/* Modal */}
                {loading && <LoadingWindow onClose={closeModal} />}

                <IconButton
                  aria-label="delete"
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  <CloseIcon />
                </IconButton>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        component="h1"
                        variant="r16"
                        paddingLeft="10px"
                      >
                        {t("Preferences")}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Photo Field */}
                  <Grid item xs={12} sm={12} container justifyContent="center">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      {renderPhoto()}
                    </Box>
                  </Grid>

                  {/* Full Name Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Full Name")}
                      name="full_name"
                      color="secondary"
                      value={clientDetails.full_name}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Date of Birth Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Date of Birth")}
                      name="birthdate"
                      color="secondary"
                      value={birthday}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Driving License Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Driving License")}
                      name="driving_licence"
                      color="secondary"
                      value={clientDetails.driving_licence}
                      readOnly
                      fullWidth
                    />
                  </Grid>

                  {/* Phone Field */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("Phone")}
                      name="phone"
                      color="secondary"
                      multiline
                      value={clientDetails.phone}
                      readOnly
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    sx={{ m: 3 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormLabel component="legend">
                      <Typography
                        component="h1"
                        variant="r16"
                        // ml="50px"
                        color={colors.greenAccent[500]}
                      >
                        {t("Give permission to client to")}
                      </Typography>
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        label={t("Use mobile app")}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 32 },
                          "& .MuiFormControlLabel-label": {
                            fontSize: 20, // Adjust font size here
                          },
                        }}
                        control={
                          <Checkbox
                            name="mobileAppPerm"
                            checked={mobileAppPerm}
                            onChange={handleChange}
                            sx={{
                              color: pink[800],
                              "&.Mui-checked": {
                                color: pink[600],
                              },
                            }}
                          />
                        }
                      />
                      <FormControlLabel
                        disabled={!mobileAppPerm}
                        label={t("Update their profile")}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 32 },
                          "& .MuiFormControlLabel-label": {
                            fontSize: 20, // Adjust font size here
                          },
                        }}
                        control={
                          <Checkbox
                            name="mobileAppProfileCheck"
                            checked={mobileAppProfileCheck}
                            onChange={handleChange}
                            sx={{
                              color: pink[800],
                              "&.Mui-checked": {
                                color: pink[600],
                              },
                            }}
                          />
                        }
                      />
                      <FormControlLabel
                        disabled={!mobileAppPerm}
                        label={t("Add/update their motorcycles")}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 32 },
                          "& .MuiFormControlLabel-label": {
                            fontSize: 20, // Adjust font size here
                          },
                        }}
                        control={
                          <Checkbox
                            name="mobileAppMotoCheck"
                            checked={mobileAppMotoCheck}
                            onChange={handleChange}
                            sx={{
                              color: pink[800],
                              "&.Mui-checked": {
                                color: pink[600],
                              },
                            }}
                          />
                        }
                      />
                      <FormControlLabel
                        disabled={!mobileAppPerm}
                        label={t("Request appointments")}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 32 },
                          "& .MuiFormControlLabel-label": {
                            fontSize: 20, // Adjust font size here
                          },
                        }}
                        control={
                          <Checkbox
                            name="mobileAppAppointmentCheck"
                            checked={mobileAppAppointmentCheck}
                            onChange={handleChange}
                            sx={{
                              color: pink[800],
                              "&.Mui-checked": {
                                color: pink[600],
                              },
                            }}
                          />
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>

                {/* Submit Button */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      justifyContent={"center"}
                      alignContent={"center"}
                      flexDirection={"row"}
                      flex={true}
                    >
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={() => handleSubmit()}
                        sx={{
                          mt: 4,
                          mb: 1,
                          backgroundColor: colors.greenAccent[700],
                          color: "#FFFFFF",
                          height: "50px",
                          "&:hover": {
                            backgroundColor: colors.greenAccent[500],
                          },
                        }}
                      >
                        <Typography component="h1" variant="r16">
                          {t("Save")}
                        </Typography>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};

export default ClientSettings;
